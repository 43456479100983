import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { arrayOf, func, bool, object, shape } from "prop-types";

import { actions as recoverUserActions } from "reducers/recoverUser";
import { actions as miscActions, selectors as miscSelectors } from "reducers/misc";

import * as i18n from "util/i18n";
import * as config from "util/config";

import I18n from "pages/_components/I18n";
import DocumentField from "pages/_components/fields/DocumentField";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Captcha from "pages/_components/fields/credentials/Captcha";
import Credential from "pages/_components/fields/credentials/Credential";
import { Mixpanel } from "util/clickstreaming";

import Keyboard from "react-simple-keyboard";

const FORM_ID = "recoverUser.step1";

class Step1Content extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        setValues: func.isRequired,
        values: shape({}).isRequired,
    };

    state = {
        openVirtualKeyboard: false,
        inputSelected: "",
        inputsValueKeyboard: {
            inputSelectedKeyboard: "",
        },
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(miscActions.getDocumentTypes());
    }

    onVerify = (value) => {
        const { setFieldValue } = this.props;
        setFieldValue("captcha", value);
    };

    setInputSelected = (e) => {
        if (e?.target) {
            this.setState({ inputSelected: e.target.name });
        }
    };

    onChangeKeyboard = (e) => {
        const { inputSelected, inputsValueKeyboard } = this.state;
        const { setValues, values } = this.props;

        const copyValues = { ...values };
        if (inputSelected && inputSelected === inputsValueKeyboard.inputSelectedKeyboard) {
            this.setState({
                inputsValueKeyboard: {
                    ...inputsValueKeyboard,
                    [inputSelected]: e,
                    inputSelectedKeyboard: inputSelected,
                },
            });
        } else if (inputSelected) {
            let valueInput;
            if (inputSelected === "document") {
                valueInput = inputsValueKeyboard.document
                    ? inputsValueKeyboard.document + e.substr(e.length - 1)
                    : e.substr(e.length - 1);
            } else {
                valueInput = inputsValueKeyboard[inputSelected]
                    ? inputsValueKeyboard[inputSelected] + e.substr(e.length - 1)
                    : e.substr(e.length - 1);
            }
            this.setState({
                inputsValueKeyboard: {
                    ...inputsValueKeyboard,
                    [inputSelected]: valueInput,
                    inputSelectedKeyboard: inputSelected,
                },
            });
        }
        if (inputSelected === "document") {
            copyValues.document = {
                document: e,
                type: copyValues.document.type,
            };
        } else {
            copyValues[inputSelected] = e;
        }
        setValues(copyValues);
    };

    toggleVirtualKeyboard() {
        const { openVirtualKeyboard } = this.state;
        this.setState({ openVirtualKeyboard: !openVirtualKeyboard });
    }

    render() {
        const { isSubmitting, showCaptcha, isMobileNative, documentTypes, isDesktop } = this.props;
        const { openVirtualKeyboard, inputSelected } = this.state;

        return (
            <Form className="form-content">
                <div className="pre-form-info">
                    <div className="pre-form-info__text">
                        <I18n id="recoverUser.step1.header" />
                        <I18n id="recoverUser.step1.description" component="p" />
                    </div>
                </div>
                <Field
                    autoComplete="off"
                    clearable={false}
                    component={DocumentField}
                    searchable
                    data={{ documentTypes }}
                    hideDocumentPlaceholder
                    idForm={FORM_ID}
                    name="document"
                    onClick={isDesktop && this.setInputSelected}
                    onFocus={isDesktop && this.setInputSelected}
                    inputRef={this.keyboard}
                />
                <Field
                    name="email"
                    idForm={FORM_ID}
                    autoComplete="on"
                    maxLength={50}
                    component={TextField}
                    pattern={config.get("email.pattern")}
                    onClick={isDesktop && this.setInputSelected}
                    onFocus={isDesktop && this.setInputSelected}
                    inputRef={this.keyboard}
                />
                <Field
                    name="secondFactor"
                    idForm={FORM_ID}
                    component={Credential}
                    type="otp"
                    showLabelInfo={false}
                    onClick={isDesktop && this.setInputSelected}
                    onFocus={isDesktop && this.setInputSelected}
                    inputRef={this.keyboard}
                />
                {showCaptcha && !isMobileNative && <Field name="captcha" idForm={FORM_ID} component={Captcha} />}
                <Button label="global.continue" type="submit" bsStyle="primary" loading={isSubmitting} block />
                {isDesktop && (
                    <>
                        <div aria-hidden="true" className="virtual-keyboard">
                            <Button
                                image="images/ui-icons/virtual_keyboard.svg"
                                className="btn-only-icon"
                                alt="Virtual keyboard"
                                onClick={() => this.toggleVirtualKeyboard()}
                            />
                            <span onClick={() => this.toggleVirtualKeyboard()}>Teclado Virtual</span>
                        </div>

                        {openVirtualKeyboard && (
                            <div className={`d-${openVirtualKeyboard ? "block" : "none"} ui-mt-6`}>
                                <Keyboard
                                    onChange={this.onChangeKeyboard}
                                    onKeyPress={inputSelected}
                                    inputName={inputSelected}
                                    keyboardRef={(e) => {
                                        this.keyboard = e;
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            </Form>
        );
    }
}
/* eslint-disable */
Step1Content.propTypes = {
    documentTypes: arrayOf(object),
    dispatch: func.isRequired,
    isMobileNative: bool,
    showCaptcha: bool,
    setFieldValue: func.isRequired,
    isSubmitting: bool,
};

Step1Content.defaultProps = {
    isSubmitting: false,
    showCaptcha: true,
    isMobileNative: false,
    documentTypes: [],
};
/* eslint-enable */

const mapStateToProps = (state) => ({
    documentTypes: miscSelectors.getDocumentTypes(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            document: {
                country: "",
                document: "",
                type: "DN",
            },
            email: "",
            secondFactor: "",
            captcha: "",
        }),
        validationSchema: ({ isMobileNative, showCaptcha }) =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    document: Yup.object().shape({
                        document: Yup.string()
                            .max(
                                config.get(`documentType.maxLength.${values.document.type}`, 11),
                                i18n.get("global.document.format.invalid"),
                            )
                            .required(i18n.get(`${FORM_ID}.document.required`)),
                        type: Yup.string().required(i18n.get(`${FORM_ID}.documentType.required`)),
                    }),
                    email: Yup.string()
                        .required(i18n.get(`${FORM_ID}.email.required`))
                        .test("format", i18n.get("global.email.format.invalid"), (value) => {
                            const rexp = new RegExp(config.get("email.validationFormat"));
                            return rexp.test(value);
                        }),
                    secondFactor: Yup.string()
                        .min(6, i18n.get("global.token.minLength"))
                        .required(i18n.get(`${FORM_ID}.secondFactor.required`)),
                    captcha:
                        !isMobileNative && showCaptcha
                            ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.required`))
                            : Yup.string(),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            Mixpanel.track(FORM_ID);
            formikBag.props.dispatch(
                recoverUserActions.recoveryUserSendValidatonCode(
                    values.document.type,
                    values.document.document,
                    values.email,
                    values.secondFactor,
                    values.captcha,
                    formikBag,
                ),
            );
        },
    }),
)(Step1Content);
