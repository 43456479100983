import { call, put, select, takeLatest } from "redux-saga/effects";
import { actions as notificationActions } from "reducers/notification";
import { actions as suppliersActions, selectors as suppliersSelectors, types } from "reducers/paymentToSuppliers";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import { base64toBlob } from "util/file";
import { downloadCsv, downloadXls, downloadPdf } from "util/download";
import * as supplierUtils from "util/paymentSupplier";
import { push } from "react-router-redux";
import * as paymentToSuppliersMiddleware from "../middleware/paymentToSuppliers";

const sagas = [
    takeLatest(types.DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_REQUEST, downloadPaymentToSuppliersFileExample),
    takeLatest(types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST, saveFile),
    takeLatest(types.DELETE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST, deleteFile),
    takeLatest(types.PAYMENT_FILES_REQUESTED, getPaymentFiles),
    takeLatest(types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_REQUEST, seeDetail),
    takeLatest(types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_REQUEST, seeDetailById),
    takeLatest(types.PAYMENT_TO_SUPPLIERS_ANSWER_FILES_REQUEST, getAnswerFilesRequest),
    takeLatest(types.REQUEST_PAYMENT_TO_SUPPLIERS_DOWNLOAD_FILE_FROM_SERVICE, downloadFileFromService),
    takeLatest(types.REQUEST_PAYMENT_TO_SUPPLIERS_SEE_DETAIL_FROM_SERVICE, seeDetailFromService),
    takeLatest(types.GET_PAYMENT_TO_SUPPLIERS_FILE_BASE, getFile),
    takeLatest(types.GET_PAYMENT_TO_SUPPLIERS_PROCCESED_FILE, getProcessedFile),
    takeLatest(types.PAYMENT_TO_SUPPLIERS_ERROR_REQUEST, getErrors),
    takeLatest(types.DOWNLOAD_PAYMENT_ERRORS_FILE_REQUEST, downloadErrorFile),
    takeLatest(types.DOWNLOAD_VOUCHER_DETAILS_REQUEST, downloadVoucherDetails),
    takeLatest(types.ADD_DATA_TO_DOWNLOAD_REQUEST, addDataToDownload),
];

export default sagas;

function* downloadPaymentToSuppliersFileExample({ tipoOfFile }) {
    const response = yield call(paymentToSuppliersMiddleware.downloadPaymentToSuppliersExampleFile, tipoOfFile);

    if (response.type !== "W") {
        const blob = base64toBlob(response.data.data.content, "text/dat");
        downloadCsv(response.data.data.fileName, blob);
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.payments.suppliers.file.download.message.error"),
                "error",
                ["form"],
            ),
        );
    }
}

function* saveFile({ fileToSave, fileName, fileType: fileTypeToUpload, fileDescription, fileIdActivity }) {
    const response = yield call(
        paymentToSuppliersMiddleware.saveFile,
        fileToSave,
        fileName,
        fileTypeToUpload,
        fileDescription,
        fileIdActivity,
    );
    if (response.type !== "W") {
        const {
            idFile,
            totalLines,
            totalAmount,
            date,
            debitAccount,
            fileType,
            amountForCap,
        } = response.data.data.response;
        yield put(
            suppliersActions.saveFileSuccess(
                fileName,
                idFile,
                fileToSave,
                totalLines,
                totalAmount,
                date,
                debitAccount,
                fileType,
                amountForCap,
            ),
        );
    } else {
        yield put(
            suppliersActions.saveFileFailure(
                response.data.data?.linesError || [],
                fileTypeToUpload,
                fileName,
                response.data.data?.NO_FIELD,
            ),
        );
    }
}

function* deleteFile({ idFile }) {
    const response = yield call(paymentToSuppliersMiddleware.deleteFile, idFile);
    if (response.type !== "W") {
        yield put(suppliersActions.deleteFileSuccess());
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.payments.suppliers.file.delete.message.error"),
                "success",
                ["form"],
            ),
        );
    }
}

function* getPaymentFiles({ filters, firstFetch }) {
    const response = yield call(paymentToSuppliersMiddleware.getFiles, filters);
    if (response !== "W") {
        yield put(
            suppliersActions.paymentFilesReceived(
                response.data.data.files,
                response.data.data.nextPage,
                response.data.data.lastPage,
                firstFetch,
            ),
        );
    }
}

function* seeDetail({ dataToProcess }) {
    const { fileType, fileToSave, fileName, creationDate, totalLines, totalAmount, debitAccount } = dataToProcess;

    const response = yield call(paymentToSuppliersMiddleware.seeDetail, fileType, fileToSave, false);
    if (response !== "W") {
        const { linesDetail, typeFileDetail, headerData } = response.data.data;
        const headInfo = yield call(
            getHeader,
            fileType,
            creationDate,
            typeFileDetail,
            totalLines,
            debitAccount,
            totalAmount,
            headerData,
        );

        yield put(suppliersActions.seeDetailSuccess(linesDetail, typeFileDetail, fileName, headInfo, false));
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.payments.suppliers.see.detail.message.error"),
                "error",
                ["form"],
            ),
        );
    }
}

function* seeDetailById({ file }) {
    const { id, fileName, totalAmount, debitAccount, currency, transactionId } = file;
    const response = yield call(paymentToSuppliersMiddleware.seeDetailById, id);
    const tAmount = {
        currency,
        quantity: totalAmount,
    };

    if (response !== "W") {
        const { linesDetail, date, typeFileDetail, headerData } = response.data.data;
        const headInfo = yield call(
            getHeader,
            typeFileDetail,
            date,
            typeFileDetail,
            linesDetail.length,
            debitAccount,
            tAmount,
            headerData,
        );

        yield put(
            suppliersActions.seeDetailByIdSuccess(linesDetail, typeFileDetail, fileName, headInfo, transactionId, id),
        );
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.payments.suppliers.see.detail.message.error"),
                "error",
                ["form"],
            ),
        );
    }
}

function getHeader(fileType, date, typeFileDetail, totalLines, debitAccount, totalAmount, headerData) {
    const typeRegister = "D";
    let headInfo;
    switch (fileType.toUpperCase()) {
        case "TRANSFER":
            headInfo = {
                date,
                typeFileDetail,
                totalLines,
                debitAccount,
                totalAmount,
                typeRegister,
                currency: headerData?.currency,
                transferType: headerData?.transferType,
            };
            break;
        case "CHECK":
        case "ECHECK":
            headInfo = {
                date,
                typeFileDetail,
                totalLines,
                totalAmount,
            };
            break;
        case "RETENTION":
            headInfo = {
                date,
                typeFileDetail,
                totalLines,
                businessName: headerData?.businessName,
                cuit: headerData?.cuit,
                totalAmount,
                typeRegister,
            };
            break;
        default: {
            headInfo = {};
        }
    }

    return headInfo;
}

function* getAnswerFilesRequest({ data }) {
    const { docTypeClient, docNumberClient, docType, dateFrom, dateTo, filterFileType, pageNumber, fileStatus } = data;
    const response = yield call(
        paymentToSuppliersMiddleware.getAnswerFilesRequest,
        docTypeClient,
        docNumberClient,
        docType,
        dateFrom,
        dateTo,
        filterFileType,
        pageNumber,
        fileStatus,
    );
    if (response.type !== "W") {
        const { queryAnswers, lastPage, nextPage } = response.data.data;

        yield put(suppliersActions.getAnswerFilesSuccess(queryAnswers, lastPage, nextPage));
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.payments.suppliers.file.download.message.error"),
                "error",
                ["form"],
            ),
        );
    }
}

function* downloadFileFromService({ longFileName, action, format, fileType }) {
    if (action === "DOWNLOAD") {
        const { type, data } = yield call(paymentToSuppliersMiddleware.downloadFileFromService, longFileName, false);
        if (type === "W") {
            yield put(suppliersActions.setDownloadModal(false));
            let message = "";
            if (data.data.NO_FIELD) {
                message = data.data.NO_FIELD;
            } else {
                message = i18n.get("forms.payments.suppliers.see.detail.message.error");
            }
            yield put(notificationActions.showNotification(message, "error", ["supplierPaymentQueryList"]));
        } else {
            const { file } = data.data;
            const blob = base64toBlob(file, "dat");
            let name = longFileName;
            if (format === "txt") {
                name = longFileName.length > 19 ? (name += ".txt") : name.replace(".dat", ".txt");
            } else if (format === "xls") {
                name = longFileName.length > 19 ? (name += ".xls") : name.replace(".dat", ".xls");
            }
            downloadCsv(name, blob);
            yield put(suppliersActions.setDownloadModal(true));
        }
    } else if (action === "MULTIDOWNLOAD") {
        const { type, data } = yield call(paymentToSuppliersMiddleware.downloadFileFromService, longFileName, true);
        if (type === "W") {
            yield put(suppliersActions.setDownloadErrorModal(true));
        } else {
            const { file } = data.data;
            const blob = base64toBlob(file, "zip");
            const fileName = i18n.get(`pays.supplier.list.file.query.${fileType}.zip`);
            downloadCsv(fileName, blob);
            yield put(suppliersActions.setDownloadModal(true));
        }
    }
}

function* seeDetailFromService({ fileData }) {
    const { longFileName, processDate, processTime } = fileData;
    yield put({ type: types.RESET_VOUCHERS_TO_DOWNLOAD });
    const { type, data } = yield call(paymentToSuppliersMiddleware.downloadFileFromService, longFileName, false);
    if (type === "W") {
        yield put(suppliersActions.seeDetailFailure());

        let message = "";
        if (data.data.NO_FIELD) {
            message = data.data.NO_FIELD;
        } else {
            message = i18n.get("forms.payments.suppliers.see.detail.message.error");
        }

        yield put(notificationActions.showNotification(message, "error", ["supplierPaymentQueryList"]));
    } else {
        const { file } = data.data;
        const fileType = supplierUtils.typeFileByName(longFileName);
        const { data: detailData, type: detailType } = yield call(
            paymentToSuppliersMiddleware.seeDetail,
            fileType,
            file,
            true,
            longFileName,
        );
        if (detailType === "W") {
            yield put(suppliersActions.seeDetailFailure());

            let message = "";
            if (detailData.data.NO_FIELD) {
                message = detailData.data.NO_FIELD;
            } else {
                message = i18n.get("forms.payments.suppliers.see.detail.message.error");
            }

            yield put(notificationActions.showNotification(message, "error", ["supplierPaymentQueryList"]));
        } else {
            const { linesDetail, headerData } = detailData.data;
            const headerDataComplete = { ...headerData, processDate, processTime };
            yield put(suppliersActions.seeDetailSuccess(linesDetail, fileType, longFileName, headerDataComplete, true));
            yield put(push("/suppliersPaymentList/supplierList"));
        }
    }
}

function* getFile({ idTransaction, nameFileDetail, format, idDownloadFileSent }) {
    const { type, data } = yield call(
        paymentToSuppliersMiddleware.getFileByName,
        idTransaction,
        idDownloadFileSent,
        format,
    );

    if (type === "W") {
        // Handle error
    } else {
        const { content } = data.data;

        if (format === "txt") {
            const blob = base64toBlob(content, "dat");
            downloadCsv(`${nameFileDetail}.dat`, blob);
        } else {
            downloadXls(`${nameFileDetail}.xls`, content);
        }
    }
    yield put(suppliersActions.setDownloadModal(true));
}

function* getProcessedFile({ processed, typeFile, longFileName, format, processDate }) {
    const { type, data } = yield call(
        paymentToSuppliersMiddleware.getFileProccedFile,
        processed,
        typeFile,
        longFileName,
        format,
        processDate,
    );

    if (type === "W") {
        // Handle error
    } else {
        const { content } = data.data;

        if (format === "txt") {
            const blob = base64toBlob(content, "dat");
            downloadCsv(`${longFileName}.dat`, blob);
        } else {
            downloadXls(`${longFileName}.xls`, content);
        }
    }
    yield put(suppliersActions.setDownloadModal(true));
}

function* getErrors({ internalFileName, internalMemberFileName, longFileName }) {
    const response = yield call(
        paymentToSuppliersMiddleware.getErrors,
        internalFileName,
        internalMemberFileName,
        longFileName,
    );

    if (response.type !== "W") {
        const { errorDetails } = response.data.data;
        yield put(suppliersActions.getErrorsSuccess(longFileName, errorDetails));
    }
}

function* downloadErrorFile({ fileName, format, errorLines }) {
    const { type, data } = yield call(
        paymentToSuppliersMiddleware.downloadFileWithErrors,
        fileName,
        format,
        errorLines,
    );

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_PAYMENT_ERRORS_FILE_FAILURE });
    } else {
        yield put({ type: types.DOWNLOAD_PAYMENT_ERRORS_FILE_SUCCESS });

        const { content } = data.data;

        if (content) {
            if (format === "txt") {
                const blob = base64toBlob(content, "dat");
                downloadCsv(`${fileName}_errores.dat`, blob);
            } else {
                downloadXls(`${fileName}_errores.xls`, content);
            }
        }
    }
}

function* downloadVoucherDetails({ paymentType, downloadType, headInfo, payments }) {
    const notificationIds = ["suplierList"];

    try {
        const activeEnvironment = yield select(sessionSelectors.getActiveEnvironment);
        const { type, data } = yield call(
            paymentToSuppliersMiddleware.downloadVoucherDetails,
            paymentType,
            downloadType,
            headInfo,
            payments,
            activeEnvironment?.name,
        );

        if (type === "W") {
            yield put({ type: types.DOWNLOAD_VOUCHER_DETAILS_FAILURE });
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", notificationIds));
        } else {
            const { content, fileName } = data.data;
            if (downloadType === "single" && fileName.includes(".pdf")) {
                yield call(downloadPdf, fileName, content);
                yield put({ type: types.DOWNLOAD_VOUCHER_DETAILS_SUCCESS });
                yield put(
                    notificationActions.showNotification(
                        i18n.get("notification.download.successful"),
                        "success",
                        notificationIds,
                    ),
                );
            } else if (downloadType === "multiple" && fileName.includes(".zip")) {
                const blob = base64toBlob(content, "zip");
                yield downloadCsv(fileName, blob);
                yield put({ type: types.DOWNLOAD_VOUCHER_DETAILS_SUCCESS });
                yield put(
                    notificationActions.showNotification(
                        i18n.get("notification.download.successful"),
                        "success",
                        notificationIds,
                    ),
                );
            }
        }
    } catch (error) {
        yield put({ type: types.DOWNLOAD_VOUCHER_DETAILS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationIds));
    }
}

function* addDataToDownload({ detail, keyDetailForValidation }) {
    const actualVouchersToDownload = yield select(suppliersSelectors.getVouchersToDownload);
    const valueToValidate = detail[keyDetailForValidation];
    if (valueToValidate) {
        if (actualVouchersToDownload.includes(valueToValidate)) {
            const index = actualVouchersToDownload.indexOf(valueToValidate);
            actualVouchersToDownload.splice(index, 1);
        } else {
            actualVouchersToDownload.push(valueToValidate);
        }

        yield put(suppliersActions.setVouchersToDownload(actualVouchersToDownload));
    }
}
