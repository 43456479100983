import React, { Component } from "react";
import { any, bool, func, instanceOf, number, arrayOf, shape, string, oneOfType, objectOf } from "prop-types";
import { connect } from "react-redux";

import {
    types as transactionsTypes,
    actions as transactionsActions,
    selectors as transactionsSelectors,
} from "reducers/transactions";
import { actions as supplierActions } from "reducers/paymentToSuppliers";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { actions as massPaymentActions } from "reducers/massPayments";
import { selectors as sessionSelectors } from "reducers/session";

import defaultDateFromList from "util/defaultDateFromList";
import * as configUtils from "util/config";
import { validateToResetFilters } from "util/filters";
import moment from "moment";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PendingList from "./_components/PendingList";
import PendingFilters from "./_components/PendingFilters";

const LAST_TRANSACTIONS = "last";
const ID_ACTIVITY = "transactions.list";

class PendingTransactionList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        filters: shape({
            dateFrom: oneOfType([instanceOf(Date), instanceOf(moment)]),
            dateTo: oneOfType([instanceOf(Date), instanceOf(moment)]),
            minAmount: number,
            maxAmount: number,
            pageNumber: number,
        }),
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        transactions: arrayOf(any),
        isResetQuery: bool.isRequired,
        filtersSaved: shape().isRequired,
        idActivityOnFilter: string.isRequired,
        reloadData: bool.isRequired,
        history: objectOf(any).isRequired,
        user: objectOf(any).isRequired,
    };

    static defaultProps = {
        filters: null,
        transactions: [],
    };

    state = {
        transactionsSelected: [],
        activityGroupSelected: "",
        selectedQuickFilter: "",
    };

    componentDidMount = () => {
        const { filters, isResetQuery, dispatch, idActivityOnFilter, reloadData } = this.props;
        dispatch(massPaymentActions.resetData());
        dispatch(supplierActions.resetUploadedFileData());
        let defaultFilters = {};
        dispatch(filterActions.validateToResetFilters(transactionsTypes.RESET_FILTERS, "transactions.pending.list"));
        if (validateToResetFilters(isResetQuery, idActivityOnFilter, "transactions.pending.list")) {
            const dateFrom = defaultDateFromList().pendingTransactions;
            const dateTo = moment();
            defaultFilters = {
                dateFrom,
                dateTo,
                pageNumber: 1,
            };

            dispatch(transactionsActions.setSelecterFilter(LAST_TRANSACTIONS));
            dispatch(transactionsActions.loadListRequest(defaultFilters, true, false));
        } else {
            defaultFilters = filters;

            if (reloadData) {
                dispatch(transactionsActions.loadListRequest(defaultFilters, true, false));
            }
        }

        dispatch(filterActions.setReloadData(false));
    };

    resetSelectedFilter = () => {
        const { dispatch } = this.props;
        dispatch(transactionsActions.setSelecterFilter("more"));
    };

    handleSetActivityGroupSelected = (idActivity) => {
        this.setState({
            activityGroupSelected: idActivity,
        });
    };

    handleSetTransactionsSelected = (transactions) => {
        this.setState({
            transactionsSelected: transactions,
        });
    };

    handleSetSelectedQuickFilter = (quickFilter) => {
        this.setState({
            selectedQuickFilter: quickFilter,
        });
    };

    handleMoreFiltersClick = () => {
        this.resetSelectedFilter();
        this.onToggleFilters();
    };

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.toggleShowFilters());
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }

        return <Head />;
    };

    render() {
        const { isDesktop, transactions, filters: defaultFilters, history, user, dispatch, fetching, filtersSaved } = this.props;

        const { transactionsSelected, activityGroupSelected, selectedQuickFilter } = this.state;

        const multiSignAuthorizedList = configUtils.get("transactions.multiple.sign.activities.allowed", "").split("|");

        // Esto es para que si el listado trae transacciones sin firma pendiente, no se muestre el botón de firmar selección ni el checkbox de seleccionar todo
        const transactionsWithPendingSignature = transactions?.length
            ? transactions.filter(
                  (transaction) =>
                      transaction.transaction.signatures.length &&
                      transaction.transaction.signatures.every((signature) => signature.idUser !== user.userId) &&
                      transaction.transaction.idTransactionStatus !== "DRAFT" &&
                      multiSignAuthorizedList.includes(transaction.transaction.idActivity),
              )
            : [];

        const showCheckboxInput = transactions?.length >= 2 && transactionsWithPendingSignature?.length >= 1;

        const activitiesGroup = configUtils.getArrayObjectsByPortionId("transactions.pending.quickFilter.");

        return (
            <>
                <Notification scopeToShow="transactions" />

                {this.renderHeader()}

                <MainContainer>
                    <div className="container-left">
                        <div className="title-left-header">
                            <I18n id="pendingDispatch.list.title" component="h1" />
                        </div>
                        <PendingFilters
                            dispatch={dispatch}
                            isDesktop={isDesktop}
                            fetching={fetching}
                            idForm={ID_ACTIVITY}
                            resetSelectedFilter={this.resetSelectedFilter}
                            filtersSaved={filtersSaved}
                            onlyPendings
                            handleSetTransactionsSelected={this.handleSetTransactionsSelected}
                            selectedQuickFilter={selectedQuickFilter}
                            handleSetSelectedQuickFilter={this.handleSetSelectedQuickFilter}
                            handleSetActivityGroupSelected={this.handleSetActivityGroupSelected}
                            activitiesGroup={activitiesGroup}
                        />

                        <PendingList
                            isDesktop={isDesktop}
                            showSearchButton
                            defaultFilters={defaultFilters}
                            onlyPendings
                            pendingDispatch={false}
                            transactions={transactions}
                            showCheckboxInput={showCheckboxInput}
                            history={history}
                            transactionsWithPendingSignature={transactionsWithPendingSignature}
                            transactionsSelected={transactionsSelected}
                            handleSetTransactionsSelected={this.handleSetTransactionsSelected}
                            multiSignAuthorizedList={multiSignAuthorizedList}
                            handleSetActivityGroupSelected={this.handleSetActivityGroupSelected}
                            activityGroupSelected={activityGroupSelected}
                            selectedQuickFilter={selectedQuickFilter}
                            handleSetSelectedQuickFilter={this.handleSetSelectedQuickFilter}
                            activitiesGroup={activitiesGroup}
                        />
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: transactionsSelectors.getFetching(state),
    filters: transactionsSelectors.getFilters(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    transactions: transactionsSelectors.getTransactions(state),
    filtersSaved: transactionsSelectors.getFilters(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
    reloadData: filterSelectors.isReloadData(state),
    user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(PendingTransactionList);
