import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { bool, func, shape } from "prop-types";

import { actions as recoverUserActions } from "reducers/recoverUser";
import * as i18n from "util/i18n";

import * as config from "util/config";
import I18n from "pages/_components/I18n";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import { Mixpanel } from "util/clickstreaming";
import Credential from "pages/_components/fields/credentials/Credential";

import Keyboard from "react-simple-keyboard";

const FORM_ID = "recoverUser.step2";

class Step2Content extends Component {
    UNIQUE_IDENTIFIER_CODE_MASK = [
        /[\d\w]/,
        /[\d\w]/,
        /[\d\w]/,
        /[\d\w]/,
        "-",
        /[\d\w]/,
        /[\d\w]/,
        /[\d\w]/,
        /[\d\w]/,
        "-",
        /[\d\w]/,
        /[\d\w]/,
        /[\d\w]/,
        /[\d\w]/,
    ];

    static propTypes = {
        isDesktop: bool.isRequired,
        setValues: func.isRequired,
        values: shape({}).isRequired,
    };

    state = {
        openVirtualKeyboard: false,
        inputSelected: "",
        inputsValueKeyboard: {
            inputSelectedKeyboard: "",
        },
    };

    setInputSelected = (e) => {
        if (e?.target) {
            this.setState({ inputSelected: e.target.name });
        }
    };

    onChangeKeyboard = (e) => {
        const { inputSelected, inputsValueKeyboard } = this.state;
        const { setValues, values } = this.props;

        const copyValues = { ...values };
        if (inputSelected && inputSelected === inputsValueKeyboard.inputSelectedKeyboard) {
            this.setState({
                inputsValueKeyboard: {
                    ...inputsValueKeyboard,
                    [inputSelected]: e,
                    inputSelectedKeyboard: inputSelected,
                },
            });
        } else if (inputSelected) {
            let valueInput;
            if (inputSelected === "document") {
                valueInput = inputsValueKeyboard.document
                    ? inputsValueKeyboard.document + e.substr(e.length - 1)
                    : e.substr(e.length - 1);
            } else {
                valueInput = inputsValueKeyboard[inputSelected]
                    ? inputsValueKeyboard[inputSelected] + e.substr(e.length - 1)
                    : e.substr(e.length - 1);
            }
            this.setState({
                inputsValueKeyboard: {
                    ...inputsValueKeyboard,
                    [inputSelected]: valueInput,
                    inputSelectedKeyboard: inputSelected,
                },
            });
        }
        if (inputSelected === "document") {
            copyValues.document = {
                document: e,
                type: copyValues.document.type,
            };
        } else {
            copyValues[inputSelected] = e;
        }
        setValues(copyValues);
    };

    toggleVirtualKeyboard() {
        const { openVirtualKeyboard } = this.state;
        this.setState({ openVirtualKeyboard: !openVirtualKeyboard });
    }

    render() {
        const { isSubmitting, isDesktop } = this.props;
        const { openVirtualKeyboard, inputSelected } = this.state;

        return (
            <Form className="form-content container">
                <div className="pre-form-info">
                    <div className="pre-form-info__text">
                        <I18n id="recoverUser.step2.header" />
                        <I18n id="recoverUser.step2.description" component="p" />
                    </div>
                </div>
                <Field
                    name="code"
                    idForm={FORM_ID}
                    autoComplete="off"
                    maxLength={14}
                    component={TextField}
                    mask={this.UNIQUE_IDENTIFIER_CODE_MASK}
                    placeholderText="XXXX-XXXX-XXXX"
                    inputRef={this.keyboard}
                    onClick={isDesktop && this.setInputSelected}
                    onFocus={isDesktop && this.setInputSelected}
                />

                <Field
                    name="newUser"
                    idForm={FORM_ID}
                    autoComplete="off"
                    maxLength={50}
                    component={Credential}
                    hidePlaceholder
                    pattern={config.get("core.idUser.pattern")}
                    inputRef={this.keyboard}
                    onClick={isDesktop && this.setInputSelected}
                    onFocus={isDesktop && this.setInputSelected}
                />
                <Field
                    name="newUserConfirmation"
                    idForm={FORM_ID}
                    autoComplete="off"
                    maxLength={50}
                    component={Credential}
                    hidePlaceholder
                    pattern={config.get("core.idUser.pattern")}
                    inputRef={this.keyboard}
                    onClick={isDesktop && this.setInputSelected}
                    onFocus={isDesktop && this.setInputSelected}
                />

                <Button block label="global.continue" type="submit" bsStyle="primary" loading={isSubmitting} />

                {isDesktop && (
                    <>
                        <div aria-hidden="true" className="virtual-keyboard">
                            <Button
                                image="images/ui-icons/virtual_keyboard.svg"
                                className="btn-only-icon"
                                alt="Virtual keyboard"
                                onClick={() => this.toggleVirtualKeyboard()}
                            />
                            <span onClick={() => this.toggleVirtualKeyboard()}>Teclado Virtual</span>
                        </div>

                        {openVirtualKeyboard && (
                            <div className={`d-${openVirtualKeyboard ? "block" : "none"} ui-mt-6`}>
                                <Keyboard
                                    onChange={this.onChangeKeyboard}
                                    onKeyPress={inputSelected}
                                    inputName={inputSelected}
                                    keyboardRef={(e) => {
                                        this.keyboard = e;
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            </Form>
        );
    }
}

Step2Content.propTypes = {
    isSubmitting: bool,
};

Step2Content.defaultProps = {
    isSubmitting: false,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        code: "",
        newUser: "",
        newUserConfirmation: "",
    }),
    validationSchema: () =>
        Yup.object().shape({
            code: Yup.string().required(i18n.get(`${FORM_ID}.code.required`)),
            newUser: Yup.string()
                .required(i18n.get(`${FORM_ID}.newUser.required`))
                .test("format", i18n.get("global.username.format.invalid"), (value) => {
                    if (value?.length < config.get("username.minLength")) {
                        return false;
                    }
                    if (value?.length > config.get("username.maxLength")) {
                        return false;
                    }
                    const rexp = new RegExp(config.get("username.pattern"));
                    return rexp.test(value);
                }),
            newUserConfirmation: Yup.string().required(i18n.get(`${FORM_ID}.newUserConfirmation.required`)),
        }),
    handleSubmit: (values, formikBag) => {
        Mixpanel.track(FORM_ID);
        formikBag.props.dispatch(
            recoverUserActions.recoveryUser(values.code, values.newUser, values.newUserConfirmation, formikBag),
        );
    },
})(Step2Content);
