import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "transactions/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "transactions/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "transactions/LOAD_LIST_SUCCESS",
    SET_FILTERS: "transactions/SET_FILTERS",
    LOAD_MORE_TRANSACTIONS_REQUEST: "transactions/LOAD_MORE_TRANSACTIONS_REQUEST",
    LOAD_MORE_TRANSACTIONS_SUCCESS: "transactions/LOAD_MORE_TRANSACTIONS_SUCCESS",
    DELETE_DRAFT_REQUEST: "transactions/DELETE_DRAFT_REQUEST",
    DELETE_DRAFT_FAILURE: "transactions/DELETE_DRAFT_FAILURE",
    DELETE_DRAFT_SUCCESS: "transactions/DELETE_DRAFT_SUCCESS",
    REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS: "transactions/REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS",
    RESET_FILTERS: "transactions/RESET_FILTERS",
    SET_SELECTED_FILTER: "transactions/SET_SELECTED_FILTER",
    LOAD_TRANSACTION_DATA_TO_SIGN: "transactions/LOAD_TRANSACTION_DATA_TO_SIGN",
    LOAD_TRANSACTION_DATA_TO_SIGN_SUCCESS: "transactions/LOAD_TRANSACTION_DATA_TO_SIGN_SUCCESS",
    RESET_TRANSACTION_DATA_TO_SIGN: "transactions/RESET_TRANSACTION_DATA_TO_SIGN",
    SIGN_MULTIPLE_TRANSACTIONS_REQUEST: "transactions/SIGN_MULTIPLE_TRANSACTIONS_REQUEST",
    SIGN_MULTIPLE_TRANSACTIONS_FAILURE: "transactions/SIGN_MULTIPLE_TRANSACTIONS_FAILURE",
    SIGN_MULTIPLE_TRANSACTIONS_SUCCESS: "transactions/SIGN_MULTIPLE_TRANSACTIONS_SUCCESS",
    SHOW_SIGN_MULTIPLE_TRANSACTIONS_MODAL: "transactions/SHOW_SIGN_MULTIPLE_TRANSACTIONS_MODAL",
    SHOW_SIGN_MULTIPLE_TRANSACTIONS_FINISH_MODAL: "transactions/SHOW_SIGN_MULTIPLE_TRANSACTIONS_FINISH_MODAL",
};

export const INITIAL_STATE = {
    transactions: [],
    isFetching: false,
    filters: {
        transactions: null,
        dateFrom: null,
        dateTo: null,
        minAmount: null,
        maxAmount: null,
    },
    selectedFilter: "last",
    hasMoreData: false,
    pageNumber: 1,
    isDeletingDraft: false,
    pendingTransactionsQuantity: 0,
    isFirstFetching: true,
    transactionsToSign: [],
    noPendingTransactions: [],
    showSignMultipleTransactionsModal: false,
    showSignMultipleTransactionsFinishModal: false,
    idTransactionToRedirect: "",
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: action.filters,
        isFirstFetching: true,
        pageNumber: 1,
        transactions: null,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, action) => ({
        ...state,
        transactions: action.transactions,
        hasMoreData: action.pageNumber < action.totalPages,
        isFetching: false,
        isFirstFetching: false,
        pageNumber: action.pageNumber,
    }),
    [types.SET_FILTERS]: (state, action) => ({
        ...state,
        filters: action.filters,
    }),
    [types.LOAD_MORE_TRANSACTIONS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_TRANSACTIONS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        transactions: state.transactions ? [...state.transactions.concat(action.transactions)] : action.transactions,
        hasMoreData: action.pageNumber < action.totalPages,
        pageNumber: action.pageNumber,
    }),
    [types.DELETE_DRAFT_REQUEST]: (state) => ({
        ...state,
        isDeletingDraft: true,
    }),
    [types.DELETE_DRAFT_FAILURE]: (state) => ({
        ...state,
        isDeletingDraft: false,
    }),
    [types.DELETE_DRAFT_SUCCESS]: (state, action) => ({
        ...state,
        isDeletingDraft: false,
        transactions: state.transactions.filter((item) => item.transaction.idTransaction !== action.idTransaction),
    }),
    [types.REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS]: (state, action) => ({
        ...state,
        pendingTransactionsQuantity: action.pendingTransactionsQuantity,
    }),
    [types.RESET_FILTERS]: (state) => ({
        ...state,
        filters: INITIAL_STATE.filters,
    }),
    [types.SET_SELECTED_FILTER]: (state, action) => ({
        ...state,
        selectedFilter: action.selectedFilter,
    }),
    [types.LOAD_TRANSACTION_DATA_TO_SIGN]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_TRANSACTION_DATA_TO_SIGN_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        transactionsToSign: action.pendingTransactions,
        noPendingTransactions: state.noPendingTransactions
            ? [...state.noPendingTransactions.concat(action.noPendingTransactions)]
            : action.noPendingTransactions,
    }),
    [types.RESET_TRANSACTION_DATA_TO_SIGN]: (state) => ({
        ...state,
        transactionsToSign: INITIAL_STATE.transactionsToSign,
        noPendingTransactions: INITIAL_STATE.noPendingTransactions,
    }),
    [types.SHOW_SIGN_MULTIPLE_TRANSACTIONS_MODAL]: (state, action) => ({
        ...state,
        isFetching: false,
        showSignMultipleTransactionsModal: action.showSignMultipleTransactionsModal,
    }),
    [types.SIGN_MULTIPLE_TRANSACTIONS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.SIGN_MULTIPLE_TRANSACTIONS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.SHOW_SIGN_MULTIPLE_TRANSACTIONS_FINISH_MODAL]: (state, action) => ({
        ...state,
        showSignMultipleTransactionsFinishModal: action.showSignMultipleTransactionsFinishModal,
        idTransactionToRedirect: action.idTransactionToRedirect,
    }),
});

export const actions = {
    loadListRequest: makeActionCreator(
        types.LOAD_LIST_REQUEST,
        "filters",
        "onlyPendings",
        "pendingDispatch",
        "isFirstFetching",
    ),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "transactions", "pageNumber", "totalPages"),
    setFilters: makeActionCreator(types.SET_FILTERS, "filters"),
    loadMoreTransactionsRequest: makeActionCreator(
        types.LOAD_MORE_TRANSACTIONS_REQUEST,
        "filters",
        "onlyPendings",
        "pendingDispatch",
    ),
    loadMoreTransactionsSuccess: makeActionCreator(
        types.LOAD_MORE_TRANSACTIONS_SUCCESS,
        "transactions",
        "pageNumber",
        "totalPages",
    ),
    deleteDraftRequest: makeActionCreator(types.DELETE_DRAFT_REQUEST, "idTransaction"),
    deleteDraftFailure: makeActionCreator(types.DELETE_DRAFT_FAILURE),
    deleteDraftSuccess: makeActionCreator(types.DELETE_DRAFT_SUCCESS, "idTransaction"),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),

    setSelecterFilter: makeActionCreator(types.SET_SELECTED_FILTER, "selectedFilter"),

    loadTransactionDataToSign: makeActionCreator(types.LOAD_TRANSACTION_DATA_TO_SIGN, "transactionsList"),
    loadTransactionDataToSignSuccess: makeActionCreator(
        types.LOAD_TRANSACTION_DATA_TO_SIGN_SUCCESS,
        "pendingTransactions",
        "noPendingTransactions",
    ),
    resetTransactionDataToSign: makeActionCreator(types.RESET_TRANSACTION_DATA_TO_SIGN),
    signMultipleTransactionsRequest: makeActionCreator(types.SIGN_MULTIPLE_TRANSACTIONS_REQUEST, "data"),
    signMultipleTransactionsFailure: makeActionCreator(types.SIGN_MULTIPLE_TRANSACTIONS_FAILURE),
    setShowSignMultipleTransactionsModal: makeActionCreator(
        types.SHOW_SIGN_MULTIPLE_TRANSACTIONS_MODAL,
        "showSignMultipleTransactionsModal",
    ),
    setShowSignMultipleTransactionsFinishModal: makeActionCreator(
        types.SHOW_SIGN_MULTIPLE_TRANSACTIONS_FINISH_MODAL,
        "showSignMultipleTransactionsFinishModal",
        "idTransactionToRedirect",
    ),
};

export const selectors = {
    getTransactions: ({ transactions }) => transactions.transactions,
    getFirstFetch: ({ transactions }) => transactions.isFirstFetching,
    getFetching: ({ transactions }) => transactions.isFetching,
    getHasMoreData: ({ transactions }) => transactions.hasMoreData,
    getPageNumber: ({ transactions }) => transactions.pageNumber,
    getFilters: ({ transactions }) => transactions.filters,
    isDeletingDraft: ({ transactions }) => transactions.isDeletingDraft,
    getPendingTransactionsQuantity: ({ transactions }) => transactions.pendingTransactionsQuantity,
    getSelectedFilter: ({ transactions }) => transactions.selectedFilter,
    getTransactionsToSign: ({ transactions }) => transactions.transactionsToSign,
    getNoPendingTransactions: ({ transactions }) => transactions.noPendingTransactions,
    getShowSignMultipleTransactionsModal: ({ transactions }) => transactions.showSignMultipleTransactionsModal,
    getShowSignMultipleTransactionsFinishModal: ({ transactions }) =>
        transactions.showSignMultipleTransactionsFinishModal,
    getIdTransactionToRedirect: ({ transactions }) => transactions.idTransactionToRedirect,
};
