import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { func, instanceOf, shape, number, string, bool, arrayOf, any, oneOfType } from "prop-types";
import { isMobileNative } from "util/device";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import defaultDateFromList from "util/defaultDateFromList";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as sessionSelectors } from "reducers/session";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import TransactionItem from "pages/transactions/_components/TransactionItem";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import ProductList from "pages/_components/product/List";
import moment from "moment";
import getFiltersStatus from "util/getFiltersStatus";
import Loader from "pages/_components/Loader";
import Checkbox from "pages/_components/fields/Checkbox";
import MultipleActionBtn from "pages/_components/MultipleActionBtn/MultipleActionBtn";
import SignMultipleTransactionsModal from "./SignMultipleTransactionsModal";
import SignMultipleTransactionsFinishModal from "./SignMultipleTransactionsFinishModal";

class List extends Component {
    static propTypes = {
        defaultFilters: shape({
            dateFrom: oneOfType([instanceOf(Date), instanceOf(moment)]),
            dateTo: oneOfType([instanceOf(Date), instanceOf(moment)]),
            pageNumber: number,
            filter: string,
        }),
        dispatch: func.isRequired,
        pageNumber: number,
        onlyPendings: bool,
        pendingDispatch: bool,
        filters: shape({
            idFilter: string,
            dateFrom: oneOfType([instanceOf(Date), instanceOf(moment)]),
            dateTo: oneOfType([instanceOf(Date), instanceOf(moment)]),
            pageNumber: number,
            minAmount: number,
            maxAmount: number,
        }),
        transactions: arrayOf(any),
        isDesktop: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetching: bool.isRequired,
        showEnvironment: bool,
        itemsAreClickeable: bool,
        firstFetched: bool.isRequired,
        showCheckboxInput: bool,
        showSignMultipleTransactionsModal: bool.isRequired,
        showSignMultipleFinishModal: bool.isRequired,
        transactionsWithPendingSignature: arrayOf(any),
        idTransactionToRedirect: string.isRequired,
        activeEnvironment: shape({}).isRequired,
        transactionsSelected: arrayOf(string),
        handleSetTransactionsSelected: func,
        handleSetActivityGroupSelected: func,
        activityGroupSelected: string,
        multiSignAuthorizedList: arrayOf(string).isRequired,
    };

    static defaultProps = {
        defaultFilters: null,
        pageNumber: 1,
        onlyPendings: false,
        pendingDispatch: false,
        filters: null,
        showEnvironment: false,
        itemsAreClickeable: true,
        transactions: [],
        showCheckboxInput: false,
        transactionsWithPendingSignature: [],
        transactionsSelected: [],
        handleSetTransactionsSelected: null,
        handleSetActivityGroupSelected: null,
        activityGroupSelected: "",
    };

    fetchMoreTransactions = () => {
        const { defaultFilters, dispatch, pageNumber, onlyPendings, pendingDispatch } = this.props;
        let { filters } = this.props;

        filters = filters ? { ...filters, pageNumber } : { ...defaultFilters, pageNumber };

        dispatch(transactionsActions.loadMoreTransactionsRequest(filters, onlyPendings, pendingDispatch));
    };

    onMobileClickTransaction = (transaction) => {
        const { dispatch, itemsAreClickeable } = this.props;

        if (
            transaction.transaction.idActivity === "pay.transfers.massive.send" &&
            transaction.transaction.idTransactionStatus === "DRAFT" &&
            isMobileNative
        ) {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactions",
                ]),
            );
        } else {
            dispatch(
                push(
                    itemsAreClickeable ? `/transaction/${transaction.transaction.idTransaction}` : `/transactions/list`,
                ),
            );
        }
    };

    onDesktopItemClick = (transaction) => {
        const { dispatch, isDesktop, itemsAreClickeable } = this.props;

        if (
            transaction.transaction.idForm === SALARY_PAYMENT_ID_FORM &&
            !isDesktop &&
            transaction.transaction.idTransactionStatus === "DRAFT"
        ) {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactions",
                ]),
            );
        } else {
            dispatch(
                push(
                    itemsAreClickeable ? `/transaction/${transaction.transaction.idTransaction}` : `/transactions/list`,
                ),
            );
        }
    };

    handleSelectAllTransaction = () => {
        const { transactionsWithPendingSignature, transactionsSelected, handleSetTransactionsSelected } = this.props;
        const maxTransactions = configUtils.get("pendingTransactions.maxTransactions.to.multipleSign");

        if (handleSetTransactionsSelected) {
            if (transactionsWithPendingSignature.slice(0, maxTransactions).length === transactionsSelected.length) {
                handleSetTransactionsSelected([]);
            } else {
                const transactionsIds = transactionsWithPendingSignature
                    .slice(0, maxTransactions)
                    .map((transaction) => transaction.transaction.idTransaction);

                handleSetTransactionsSelected(transactionsIds);
            }
        }
    };

    handleSelectTransaction = (idTransaction, idActivity) => {
        const {
            dispatch,
            transactionsSelected,
            handleSetTransactionsSelected,
            handleSetActivityGroupSelected,
            activityGroupSelected,
        } = this.props;
        const maxTransactions = configUtils.get("pendingTransactions.maxTransactions.to.multipleSign");

        if (handleSetTransactionsSelected) {
            if (transactionsSelected.includes(idTransaction)) {
                const newTransactions = transactionsSelected.filter((id) => id !== idTransaction);
                handleSetTransactionsSelected(newTransactions);
                if (transactionsSelected.length === 1) {
                    handleSetActivityGroupSelected("");
                }
            } else if (transactionsSelected.length < maxTransactions) {
                const newTransactions = [...transactionsSelected, idTransaction];
                handleSetTransactionsSelected(newTransactions);
                if (activityGroupSelected !== idActivity) {
                    handleSetActivityGroupSelected(idActivity);
                }
            } else {
                dispatch(
                    notificationModalActions.showNotification(
                        i18nUtils.get("pendingTransactions.list.signSelection.maxTransactions.error", "", {
                            maxTransactions,
                        }),
                        "warning",
                    ),
                );
            }
        }
    };

    handleSignSelection = () => {
        const { dispatch, transactionsSelected } = this.props;
        dispatch(transactionsActions.setShowSignMultipleTransactionsModal(true));
        dispatch(transactionsActions.loadTransactionDataToSign(transactionsSelected));
    };

    hasPermissionForSignMultiple = () => {
        const { activeEnvironment } = this.props;
        if (activeEnvironment.permissions.signMultipleTransaction) {
            return true;
        }
        return false;
    };

    renderSignSelectionBtn = () => {
        const { isDesktop, transactionsSelected } = this.props;

        let toolTipText = i18nUtils.get("pendingTransactions.list.signSelection.button.disabled.tooltip");
        let btnDisabled = transactionsSelected.length < 2;
        if (!this.hasPermissionForSignMultiple()) {
            toolTipText = i18nUtils.get("returnCode.COR005E");
            btnDisabled = true;
        }

        const tooltipProps = {
            "data-tooltip": toolTipText,
            "data-tooltip-position": isDesktop ? "right" : "bottom",
        };

        return (
            <MultipleActionBtn
                btnLabel="pendingTransactions.list.signSelection.button"
                btnIcon="images/ui-icons/ui-sign-pend-gray.svg"
                btnAction={() => this.handleSignSelection()}
                btnLoading={false}
                btnBlock={!isDesktop}
                textInfo="pendingTransactions.list.signSelection.textInfo"
                textInfoProps={{
                    maxTransactions: configUtils.get("pendingTransactions.maxTransactions.to.multipleSign"),
                }}
                btnDisabled={btnDisabled}
                tooltipProps={tooltipProps}
            />
        );
    };

    renderCheckAll = () => {
        const { transactionsWithPendingSignature, isDesktop, transactionsSelected } = this.props;
        const checkAllDisabled = !transactionsWithPendingSignature.length || !this.hasPermissionForSignMultiple();

        const maxTransactions = configUtils.get("pendingTransactions.maxTransactions.to.multipleSign");

        return (
            <Checkbox
                key="checkAll"
                name="checkAll"
                label={i18nUtils.get("pendingTransactions.list.selectAll.checkbox.selectAll")}
                checked={
                    transactionsWithPendingSignature.length &&
                    transactionsWithPendingSignature.slice(0, maxTransactions).length === transactionsSelected.length
                }
                onChange={() => this.handleSelectAllTransaction()}
                hideLabel={isDesktop}
                disabled={checkAllDisabled}
                value={["all"]}
            />
        );
    };

    renderItem = (transaction) => {
        const {
            isDesktop,
            showEnvironment,
            onlyPendings,
            multiSignAuthorizedList,
            transactionsSelected,
            activityGroupSelected,
        } = this.props;

        if (isDesktop) {
            return (
                <Table.Row
                    key={transaction.transaction.idTransaction}
                    role="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (
                            !(
                                e.target.classList.contains("c-control-label") ||
                                e.target.classList.contains("c-control-input")
                            )
                        ) {
                            this.onDesktopItemClick(transaction);
                        }
                    }}>
                    <TransactionItem
                        key={`transaction-${transaction.transaction.idTransaction}`}
                        transaction={transaction}
                        isDesktop={isDesktop}
                        showEnvironment={showEnvironment}
                        onlyPendings={onlyPendings}
                        multiSignAuthorizedList={multiSignAuthorizedList}
                        handleSelectTransaction={this.handleSelectTransaction}
                        transactionsSelected={transactionsSelected}
                        disabledCheckBox={!this.hasPermissionForSignMultiple()}
                        activityGroupSelected={activityGroupSelected}
                    />
                </Table.Row>
            );
        }
        return (
            <TransactionItem
                key={`transaction-${transaction.transaction.idTransaction}`}
                transaction={transaction}
                isDesktop={isDesktop}
                showEnvironment={showEnvironment}
                onClick={() => this.onMobileClickTransaction(transaction)}
                onlyPendings={onlyPendings}
                multiSignAuthorizedList={multiSignAuthorizedList}
                handleSelectTransaction={this.handleSelectTransaction}
                transactionsSelected={transactionsSelected}
                activityGroupSelected={activityGroupSelected}
            />
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, showEnvironment, onlyPendings, showCheckboxInput } = this.props;

        return isDesktop ? (
            <>
                <Table className="gridTable transactions">
                    <Table.Header>
                        {showCheckboxInput && (
                            <>
                                <Table.HeaderData className="with-checkbox">{this.renderCheckAll()}</Table.HeaderData>
                            </>
                        )}
                        <Table.HeaderData align="left">
                            <I18n id="transactions.list.header.state" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="transactions.list.header.transactionName" />
                        </Table.HeaderData>
                        {showEnvironment && (
                            <Table.HeaderData align="left">
                                <I18n id="transactions.list.header.client" />
                            </Table.HeaderData>
                        )}
                        <Table.HeaderData align="left">
                            <I18n id="transactions.list.header.creationDate" />
                        </Table.HeaderData>
                        {!onlyPendings && (
                            <Table.HeaderData align="left">
                                <I18n id="transactions.list.header.submitDate" />
                            </Table.HeaderData>
                        )}
                        <Table.HeaderData align="right">
                            <I18n id="transactions.list.header.amount" />
                        </Table.HeaderData>
                        <Table.HeaderData />
                        {onlyPendings && <Table.HeaderData />}
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {showCheckboxInput && this.renderSignSelectionBtn()}
                {renderLoadMore()}
            </>
        ) : (
            <>
                {showCheckboxInput && this.renderCheckAll()}
                <div className="generic-movements-wrapper">{list}</div>
                <SeuoLabel isTable isMobile={!isDesktop} />
                {showCheckboxInput && this.renderSignSelectionBtn()}
                {renderLoadMore()}
            </>
        );
    };

    render() {
        const {
            transactions,
            hasMoreData,
            fetching,
            pageNumber,
            filters,
            firstFetched,
            onlyPendings,
            isDesktop,
            showSignMultipleTransactionsModal,
            showSignMultipleFinishModal,
            idTransactionToRedirect,
            dispatch,
        } = this.props;

        const defaultFilters = {
            dateFrom: defaultDateFromList().listTransaction,
            dateTo: moment(),
            filter: "last",
            selectedFilter: "last",
            transactionType: ["all"],
            state: ["Todos"],
        };

        const listType = onlyPendings ? "pendingTransactions" : "transactions";
        const noDataText = `${listType}.list.none`;
        const withFilters = getFiltersStatus({ filters, defaultFilters });
        let noMoreDataText;

        if (withFilters) {
            noMoreDataText = `${listType}.list.noMoreTransactions.withFilters`;
        } else {
            noMoreDataText = `${listType}.list.noMoreTransactions.default`;
        }

        return fetching && firstFetched ? (
            <Loader />
        ) : (
            <>
                {showSignMultipleTransactionsModal && (
                    <SignMultipleTransactionsModal
                        isMobile={!isDesktop}
                        transactionType={filters?.transactionType || []}
                    />
                )}
                {showSignMultipleFinishModal && (
                    <SignMultipleTransactionsFinishModal
                        dispatch={dispatch}
                        isDisplayed={showSignMultipleFinishModal}
                        idTransactionToRedirect={idTransactionToRedirect}
                    />
                )}
                <ProductList
                    fetching={fetching}
                    items={transactions}
                    renderItem={this.renderItem}
                    lastPage={!hasMoreData}
                    pageNumber={pageNumber}
                    onLoadMoreClick={this.fetchMoreTransactions}
                    firstFetched={firstFetched}
                    filters={filters}
                    loadMoreText="transactions.list.moreTransactions"
                    noDataText={noDataText}
                    noFiltersDataText="transactions.list.filters.noData"
                    noMoreDataText={noMoreDataText}
                    defaultFilters={defaultFilters}
                    isMobile={!isDesktop}>
                    {this.renderList}
                </ProductList>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    filters: transactionsSelectors.getFilters(state),
    firstFetched: transactionsSelectors.getFirstFetch(state),
    showSignMultipleFinishModal: transactionsSelectors.getShowSignMultipleTransactionsFinishModal(state),
    showSignMultipleTransactionsModal: transactionsSelectors.getShowSignMultipleTransactionsModal(state),
    idTransactionToRedirect: transactionsSelectors.getIdTransactionToRedirect(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(List);
