import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { array, bool, func, oneOfType, shape, string } from "prop-types";

import { selectors as suppliersSelectors, actions as supplierActions } from "reducers/paymentToSuppliers";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GenericItem from "components/GenericItem/GenericItem";
import { compose } from "redux";
import withRouter from "react-router-dom/withRouter";
import Loader from "pages/_components/Loader";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import SimpleModal from "components/SimpleModal/SimpleModal";
import Notification from "pages/_components/Notification";
import TransferItem from "./transfer/TransferItem";
import TransferHeaderInfo from "./transfer/TransferHeaderInfo";
import TransferTableHeader from "./transfer/TransferTableHeader";
import ButtonToDownloadMultipleItems from "./_components/ButtonToDownloadMultipleItems";
import CheckHeaderInfo from "./check/CheckHeaderInfo";
import CheckTableHeader from "./check/CheckTableHeader";
import CheckItem from "./check/CheckItem";
import EcheckHeaderInfo from "./echeck/EcheckHeaderInfo";
import EcheckItem from "./echeck/EcheckItem";
import EcheckTableHeader from "./echeck/EcheckTableHeader";
import RetentionHeaderInfo from "./retention/RetentionHeaderInfo";
import RetentionItem from "./retention/RetentionItem";
import RetentionTableHeader from "./retention/RetentionTableHeader";

const SupplierList = (props) => {
    const {
        linesDetail,
        nameFileDetail,
        linesWithError,
        nameFileError,
        isMobile,
        history,
        isFetching,
        fetchingExport,
        fetchingDownloadFile,
        dispatch,
        typeFile,
        idTransaction,
        showDownloadModal,
        comeFromProcessed,
        idTransactionPending,
        idDownloadFileSent,
        idFileCharged,
        headInfo,
    } = props;
    const isDetail = linesDetail.length > 0;
    const type = isDetail ? typeFile : "ERROR";
    const itemQuantity = isDetail ? linesDetail.length : linesWithError.length;
    const [showDownloadModalState, setShowDownloadModalState] = useState(false);
    const isDownloadButton = comeFromProcessed && (type === "transfer" || type === "check" || type === "echeck");

    useEffect(() => {
        if (isMobile) {
            history.push("/paymentSuppliers/files/");
        }
    }, []);

    useEffect(() => {
        if (!isFetching && !itemQuantity) {
            history.goBack();
        }
    }, [isFetching]);

    const renderHeaderInfo = () => {
        switch (typeFile.toUpperCase()) {
            case "TRANSFER":
                return <TransferHeaderInfo headInfo={headInfo} comeFromProcessed={comeFromProcessed} />;
            case "CHECK":
                return <CheckHeaderInfo headInfo={headInfo} comeFromProcessed={comeFromProcessed} />;
            case "ECHECK":
                return <EcheckHeaderInfo headInfo={headInfo} comeFromProcessed={comeFromProcessed} />;
            case "RETENTION":
                return <RetentionHeaderInfo headInfo={headInfo} comeFromProcessed={comeFromProcessed} />;
            default: {
                return null;
            }
        }
    };

    const renderItem = (detail) => {
        let item;
        switch (type.toUpperCase()) {
            case "TRANSFER":
                item = (
                    <TransferItem
                        key={detail.transferNumber}
                        detail={detail}
                        isMobile={false}
                        headInfo={headInfo}
                        comeFromProcessed={comeFromProcessed}
                    />
                );
                break;

            case "CHECK":
                item = (
                    <CheckItem
                        key={detail.checkNumber}
                        detail={detail}
                        isMobile={false}
                        headInfo={headInfo}
                        comeFromProcessed={comeFromProcessed}
                    />
                );
                break;

            case "ECHECK":
                item = (
                    <EcheckItem
                        key={detail.eCheckNumber}
                        detail={detail}
                        isMobile={false}
                        headInfo={headInfo}
                        comeFromProcessed={comeFromProcessed}
                    />
                );
                break;

            case "RETENTION":
                item = (
                    <RetentionItem
                        key={detail.voucherNumber}
                        detail={detail}
                        isMobile={false}
                        headInfo={headInfo}
                        comeFromProcessed={comeFromProcessed}
                    />
                );
                break;

            case "ERROR":
                item = (
                    <Table.Row additionalClassName="no-hover">
                        <Table.Data align="left">
                            <span className="data-text">{detail.line}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span className="data-text">{detail.field}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span className="data-text">{detail.starPos}</span>
                        </Table.Data>

                        <Table.Data align="left">
                            <span className="data-text">{detail.endPos}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span className="data-text">{detail.description || detail.validationFail}</span>
                        </Table.Data>
                    </Table.Row>
                );
                break;

            case "REJECTED":
                item = (
                    <Table.Row additionalClassName="no-hover">
                        <Table.Data align="left">
                            <span className="data-text">
                                {`${detail.branch}-${detail.debitAccount}-${detail.digit}`}
                            </span>
                        </Table.Data>

                        <Table.Data align="left">
                            <span className="data-text">{`${detail.receiver}/${detail.cbu}`}</span>
                        </Table.Data>

                        <Table.Data align="left">
                            <div className="data-text">
                                <FormattedDate date={detail.presentationDate} />
                            </div>
                            <div className="data-text">
                                <FormattedDate date={detail.compensationDate} />
                            </div>
                        </Table.Data>

                        <Table.Data align="left">
                            <span className="data-text">{detail.reason}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span className="data-text">{detail.voucher}</span>
                        </Table.Data>
                        <Table.Data align="left">
                            <FormattedAmount currency={detail.amount?.currency} quantity={detail.amount?.quantity} />
                        </Table.Data>
                    </Table.Row>
                );
                break;

            default:
                break;
        }
        return item;
    };

    const renderTableHeader = () => {
        switch (type.toUpperCase()) {
            case "TRANSFER":
                return <TransferTableHeader linesDetail={linesDetail} comeFromProcessed={comeFromProcessed} />;
            case "CHECK":
                return <CheckTableHeader linesDetail={linesDetail} comeFromProcessed={comeFromProcessed} />;
            case "ECHECK":
                return <EcheckTableHeader linesDetail={linesDetail} comeFromProcessed={comeFromProcessed} />;
            case "RETENTION":
                return <RetentionTableHeader linesDetail={linesDetail} comeFromProcessed={comeFromProcessed} />;
            case "ERROR":
                return (
                    <>
                        <Table.Header>
                            <Table.HeaderData align="left">
                                <I18n id="forms.payments.suppliers.linesDetail.error.header.location.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left">
                                <I18n id="forms.payments.suppliers.linesDetail.error.header.fieldError.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left">
                                <I18n id="forms.payments.suppliers.linesDetail.error.header.initialPosition.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left">
                                <I18n id="forms.payments.suppliers.linesDetail.error.header.finalPosition.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left">
                                <I18n id="forms.payments.suppliers.linesDetail.error.header.errorType.label" />
                            </Table.HeaderData>
                        </Table.Header>
                    </>
                );
            case "REJECTED":
                return (
                    <>
                        <Table.Header>
                            <Table.HeaderData align="left" styles={{ minWidth: "120px" }}>
                                <I18n id="forms.payments.suppliers.detail.rejected.headerInfo.debitAccount.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left">
                                <I18n id="forms.payments.suppliers.detail.rejected.headerInfo.receiver.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left" styles={{ maxWidth: "115px" }}>
                                <I18n id="forms.payments.suppliers.detail.rejected.headerInfo.date.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left">
                                <I18n id="forms.payments.suppliers.detail.rejected.headerInfo.reason.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left" styles={{ minWidth: "115px" }}>
                                <I18n id="forms.payments.suppliers.detail.rejected.headerInfo.voucher.label" />
                            </Table.HeaderData>
                            <Table.HeaderData align="right">
                                <I18n id="forms.payments.suppliers.detail.rejected.headerInfo.amount.label" />
                            </Table.HeaderData>
                        </Table.Header>
                    </>
                );
            default: {
                return null;
            }
        }
    };

    const renderListMobile = (detail) => {
        switch (type) {
            case "TRANSFER":
                return (
                    <TransferItem detail={detail} isMobile headInfo={headInfo} comeFromProcessed={comeFromProcessed} />
                );
            case "CHECK":
                return <CheckItem detail={detail} isMobile headInfo={headInfo} comeFromProcessed={comeFromProcessed} />;
            case "ECHECK":
                return (
                    <EcheckItem detail={detail} isMobile headInfo={headInfo} comeFromProcessed={comeFromProcessed} />
                );
            case "RETENTION":
                return (
                    <RetentionItem detail={detail} isMobile headInfo={headInfo} comeFromProcessed={comeFromProcessed} />
                );
            case "ERROR":
                return (
                    <>
                        <GenericItem>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.linesDetail.error.header.location.label" />
                                </div>
                                <div className="data-text">{detail.line}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.linesDetail.error.header.fieldError.label" />
                                </div>
                                <div className="data-text">{detail.field}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.linesDetail.error.header.initialPosition.label" />
                                </div>
                                <div className="data-text">{detail.starPos}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.linesDetail.error.header.finalPosition.label" />
                                </div>
                                <div className="data-text">{detail.endPos}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.linesDetail.error.header.errorType.label" />
                                </div>
                                <div className="data-text">{detail.description || detail.validationFail}</div>
                            </div>
                        </GenericItem>
                    </>
                );
            case "REJECTED":
                return (
                    <>
                        <GenericItem>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.detail.rejected.header.debitAccount.label" />
                                </div>
                                <div className="data-text">{detail.debitAccount}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.detail.rejected.header.receiver.label" />
                                </div>
                                <div className="data-text">{detail.receiver}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.detail.rejected.header.dates.label" />
                                </div>
                                <div className="data-text">{detail.presentationDate}</div>
                                <div className="data-text">{detail.compensationDate}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.detail.rejected.header.reason.label" />
                                </div>
                                <div className="data-text">{detail.reason}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.detail.rejected.header.voucher.label" />
                                </div>
                                <div className="data-text">{detail.voucher}</div>
                            </div>
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="forms.payments.suppliers.detail.rejected.header.amount.label" />
                                    <FormattedAmount
                                        currency={detail.amount?.currency}
                                        quantity={detail.amount?.quantity}
                                    />
                                </div>
                            </div>
                        </GenericItem>
                    </>
                );
            default: {
                return null;
            }
        }
    };

    const filteredItems = () => {
        if (isDetail) {
            if (!isMobile) {
                return linesDetail.map((detail) => renderItem(detail));
            }
            return linesDetail.map((detail) => renderListMobile(detail));
        }
        if (!isMobile) {
            return linesWithError.map((item) => renderItem(item));
        }
        return linesWithError.map((item) => renderListMobile(item));
    };

    const renderList = () => (
        <>
            <Table className="gridTable">
                {!isMobile && renderTableHeader()}
                <Table.Body>{filteredItems()}</Table.Body>
            </Table>
            <SeuoLabel />
            {isDownloadButton && (
                <ButtonToDownloadMultipleItems paymentType={type} headInfo={headInfo} linesDetail={linesDetail} />
            )}
        </>
    );

    const downloadFile = (format) => {
        if (comeFromProcessed) {
            const processDate = headInfo?.processDate || "";
            dispatch(
                supplierActions.getFileContentProcessed(
                    comeFromProcessed,
                    typeFile,
                    nameFileDetail,
                    format,
                    processDate,
                ),
            );
        } else if (type === "ERROR") {
            dispatch(supplierActions.downloadPaymentErrorsFile(nameFileError.slice(0, -4), format, linesWithError));
        } else {
            dispatch(
                supplierActions.getFileContent(
                    idTransaction || idTransactionPending,
                    nameFileDetail.slice(0, -4),
                    format,
                    idDownloadFileSent || idFileCharged,
                ),
            );
        }
    };

    const handleShowPermissionModal = () => {
        if (showDownloadModalState === true) {
            setShowDownloadModalState(false);
        } else {
            dispatch(supplierActions.setDownloadModal(false));
        }
    };

    return (
        <>
            <Notification scopeToShow="suplierList" />

            <Head
                showLogo={isMobile}
                additionalClassName={isMobile ? "blue-main-header-mobile" : ""}
                onBack={history.goBack}
                arrowWhite={isMobile}
                DropdownCustom={!comeFromProcessed ? "pays.suppliers" : ""}
                addLinkToLabel="global.download"
                isFetchingExport={fetchingExport || fetchingDownloadFile}
                handleClick={downloadFile}
            />

            <MainContainer>
                <div className="container-left">
                    {isFetching ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="title-left-header">
                                {isDetail ? (
                                    <h1>{nameFileDetail.slice(0, -4)}</h1>
                                ) : (
                                    <h1>{nameFileError.slice(0, -4)}</h1>
                                )}
                                {typeFile === "rejected" ? (
                                    <I18n id="forms.payments.suppliers.rejected.detail.title.label" component="h2" />
                                ) : (
                                    <I18n
                                        id={`forms.payments.suppliers.${
                                            isDetail ? "detail" : "linesDetail.error"
                                        }.title.label`}
                                        component="h2"
                                        componentProps={{ className: !isDetail ? "ui-text-error" : "" }}
                                    />
                                )}
                            </div>

                            {isDetail && typeFile !== "rejected" && (
                                <div className="suppliers-payment__detail">
                                    <div className="content-data detail-head-info">{renderHeaderInfo()}</div>
                                </div>
                            )}

                            {renderList()}
                        </>
                    )}
                </div>
            </MainContainer>

            {(showDownloadModalState || showDownloadModal) && (
                <SimpleModal
                    modalIcon="images/success.svg"
                    showActions={false}
                    closeModal={handleShowPermissionModal}
                    isDisplayed
                    modalMainText={
                        isDetail
                            ? "forms.payments.suppliers.downloadDetailModal.label"
                            : "forms.payments.suppliers.downloadlinesErrorModal.label"
                    }
                />
            )}
        </>
    );
};

SupplierList.propTypes = {
    isMobile: bool,
    history: shape({}).isRequired,
    typeFile: string.isRequired,
    headInfo: shape({}).isRequired,
    linesDetail: oneOfType([array]).isRequired,
    nameFileDetail: string.isRequired,
    linesWithError: oneOfType([array]).isRequired,
    nameFileError: string.isRequired,
    isFetching: bool.isRequired,
    dispatch: func.isRequired,
    idTransaction: string.isRequired,
    showDownloadModal: bool.isRequired,
    comeFromProcessed: bool.isRequired,
    idTransactionPending: string,
    idDownloadFileSent: string.isRequired,
    idFileCharged: string.isRequired,
    fetchingExport: bool.isRequired,
    fetchingDownloadFile: bool.isRequired,
};

SupplierList.defaultProps = {
    isMobile: false,
    idTransactionPending: null,
};

const mapStateToProps = (state) => ({
    linesDetail: suppliersSelectors.getLinesDetail(state),
    typeFile: suppliersSelectors.getTypeFileDetail(state),
    nameFileDetail: suppliersSelectors.getNameFileDetail(state),
    headInfo: suppliersSelectors.getHeadInfo(state),
    linesWithError: suppliersSelectors.getlinesWithErrors(state),
    nameFileError: suppliersSelectors.getNameFileError(state),
    isFetching: suppliersSelectors.isFetching(state),
    base64fileDownload: suppliersSelectors.getBase64File(state),
    idTransaction: suppliersSelectors.getIdTransactionTicket(state),
    showDownloadModal: suppliersSelectors.getShowDownloadModal(state),
    comeFromProcessed: suppliersSelectors.getComeFromProcessed(state),
    idTransactionPending: suppliersSelectors.getIdTransactionPending(state),
    idDownloadFileSent: suppliersSelectors.getIdDownloadFileSent(state),
    idFileCharged: suppliersSelectors.getidFileCharged(state),
    fetchingExport: suppliersSelectors.isFetchingExport(state),
    fetchingDownloadFile: suppliersSelectors.getFetchingDownloadFiles(state),
});

export default compose(connect(mapStateToProps), withRouter)(SupplierList);
