import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { func, shape, bool, string } from "prop-types";

import { actions as formActions, selectors as formSelector } from "reducers/form";
import * as utils from "util/general";

import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18nUtils from "../../../../util/i18n";
import FormTransition from "../../_components/FormTransition";

const ID_ACTIVITY = "transactions.multiple.signatures.send";
const ID_ACTIVITY_PRE = "transactions.multiple.signatures.pre";
const MultipleSignatureResume = (props) => {
    const { dispatch, transaction, data, location, history } = props;

    const { transactions: transactionsOperation } = data;
    const title = i18nUtils.get(`activities.${ID_ACTIVITY}`);
    const fullTitle = title;

    const { ...restPreData } = props?.preData || {};
    const idTransaction = transaction?.idTransaction;
    const preData = data?.preData || props?.preData;

    const handleClickDownload = () => dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    const handleBack = () => history.goBack();

    useEffect(() => {
        const requestData = {
            transactions: transactionsOperation,
        };
        dispatch(
            formActions.preForm({
                idActivity: ID_ACTIVITY_PRE,
                requestData,
            }),
        );
    }, [dispatch, location, transaction]);

    const renderTransactionDetail = (item) => {
        const { idTransaction: idTransactionDetail, idTransactionStatus, activityName, data: itemData } = item;

        return (
            <div key={idTransactionDetail} className="transaction-item-details">
                <div className="data-wrapper">
                    <div className="data-label">{activityName}</div>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">{i18nUtils.get("transactions.multiple.sign.idTransaction.label")}</div>
                    <Link to={`/transaction/${idTransactionDetail}`} className="data-text">
                        {idTransactionDetail}
                    </Link>
                </div>
                {(itemData?.totalAmount || itemData?.amount) && (
                    <div className="data-wrapper">
                        <div className="data-label">{i18nUtils.get("transactions.multiple.sign.amount.label")}</div>
                        <FormattedAmount
                            currency={itemData.totalAmount?.currency || itemData.amount?.currency}
                            quantity={itemData.totalAmount?.quantity || itemData.amount?.quantity}
                        />
                    </div>
                )}
                <div className="data-wrapper">
                    <div className="data-label">
                        {i18nUtils.get("transactions.multiple.sign.transactionStatus.label")}
                    </div>
                    <div
                        className={`data-text ui-text-uppercase ${utils.getTransactionStatusColor(
                            idTransactionStatus,
                        )}`}>
                        {i18nUtils.get(`transaction.status.${idTransactionStatus}`)}
                    </div>
                </div>
            </div>
        );
    };

    const renderTransactionStatus = () => {
        if (preData?.transactionsList) {
            return (
                <>
                    <hr />
                    <div className="transaction-item-container">
                        {preData.transactionsList.map((item) => renderTransactionDetail(item))}
                    </div>
                </>
            );
        }

        return <></>;
    };

    const renderFields = () => renderTransactionStatus();

    const formProps = {
        title: `${fullTitle}`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            idActivity: ID_ACTIVITY,
        },
        data: {},
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };

    return <FormTransition {...props} {...formProps} />;
};

MultipleSignatureResume.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    isMobile: false,
};

MultipleSignatureResume.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    mode: string.isRequired,
    location: shape({}).isRequired,
    isMobile: bool,
    fetching: bool.isRequired,
    useDefaultSubmit: bool,
};

const mapStateToProps = (state) => ({
    transaction: formSelector.getTransaction(state),
    data: formSelector.getData(state),
    preData: formSelector.getPreData(state),
    mode: formSelector.getMode(state),
    fetching: formSelector.getFetching(state),
});

export default compose(connect(mapStateToProps), withRouter)(MultipleSignatureResume);
