import { LOCATION_CHANGE } from "react-router-redux";

import types from "reducers/types/form";
import transactionLinesReducer from "reducers/form/transactionLines";
import { shouldKeepFormState } from "reducers/helpers/form";
import { createReducer, makeActionCreator } from "util/redux";
import { toggleParamsFromFalse, toggleParamsFromTrue } from "util/boolean";
import { types as investmentTypes } from "reducers/investments";
import { types as accountTypes } from "reducers/accounts";
import { types as suppliersTypes } from "reducers/paymentToSuppliers";

export const INITIAL_STATE = {
    id: null,
    values: null,
    fetching: true,
    fetchingDownloadTicket: false,
    metadata: { fieldList: [], formNameMap: {} },
    transaction: {},
    childrenTransactions: null,
    parentTransaction: null,
    data: {},
    credentialsGroups: [],
    name: "",
    submitAction: null,
    submitActionParams: null,
    mode: "edit",
    prevMode: "edit",
    previewData: null,
    preData: null,
    isCancellingTransaction: false,
    prevRoute: { pathname: "" },
    previewFormErrors: [],
    userTransactionDetail: null,
    multipleTransactionsSuccessMessage: null,
    transactionsRequireSignature: false,
    comeFromDetail: false,
    errorMessage: null,
    submitDisabled: false,
};

const setPreState = (state, action) => ({
    ...state,
    submitActionParams: {
        values: { scheduler: null },
    },
    data: { ...state.data, ...action.formData },
    fetching: false,
    preData: action.preData,
});

const setPreviewState = (state, action) => ({
    ...state,
    credentialsGroups: action.credentialsGroups,
    submitAction: action.submitAction,
    submitActionParams: {
        values: { scheduler: null },
        ...action.submitActionParams,
    },
    data: { ...state.data, ...action.submitActionParams.values },
    fetching: false,
    prevMode: action?.prevMode || state.mode,
    mode: action.mode,
    previewData: action.previewData,
});

const formReducer = createReducer(INITIAL_STATE, {
    [LOCATION_CHANGE]: (state, action) => {
        if (shouldKeepFormState(action.payload, state.prevRoute)) {
            return { ...state, prevRoute: action.payload };
        }

        return { ...INITIAL_STATE, prevRoute: action.payload };
    },
    [types.READ_FORM_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        id: action.idForm,
        metadata: action.formMetadata,
        data: { ...state.data, ...action.formData },
        transaction: {},
        mode: "edit",
    }),
    [types.SET_MODE]: (state, action) => ({
        ...state,
        mode: action.mode,
        prevMode: action.prevMode,
    }),
    [types.SET_PREVIEW_DATA]: (state, action) => ({
        ...state,
        previewData: { ...state.previewData, ...action.payload },
    }),
    [types.SET_DATA]: (state, action) => ({
        ...state,
        data: { ...state.data, ...action.payload },
    }),
    [types.PRE_FORM_REQUEST]: (state, action) => toggleParamsFromFalse({ ...state, values: action.values }, "fetching"),
    [types.PRE_FORM_SUCCESS]: (state, action) => setPreState(state, action),
    [types.PRE_FORM_FAILURE]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.PREVIEW_FORM_REQUEST]: (state, action) => ({ ...state, values: action.payload.values }),
    [types.PREVIEW_FORM_SUCCESS]: setPreviewState,
    [types.ADD_EXTRA_PARAMS]: (state, action) => ({
        ...state,
        submitActionParams: {
            ...state.submitActionParams,
            values: {
                ...state.submitActionParams.values,
                ...action.values,
            },
        },
    }),
    [types.SEND_FORM_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SEND_FORM_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: [],
        idTransaction: action.idTransaction,
        fetching: false,
        transaction: action.transaction,
        data: action.transaction.data,
        mode: "view",
    }),
    [types.SEND_FORM_USER_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: [],
        idTransaction: action.userTransactionDetail.idTransaction,
        fetching: false,
        userTransactionDetail: action.userTransactionDetail,
        data: action.userTransactionDetail,
        mode: "view",
    }),
    [types.SEND_FORM_MULTIPLE_TRANSACTIONS_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: [],
        idTransaction: action.idTransaction,
        fetching: false,
        mode: "view",
        multipleTransactionsSuccessMessage: action.message,
        transactionsRequireSignature: action.transactionsRequireSignature,
    }),
    [types.MODIFY_TRANSACTION_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.MODIFY_TRANSACTION_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.READ_TRANSACTION_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.READ_TRANSACTION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.transaction,
        childrenTransactions: action.childrenTransactions,
        parentTransaction: action.parentTransaction,
        id: action.idForm,
        data: action.transaction.data,
        metadata: action.formMetadata,
        mode: action.transaction.idTransactionStatus === "DRAFT" ? "edit" : "view",
    }),
    [types.READ_USER_TRANSACTION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.userTransactionDetail,
        childrenTransactions: null,
        parentTransaction: null,
        id: action.idForm,
        data: action.userTransactionDetail,
        userTransactionDetail: action.userTransactionDetail,
        mode: action.userTransactionDetail.idTransactionStatus === "DRAFT" ? "edit" : "view",
    }),
    [types.READ_TRANSACTION_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SIGN_TRANSACTION_PREVIEW_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.SIGN_TRANSACTION_PREVIEW_SUCCESS]: setPreviewState,
    [types.CANCEL_TRANSACTION_PRE_REQUEST]: (state) =>
        toggleParamsFromFalse(state, "fetching", "isCancellingTransaction"),
    [types.CANCEL_TRANSACTION_PRE_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: action.credentialsGroups,
        fetching: false,
    }),
    [types.CANCEL_TRANSACTION_PRE_ERROR]: (state) => toggleParamsFromTrue(state, "fetching", "isCancellingTransaction"),
    [types.CANCEL_TRANSACTION_SUCCESS]: (state) => toggleParamsFromTrue(state, "isCancellingTransaction"),
    [types.SAVE_DRAFT_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.SAVE_DRAFT_SUCCESS]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SAVE_DRAFT_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SEND_FORM_DATA_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.SEND_FORM_DATA_FAILURE_NO_PRE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.CLOSE_TRANSACTION_PREVIEW]: (state) => ({
        ...state,
        mode: state.prevMode,
    }),
    [types.GO_BACK_CLEAN_TRANSACTION]: (state) => ({
        ...state,
        mode: state.prevMode,
        transaction: INITIAL_STATE.transaction,
    }),
    [types.READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.transaction,
        id: action.idForm,
        data: action.transaction.data,
        metadata: action.formMetadata,
        mode: "view",
    }),
    [types.READ_USER_TRANSACTION_FROM_BACKOFFICE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        userTransactionDetail: action.userTransactionDetail,
        transaction: action.userTransactionDetail,
        id: action.idForm,
        data: action.data,
        mode: "view",
    }),
    [types.DOWNLOAD_TICKET_REQUEST]: (state) => ({
        ...state,
        fetchingDownloadTicket: true,
    }),
    [types.DOWNLOAD_TICKET_CUSTOM_REQUEST]: (state) => ({
        ...state,
        fetchingDownloadTicket: true,
    }),
    [types.DOWNLOAD_TICKET_FAILURE]: (state) => ({
        ...state,
        fetchingDownloadTicket: false,
    }),
    [types.DOWNLOAD_TICKET_SUCCESS]: (state) => ({
        ...state,
        fetchingDownloadTicket: false,
    }),
    [investmentTypes.SIMULATE_DEPOSIT_SUCCESS]: (state) => ({
        ...state,
        previewData: INITIAL_STATE.previewData,
    }),
    [accountTypes.CBU_ALIAS_VIEW]: (state) => ({
        ...state,
        fetching: false,
        mode: "view",
    }),
    [types.SEND_FORM_CREDENTIAL_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.PREVIEW_FORM_ERRORS]: (state, action) => ({
        ...state,
        previewFormErrors: action.errors,
    }),
    [suppliersTypes.COME_FROM_DETAIL]: (state, action) => ({
        ...state,
        comeFromDetail: action.comeFromDetail,
    }),
    [types.SET_ERROR_MESSAGE]: (state, action) => ({
        ...state,
        errorMessage: action.errorMessage,
    }),
    [types.SET_CANCELLING_TRANSACTION]: (state, action) => ({
        ...state,
        isCancellingTransaction: action.isCancellingTransaction,
    }),
    [types.CANCEL_REQUEST]: (state, action) => ({
        ...state,
        idTransaction: action.idTransaction,
        credentials: action.credentials,
        fetching: true,
    }),
    [types.CANCEL_REQUEST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.CANCEL_REQUEST_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.DISABLE_SUBMIT]: (state) => ({
        ...state,
        submitDisabled: true,
    }),
});

export const actions = {
    preForm: makeActionCreator(types.PRE_FORM_REQUEST, "values"),
    previewForm: makeActionCreator(types.PREVIEW_FORM_REQUEST),
    sendForm: makeActionCreator(types.SEND_FORM_REQUEST),
    saveDraft: makeActionCreator(types.SAVE_DRAFT_REQUEST),
    cancelTransactionPre: makeActionCreator(types.CANCEL_TRANSACTION_PRE_REQUEST),
    cancelTransaction: makeActionCreator(types.CANCEL_TRANSACTION_REQUEST),
    modifyTransaction: makeActionCreator(types.MODIFY_TRANSACTION_REQUEST, "idTransaction"),
    signTransactionPreview: makeActionCreator(types.SIGN_TRANSACTION_PREVIEW_REQUEST),
    signTransaction: makeActionCreator(types.SIGN_TRANSACTION_REQUEST),
    closeConfirmation: makeActionCreator(types.CLOSE_TRANSACTION_PREVIEW),
    setPreviewData: makeActionCreator(types.SET_PREVIEW_DATA),
    setData: makeActionCreator(types.SET_DATA),
    readTransaction: makeActionCreator(types.READ_TRANSACTION_REQUEST),
    formClosed: makeActionCreator(types.FORM_CLOSED),
    downloadTicket: makeActionCreator(types.DOWNLOAD_TICKET_REQUEST, "idTicket", "format", "idForm"),
    shareTicket: makeActionCreator(types.SHARE_TICKET, "idTicket", "format", "idForm"),
    setMode: makeActionCreator(types.SET_MODE, "mode", "prevMode"),
    addExtraParams: makeActionCreator(types.ADD_EXTRA_PARAMS, "values"),
    goBackCleanTransaction: makeActionCreator(types.GO_BACK_CLEAN_TRANSACTION),
    downloadTicketCustom: makeActionCreator(types.DOWNLOAD_TICKET_CUSTOM_REQUEST, "idTransaction", "idActivity"),
    setPreviewFormErrors: makeActionCreator(types.PREVIEW_FORM_ERRORS, "errors"),
    setErrorMessage: makeActionCreator(types.SET_ERROR_MESSAGE, "errorMessage"),
    setCancellingTransaction: makeActionCreator(types.SET_CANCELLING_TRANSACTION, "isCancellingTransaction"),
    cancelRequest: makeActionCreator(types.CANCEL_REQUEST, "idTransaction", "credentials", "cancelReason"),
    disableSubmit: makeActionCreator(types.DISABLE_SUBMIT),
};

export default (state = INITIAL_STATE, action) => ({
    ...formReducer(state, action),
    transactionLines: transactionLinesReducer(state.transactionLines, action),
});

export const selectors = {
    getId: ({ form }) => form.id,
    getFetching: ({ form }) => form.fetching,
    isFetchingDownloadTicket: ({ form }) => form.fetchingDownloadTicket,
    getMetadata: ({ form }) => form.metadata,
    getCredentialsGroups: ({ form }) => form.credentialsGroups,
    getTransaction: ({ form }) => form.transaction,
    getChildrenTransactions: ({ form }) => form.childrenTransactions,
    getParentTransaction: ({ form }) => form.parentTransaction,
    getTransactionFormMetadata: ({ form }) => form.metadata,
    getData: ({ form }) => form.data,
    getValues: ({ form }) => form.values,
    getName: ({ form }) => form.name,
    getSubmitAction: ({ form }) => form.submitAction,
    getSubmitActionParams: ({ form }) => form.submitActionParams,
    getMode: ({ form }) => form.mode,
    getPrevMode: ({ form }) => form.prevMode,
    getPreData: ({ form }) => form.preData,
    getPreviewData: ({ form }) => {
        if (form.previewData?.notificationMails && form.previewData.notificationMails.length > 0) {
            const tags = [];
            form.previewData.notificationMails.forEach((item) => {
                tags.push({ id: item, text: item });
            });
            return { ...form.previewData, notificationMails: tags };
        }
        return form.previewData;
    },
    getField: ({ form }, id) => form.metadata.fieldList.find(({ idField }) => idField === id),
    getIsCancellingTransaction: ({ form }) => form.isCancellingTransaction,
    getErrors: ({ form }) => form.previewFormErrors,
    getUserTransactionDetail: ({ form }) => form.userTransactionDetail,
    getMultipleTransactionsRequireSignature: ({ form }) => form.transactionsRequireSignature,
    getMultipleTransactionsSuccessMessage: ({ form }) => form.multipleTransactionsSuccessMessage,
    getComeFromDetail: ({ form }) => form.comeFromDetail,
    getErrorMessage: ({ form }) => form.errorMessage,
    getSubmitDisabled: ({ form }) => form.submitDisabled,
};
