import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import { any, bool, func, instanceOf, number, arrayOf, shape, string, oneOfType } from "prop-types";
import React, { Component } from "react";

import classNames from "classnames";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import {
    types as transactionsTypes,
    actions as transactionsActions,
    selectors as transactionsSelectors,
} from "reducers/transactions";
import { actions as supplierActions } from "reducers/paymentToSuppliers";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { actions as massPaymentActions } from "reducers/massPayments";
import defaultDateFromList from "util/defaultDateFromList";

import HiddenFilters from "pages/transactions/_components/HiddenFilters";
import List from "pages/transactions/_components/List";
import MobileFilters from "pages/transactions/_components/MobileFilters";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import ProductFilters from "pages/_components/ProductFilters";
import moment from "moment";
import { validateToResetFilters } from "util/filters";

const LAST_TRANSACTIONS = "last";
const PREVIOUS_MONTH = "previousMonth";
const ACTUAL_MONTH = "actualMonth";
const ID_ACTIVITY = "transactions.list";

class TransactionsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        filters: shape({
            dateFrom: oneOfType([instanceOf(Date), instanceOf(moment)]),
            dateTo: oneOfType([instanceOf(Date), instanceOf(moment)]),
            minAmount: number,
            maxAmount: number,
            pageNumber: number,
        }),
        selectedFilter: string.isRequired,
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        transactions: arrayOf(any),
        isResetQuery: bool.isRequired,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        filtersSaved: shape().isRequired,
        idActivityOnFilter: string.isRequired,
        showFilter: bool.isRequired,
        reloadData: bool.isRequired,
    };

    static defaultProps = {
        filters: null,
        transactions: [],
    };

    componentDidMount = () => {
        const { filters, isResetQuery, dispatch, idActivityOnFilter, reloadData } = this.props;
        dispatch(massPaymentActions.resetData());
        dispatch(supplierActions.resetUploadedFileData());

        let defaultFilters = {};
        dispatch(filterActions.validateToResetFilters(transactionsTypes.RESET_FILTERS, ID_ACTIVITY));
        if (validateToResetFilters(isResetQuery, idActivityOnFilter, ID_ACTIVITY)) {
            const dateFrom = defaultDateFromList().listTransaction;
            const dateTo = moment();
            defaultFilters = {
                dateFrom,
                dateTo,
                pageNumber: 1,
            };
            dispatch(transactionsActions.setSelecterFilter(LAST_TRANSACTIONS));
            dispatch(transactionsActions.loadListRequest(defaultFilters, false, false, true));
        } else {
            defaultFilters = filters;

            if (reloadData) {
                dispatch(transactionsActions.loadListRequest(defaultFilters, false, false, true));
            }
        }

        dispatch(filterActions.setReloadData(false));
    };

    resetSelectedFilter = () => {
        const { dispatch } = this.props;
        dispatch(transactionsActions.setSelecterFilter("more"));
    };

    handleMoreFiltersClick = () => {
        this.resetSelectedFilter();
        this.onToggleFilters();
    };

    handleFilterButtonsClick = (idFilter) => {
        const { dispatch } = this.props;

        let filters = {};

        switch (idFilter) {
            case LAST_TRANSACTIONS: {
                const dateFrom = defaultDateFromList().listTransaction;
                const dateTo = moment();
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                };
                break;
            }
            case PREVIOUS_MONTH: {
                const now = new Date();
                const dateFrom = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                const dateTo = new Date(now.getFullYear(), now.getMonth(), 0);
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                };
                break;
            }
            case ACTUAL_MONTH: {
                const now = new Date();
                const dateFrom = new Date(now.getFullYear(), now.getMonth(), 1);
                const dateTo = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                };
                break;
            }
            default:
                break;
        }
        dispatch(transactionsActions.setSelecterFilter(idFilter));
        dispatch(filterActions.setToggleFilters(false));
        dispatch(transactionsActions.loadListRequest(filters, false, false, false));
    };

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.toggleShowFilters());
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }

        return <Head />;
    };

    render() {
        const {
            fetching,
            isDesktop,
            dispatch,
            isMobile,
            transactions,
            activeEnvironment,
            filtersSaved,
            showFilter,
            filters: defaultFilters,
            selectedFilter,
        } = this.props;

        const isCorporateGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;

        const showFilters = (transactions?.length && !fetching) || true;

        const transactionFilters = [
            <Button
                className={classNames("btn btn-link", {
                    "is-active": selectedFilter === LAST_TRANSACTIONS,
                })}
                key={LAST_TRANSACTIONS}
                label="transactions.list.filter.last"
                onClick={() => this.handleFilterButtonsClick(LAST_TRANSACTIONS)}
            />,
            <Button
                className={classNames("btn btn-link", {
                    "is-active": selectedFilter === ACTUAL_MONTH,
                })}
                key={ACTUAL_MONTH}
                label="transactions.list.filter.actualMonth"
                onClick={() => this.handleFilterButtonsClick(ACTUAL_MONTH)}
            />,
            <Button
                className={classNames("btn btn-link", {
                    "is-active": selectedFilter === PREVIOUS_MONTH,
                })}
                key={PREVIOUS_MONTH}
                label="transactions.list.filter.previousMonth"
                onClick={() => this.handleFilterButtonsClick(PREVIOUS_MONTH)}
            />,
        ];

        return (
            <>
                <Notification scopeToShow="transactions" />

                {this.renderHeader()}

                <MainContainer>
                    <div className="container-left">
                        <div className="title-left-header ui-mb-0">
                            <I18n id="transactions.list.title" component="h1" />
                        </div>
                        {isDesktop && (
                            <ProductFilters
                                closeMoreFiltersWhenSiblingClick
                                forceOpen={showFilter}
                                handleMoreFiltersClick={this.handleMoreFiltersClick}
                                moreFilters={
                                    <HiddenFilters
                                        dispatch={dispatch}
                                        isDesktop={isDesktop}
                                        idForm={ID_ACTIVITY}
                                        resetSelectedFilter={this.resetSelectedFilter}
                                        filtersSaved={showFilter ? filtersSaved : null}
                                        isTransactionList
                                    />
                                }
                                moreFiltersClosedKeyLabel="accounts.movements.filters.more"
                                moreFiltersOpenedKeyLabel="accounts.movements.filters.less"
                                isDesktop={isDesktop}>
                                {showFilters && transactionFilters}
                            </ProductFilters>
                        )}
                        {isMobile && (
                            <>
                                <div className="info-head-movements">
                                    <ul className="nav nav-pills movement-commands">
                                        {transactionFilters.map((filter) => (
                                            <li>{filter}</li>
                                        ))}
                                    </ul>
                                </div>
                                <hr />
                                <Button
                                    onClick={this.onToggleFilters}
                                    bsStyle="filter"
                                    className="btn-link"
                                    block
                                    image={`images/${!showFilter ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                                    label={!showFilter ? "transactions.filter.more" : "transactions.filter.less"}
                                />

                                {showFilter && (
                                    <MobileFilters
                                        dispatch={dispatch}
                                        isMobile={isMobile}
                                        fetching={fetching}
                                        idForm={ID_ACTIVITY}
                                    />
                                )}
                            </>
                        )}

                        <List
                            isDesktop={isDesktop}
                            showSearchButton
                            defaultFilters={defaultFilters}
                            showEnvironment={isCorporateGroup}
                            itemsAreClickeable={!isCorporateGroup}
                            transactions={transactions}
                        />
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: transactionsSelectors.getFetching(state),
    filters: transactionsSelectors.getFilters(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transactions: transactionsSelectors.getTransactions(state),
    filtersSaved: transactionsSelectors.getFilters(state),
    selectedFilter: transactionsSelectors.getSelectedFilter(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
    showFilter: filterSelectors.getShowFilters(state),
    reloadData: filterSelectors.isReloadData(state),
});

export default connect(mapStateToProps)(TransactionsList);
