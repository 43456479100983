import * as API from "middleware/api";

export const downloadPaymentToSuppliersExampleFile = (tipoOfFile) =>
    API.executeWithAccessToken("/pays.supplier.files.example.download", { tipoOfFile });

export const saveFile = (fileToSave, fileName, fileType, fileDescription, fileIdActivity) =>
    API.executeWithAccessToken("/pays.supplier.files.validation.save", {
        fileToSave,
        fileName,
        fileType,
        fileDescription,
        fileIdActivity,
    });

export const deleteFile = (idFile) => API.executeWithAccessToken("/pays.supplier.delete.file", { idFile });

export const getFiles = (filters) =>
    API.executeWithAccessToken("/pays.supplier.query.sentAndPending", {
        ...filters,
    });

export const seeDetail = (typeFile, fileToSave, processed, longFileName) =>
    API.executeWithAccessToken("/pays.supplier.see.detail", {
        typeFile,
        fileToSave,
        processed,
        longFileName,
    });

export const seeDetailById = (idFile) => API.executeWithAccessToken("pays.supplier.see.detail.id", { idFile });

export const getAnswerFilesRequest = (
    docTypeClient,
    docNumberClient,
    docType,
    dateFrom,
    dateTo,
    filterFileType,
    pageNumber,
    fileStatus,
) =>
    API.executeWithAccessToken("/pays.supplier.response.file.query", {
        docTypeClient,
        docNumberClient,
        docType,
        dateFrom,
        dateTo,
        filterFileType,
        pageNumber,
        fileStatus,
    });

export const downloadFileFromService = (longFileName, isMultidownload) =>
    API.executeWithAccessToken("/pays.supplier.download.file.fromService", { longFileName, isMultidownload });

export const getFileByName = (fileToDownload, idDownloadFileSent, format) =>
    API.executeWithAccessToken("/pays.supplier.get.file", { fileToDownload, idDownloadFileSent, format });

export const getFileProccedFile = (processed, typeFile, longFileName, format, processDate) =>
    API.executeWithAccessToken("/pays.supplier.get.file", { processed, typeFile, longFileName, format, processDate });

export const getErrors = (internalFileName, internalMemberFileName, longFileName) =>
    API.executeWithAccessToken("/pays.supplier.query.errors", {
        internalFileName,
        internalMemberFileName,
        longFileName,
    });

export const downloadFileWithErrors = (fileName, format, errorLines) =>
    API.executeWithAccessToken("/pays.supplier.get.error.file", { fileName, format, errorLines });

export const downloadVoucherDetails = (paymentType, downloadType, headInfo, payments, emissorName) =>
    API.executeWithAccessToken("/pays.supplier.voucher.download", {
        paymentType,
        type: downloadType,
        headInfo,
        payments,
        emissorName,
    });
