import React, { Children, Component } from "react";
import { Collapse } from "react-bootstrap";
import { bool, func, node, string } from "prop-types";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import classNames from "classnames";

class ProductFilters extends Component {
    static propTypes = {
        closeMoreFiltersWhenSiblingClick: bool,
        filtersKeyLabel: string,
        moreFilters: node,
        moreFiltersClosedKeyLabel: string,
        moreFiltersOpenedKeyLabel: string,
        children: node.isRequired,
        forceOpen: bool,
        handleMoreFiltersClick: func,
        className: string,
        alert: node,
    };

    static defaultProps = {
        closeMoreFiltersWhenSiblingClick: false,
        filtersKeyLabel: "",
        moreFilters: null,
        moreFiltersClosedKeyLabel: "",
        moreFiltersOpenedKeyLabel: "",
        handleMoreFiltersClick: null,
        forceOpen: false,
        className: "",
        alert: null,
    };

    state = {
        openMoreFilters: false,
    };

    componentDidMount() {
        const { forceOpen } = this.props;

        this.setState({
            openMoreFilters: forceOpen,
        });
    }

    handleMoreFiltersClick = () => {
        const { openMoreFilters } = this.state;
        const { handleMoreFiltersClick } = this.props;
        this.setState({ openMoreFilters: !openMoreFilters });
        if (handleMoreFiltersClick) {
            handleMoreFiltersClick();
        }
    };

    handleSiblingClick = () => {
        const { closeMoreFiltersWhenSiblingClick } = this.props;

        if (closeMoreFiltersWhenSiblingClick) {
            this.setState({ openMoreFilters: false });
        }
    };

    render() {
        const {
            children,
            filtersKeyLabel,
            moreFilters,
            moreFiltersClosedKeyLabel,
            moreFiltersOpenedKeyLabel,
            className,
            alert,
        } = this.props;

        const { openMoreFilters } = this.state;

        const clonedFiltersWithOpen = React.cloneElement(moreFilters, { openMoreFilters });

        return (
            <>
                <section className={className || ""}>
                    <div className="info-head-movements">
                        {filtersKeyLabel && (
                            <I18n
                                id={filtersKeyLabel}
                                component="h2"
                                componentProps={{ className: "ui-text-black ui-fw-regular" }}
                            />
                        )}
                        {children && (
                            <ul className="nav nav-pills movement-commands">
                                {Children.map(children, (child, index) => (
                                    // eslint-disable-next-line
                                    <li key={index} onClick={this.handleSiblingClick}>
                                        {child}
                                    </li>
                                ))}
                                {clonedFiltersWithOpen && moreFilters && (
                                    <li>
                                        <Button
                                            className={classNames({
                                                "is-active": openMoreFilters,
                                            })}
                                            label={
                                                openMoreFilters ? moreFiltersOpenedKeyLabel : moreFiltersClosedKeyLabel
                                            }
                                            onClick={this.handleMoreFiltersClick}
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                    </div>
                    {clonedFiltersWithOpen && moreFilters && (
                        <Collapse in={openMoreFilters}>
                            <div>{clonedFiltersWithOpen}</div>
                        </Collapse>
                    )}
                </section>
                {alert && !openMoreFilters && alert}
            </>
        );
    }
}

export default ProductFilters;
