import * as API from "middleware/api";
import { format } from "date-fns";
import { credentialsToUnderscoreFormat } from "util/form";

export const loadListRequest = (filters, onlyPendings, pendingDispatch) => {
    let { dateFrom, dateTo } = filters;
    const {
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        client,
        state,
        transactionType,
        keyWord,
        quickFilterActivities,
        others,
    } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    if (onlyPendings) {
        return API.executeWithAccessToken("/transactions.list.pending", {
            dateFrom,
            dateTo,
            pageNumber,
            pendingDispatch,
            client,
            state: state ? state[0] : null,
            transactionType: transactionType ? transactionType[0] : null,
            keyWordTransactionType: keyWord,
            quickFilterActivities,
            others,
        });
    }

    return API.executeWithAccessToken("/transactions.list", {
        dateFrom,
        dateTo,
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        pendingDispatch,
        client,
        state: state ? state[0] : null,
        transactionType: transactionType ? transactionType[0] : null,
        keyWordTransactionType: keyWord,
    });
};

export const deleteDraftRequest = (idTransaction) =>
    API.executeWithAccessToken("/core.deleteTransactionDraft", { idTransactionToDelete: idTransaction });

export const getPendingTransactionsQuantity = () => API.executeWithAccessToken("/transactions.get.pending.quantity");

export const loadTransactionDataToSign = (transactions) =>
    API.executeWithAccessToken("/transactions.multiple.signatures.pre", { transactions });

export const signMultipleTransactionsRequest = (transactions, idActivity, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    return API.executeWithAccessToken("/transactions.multiple.signatures.send", {
        transactions,
        idActivity,
        ...credentialsWithUnderscore,
    });
};
