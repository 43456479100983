import React, { Component } from "react";
import { bool, func, shape, string, arrayOf, oneOfType, any } from "prop-types";
import { connect } from "react-redux";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as sessionSelectors } from "reducers/session";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";

import GenericItem from "components/GenericItem/GenericItem";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import TransactionStatus from "pages/transactions/_components/TransactionStatus/TransactionStatus";
import * as i18nUtils from "util/i18n";
import Image from "pages/_components/Image";
import Checkbox from "pages/_components/fields/Checkbox";

class TransactionsItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isDeletingDraft: bool.isRequired,
        transaction: shape({
            cancelEnabled: bool,
            transaction: shape({
                idTransaction: string,
                creationDateTime: string,
                submitDateTime: string,
                activityName: string,
                idTransactionStatus: string,
                signatures: oneOfType([
                    shape({
                        idUser: string,
                    }),
                    arrayOf(any),
                ]),
            }),
            transactionAmounts: shape(),
        }).isRequired,
        showEnvironment: bool,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        onClick: func,
        onlyPendings: bool,
        user: shape({}).isRequired,
        multiSignAuthorizedList: arrayOf(string).isRequired,
        handleSelectTransaction: func,
        transactionsSelected: arrayOf(any),
        disabledCheckBox: bool,
        activityGroupSelected: string,
    };

    static defaultProps = {
        showEnvironment: false,
        onClick: null,
        onlyPendings: false,
        handleSelectTransaction: null,
        transactionsSelected: [],
        disabledCheckBox: false,
        activityGroupSelected: "",
    };

    handleDeleteTransactionDraft = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(transactionsActions.deleteDraftRequest(idTransaction));
    };

    onHandleClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };

    getSignIconOrText = (activeEnvironment, showAmount, signatures, userSign, type) => {
        const { userSignatureLevel, environmentSignaturesLevels } = activeEnvironment;

        if (signatures.length) {
            if (userSign) {
                if (type === "icon") {
                    return "ui-sign-ok.svg";
                }
                return "userOk";
            }

            let belongsToSignature = [];

            if (showAmount) {
                belongsToSignature = environmentSignaturesLevels.filter(
                    (item) => item.signatureType === "AMOUNT" && item.signatureGroup.includes(userSignatureLevel),
                );
            } else {
                belongsToSignature = environmentSignaturesLevels.filter(
                    (item) => item.signatureType === "NO_AMOUNT" && item.signatureGroup.includes(userSignatureLevel),
                );
            }

            if (belongsToSignature.length > 0) {
                if (type === "icon") {
                    return "ui-sign-pend.svg";
                }
                return "userPending";
            }
        }

        if (type === "icon") {
            return "ui-sign-pend-gray.svg";
        }

        return "allPending";
    };

    getSignedIconDesktop = (showAmount, signatures, userSign) => {
        const { activeEnvironment } = this.props;

        const signedText = this.getSignIconOrText(activeEnvironment, showAmount, signatures, userSign, "text");

        const tooltipProps = {
            "data-tooltip": i18nUtils.get(`transactions.list.item.signatures.${signedText}.tooltipLabel`),
            "data-tooltip-position": "top-right",
        };

        const signedIcon = this.getSignIconOrText(activeEnvironment, showAmount, signatures, userSign, "icon");

        return (
            <div className="table-data table-data-icon ui-text-center">
                <div {...tooltipProps}>
                    <Image src={`/images/ui-icons/${signedIcon}`} className="svg-icon" />
                </div>
            </div>
        );
    };

    getMobileCustomOption = (showAmount, signatures, userSign) => {
        const { activeEnvironment } = this.props;

        const signedText = this.getSignIconOrText(activeEnvironment, showAmount, signatures, userSign, "text");

        const tooltipProps = {
            "data-tooltip": i18nUtils.get(`transactions.list.item.signatures.${signedText}.tooltipLabel`),
            "data-tooltip-position": "top-right",
        };
        const signedIcon = this.getSignIconOrText(activeEnvironment, showAmount, signatures, userSign, "icon");

        return (
            <>
                {signedIcon ? (
                    <div className="ui-dflex-center" {...tooltipProps}>
                        <Image src={`/images/ui-icons/${signedIcon}`} className="svg-icon" />
                    </div>
                ) : null}
            </>
        );
    };

    renderAdditionalData = () => {
        const { isDesktop, transaction } = this.props;

        const transationsToShowAditionalData = ["transfers.thirdParties.send", "transfers.local.send"];

        if (false && transationsToShowAditionalData.includes(transaction.transaction.idActivity)) {
            if (isDesktop) {
                return <div className="data-aux">ADITIONAL DATA</div>;
            }

            return <div className="data-text">ADITIONAL DATA</div>;
        }
        return null;
    };

    renderCheckbox = (idTransaction, idActivity) => {
        const { transactionsSelected, handleSelectTransaction, disabledCheckBox } = this.props;

        return (
            <Checkbox
                key={idTransaction}
                name={idTransaction}
                label=""
                checked={Array.isArray(transactionsSelected) && transactionsSelected.includes(idTransaction)}
                onChange={() => handleSelectTransaction(idTransaction, idActivity)}
                hideLabel
                value={[idTransaction]}
                disabled={disabledCheckBox}
            />
        );
    };

    getFinalActivityName = (transaction) => {
        const { activityName, data, idActivity } = transaction;
        let finalActivityName = activityName;

        finalActivityName = data?.activitySuffix
            ? i18nUtils.get(`activities.${idActivity.replace("send", `${data.activitySuffix}.send`)}`)
            : activityName;

        return finalActivityName;
    };

    render() {
        const {
            isDesktop,
            isDeletingDraft,
            transaction: propsTransaction,
            showEnvironment,
            activeEnvironment,
            onlyPendings,
            user,
            multiSignAuthorizedList,
            activityGroupSelected,
        } = this.props;
        const { transaction, transactionAmounts } = propsTransaction;
        const {
            idTransaction,
            creationDateTime,
            submitDateTime,
            idTransactionStatus,
            environmentName,
            signatures,
            idActivity,
        } = transaction;

        const [currency] = Object.keys(transactionAmounts);
        const totalAmount =
            idActivity === "echeqs.emitEcheq.send" && transaction?.data?.emissionType?.[0] === "multiple"
                ? transaction?.totalAmount
                : null;
        const amount = totalAmount || transactionAmounts[currency] || false;
        const showAmount = amount !== 0 && !!amount;
        const isEconomicGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;
        const { userId } = user;
        const userSign = signatures?.find((userS) => userId === userS.idUser);
        const showCheckboxInput =
            onlyPendings &&
            idTransactionStatus !== "DRAFT" &&
            multiSignAuthorizedList.includes(idActivity) &&
            !userSign;

        const webContent = (
            <>
                {onlyPendings && (
                    <>
                        {showCheckboxInput && (activityGroupSelected === "" || activityGroupSelected === idActivity) ? (
                            <div className="table-data ui-text-left with-checkbox">
                                {this.renderCheckbox(idTransaction, idActivity)}
                            </div>
                        ) : (
                            <div className="table-data" />
                        )}
                    </>
                )}
                <div className="table-data table-data-icon ui-text-left">
                    <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel />
                </div>
                <div className="table-data">
                    <div className="data-aux">{this.getFinalActivityName(transaction)}</div>
                    {this.renderAdditionalData()}
                </div>
                {showEnvironment && (
                    <div className="table-data">
                        <span className="data-text">{environmentName}</span>
                    </div>
                )}
                <div className="table-data">
                    <span className="data-date text-left">
                        <FormattedDate date={creationDateTime} />
                    </span>
                </div>
                {!onlyPendings && (
                    <div className="table-data">
                        <span className="data-date text-left">
                            <FormattedDate date={submitDateTime} />
                        </span>
                    </div>
                )}
                <div className="table-data">
                    {showAmount && <FormattedAmount currency={currency} quantity={amount} />}
                </div>

                {onlyPendings &&
                    idTransactionStatus !== "DRAFT" &&
                    this.getSignedIconDesktop(showAmount, signatures, userSign)}

                {!isEconomicGroup && idTransactionStatus === "DRAFT" && (
                    <>
                        <div className="table-data table-data-icon ui-text-center">
                            <Button
                                type="button"
                                className="btn-only-icon btn-delete"
                                block={false}
                                loading={isDeletingDraft}
                                onClick={(e) => this.handleDeleteTransactionDraft(e, idTransaction)}
                                label="transactions.list.history.trashIcon.a11y"
                                image="images/ui-icons/ui-delete.svg"
                            />
                        </div>
                        <div className="table-data table-data-icon ui-text-center">
                            <Button
                                type="button"
                                className="btn-only-icon"
                                block={false}
                                label="transactions.list.history.trashIcon.a11y"
                                image="images/ui-icons/ui-edit-pen-list.svg"
                            />
                        </div>
                    </>
                )}

                {!isEconomicGroup && idTransactionStatus !== "DRAFT" && (
                    <div className="table-data table-data-icon">
                        <ChevromRight />
                    </div>
                )}
            </>
        );

        const mobileContent = (
            <>
                <GenericItem
                    role="button"
                    onClick={this.onHandleClick}
                    arrow
                    customArrowIcon={idTransactionStatus === "DRAFT" ? "images/ui-icons/ui-edit-pen-list.svg" : null}
                    customOption={
                        <>
                            {onlyPendings && idTransactionStatus !== "DRAFT"
                                ? this.getMobileCustomOption(showAmount, signatures, userSign)
                                : null}
                            {idTransactionStatus === "DRAFT" ? (
                                <>
                                    <Button
                                        type="button"
                                        className="btn-only-icon btn-delete"
                                        block={false}
                                        loading={isDeletingDraft}
                                        onClick={(e) => this.handleDeleteTransactionDraft(e, idTransaction)}
                                        label="transactions.list.history.trashIcon.a11y"
                                        image="images/ui-icons/ui-delete.svg"
                                    />
                                </>
                            ) : null}
                        </>
                    }
                    selectCheckbox={
                        showCheckboxInput && (
                            <>
                                {idTransactionStatus !== "DRAFT" && !userSign
                                    ? this.renderCheckbox(idTransaction)
                                    : null}
                            </>
                        )
                    }>
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="transactions.list.header.state" />
                        </div>
                        <div className="data-text">
                            <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel showIcon />
                        </div>
                    </div>
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="transactions.list.header.transactionName" />
                        </div>
                        <div className="data-text">{this.getFinalActivityName(transaction)}</div>
                        {this.renderAdditionalData()}
                    </div>
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="transactions.list.header.creationDate" />
                        </div>
                        <div className="data-text">
                            <FormattedDate date={creationDateTime} />
                        </div>
                    </div>
                    {!onlyPendings && (
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="transactions.list.header.submitDate" />
                            </div>
                            <div className="data-text">
                                <FormattedDate date={submitDateTime} />
                            </div>
                        </div>
                    )}
                    {showAmount && (
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="transactions.list.header.amount" />
                            </div>
                            <div className="data-amount">
                                <FormattedAmount currency={currency} quantity={amount} />
                            </div>
                        </div>
                    )}
                </GenericItem>
            </>
        );

        return isDesktop ? webContent : mobileContent;
    }
}

const mapStateToProps = (state) => ({
    isDeletingDraft: transactionsSelectors.isDeletingDraft(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(TransactionsItem);
