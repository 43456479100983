import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bool, string, func, arrayOf, objectOf, any, oneOfType } from "prop-types";
import Yup from "yup";

import { actions as filterActions } from "reducers/filters";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18n from "util/i18n";
import * as config from "util/config";
import defaultDateFromList from "util/defaultDateFromList";
import moment from "moment";

import AmountField from "pages/_components/fields/formik/AmountField";
import Button from "pages/_components/Button";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { Field, Form, withFormik } from "formik";
import { DateField } from "pages/_components/fields/DateField";
import { resizableRoute } from "pages/_components/Resizable";
import TextFieldSearch from "pages/_components/fields/TextFieldSearch";
import { clearFiltersAndSubmit } from "util/form";

class HiddenFilters extends Component {
    static propTypes = {
        fetching: bool,
        idForm: string.isRequired,
        isMobile: bool.isRequired,
        openMoreFilters: bool,
        resetForm: func.isRequired,
        onlyPendings: bool,
        transactionTypes: oneOfType([arrayOf(any), objectOf(any)]).isRequired,
        setFieldValue: func.isRequired,
        setValues: func.isRequired,
        currentLang: string.isRequired,
        submitForm: func.isRequired,
        values: objectOf(any).isRequired,
    };

    static defaultProps = {
        fetching: false,
        onlyPendings: false,
        openMoreFilters: false,
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        open: false,
    };

    componentDidMount() {
        this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    getStateTypes = () => {
        const states = config.get("transactionsHistory.stateTypes").split("|");
        const valuesFinal = states.map((valuesState) => ({
            id: valuesState,
            label: i18n.get(`transaction.status.${valuesState}`),
        }));
        return valuesFinal;
    };

    getSignStates = () => {
        const states = config.get("transactions.pending.sign.states", "Todos|SIGNED|NOT_SIGNED|DRAFT").split("|");
        const valuesFinal = states.map((state) => ({
            id: state,
            label: i18n.get(`transaction.pending.status.${state}`),
        }));
        return valuesFinal;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState !== null && nextProps.openMoreFilters !== prevState.open) {
            nextProps.resetForm();
            return { open: nextProps.openMoreFilters };
        }
        return null;
    }

    getTransactionTypes = () => {
        const { transactionTypes } = this.props;
        const values = transactionTypes;

        if (values.find((item) => item.id === "all") === undefined) {
            values.unshift({ id: "all", label: "Todos" });
        }

        return values;
    };

    handleOnChangeTransationType = () => {
        const { setFieldValue } = this.props;
        setFieldValue("keyWord", "");
    };

    handleOnChangeKeyWord = () => {
        const { setFieldValue } = this.props;
        setFieldValue("transactionType", ["all"]);
    };

    handleClearFilters = () => {
        const { setValues, submitForm } = this.props;

        clearFiltersAndSubmit(
            () =>
                setValues({
                    transactionType: ["all"],
                    state: ["Todos"],
                    dateFrom: defaultDateFromList().listTransaction,
                    dateTo: moment(),
                    keyWord: "",
                    minAmount: { amount: null },
                    maxAmount: { amount: null },
                }),
            submitForm,
        );
    };

    render() {
        const { idForm, fetching, isMobile, onlyPendings, currentLang, values } = this.props;

        return (
            <div
                ref={this.myRef}
                className={`filters-container ${onlyPendings ? "pending-transactions" : "transactions-history"} ${
                    !isMobile ? "ui-mb-8" : ""
                }`}>
                <Form className="form-content">
                    <div className="filters-row">
                        <Field
                            component={FormFieldsComponents.Selector}
                            optionList={this.getTransactionTypes()}
                            key="transactionType"
                            name="transactionType"
                            idField="transactionType"
                            renderAs="combo"
                            mode="edit"
                            isRequired
                            idActivity={`${idForm}.send`}
                            idForm={idForm}
                            searchable
                            onChange={this.handleOnChangeTransationType}
                            lang={currentLang}
                        />
                        <Field
                            component={FormFieldsComponents.Selector}
                            optionList={this.getStateTypes()}
                            key="state"
                            name="state"
                            idField="state"
                            renderAs="combo"
                            mode="edit"
                            isRequired
                            idActivity={`${idForm}.send`}
                            idForm={idForm}
                            lang={currentLang}
                        />

                        <Field
                            idField="dateFrom"
                            component={DateField}
                            hidePlaceholder
                            idForm="forms.transactions.list"
                            isClearable
                            name="dateFrom"
                            selectsStart
                            showMonthYearDropdown
                            idActivity={`${idForm}.send`}
                            availableAllDayofWeek
                            defaultValue={defaultDateFromList().listTransaction}
                            minDate={defaultDateFromList().transactions}
                            maxDate={moment(values.dateTo) ?? moment()}
                        />

                        <Field
                            idField="dateTo"
                            component={DateField}
                            hidePlaceholder
                            idForm="forms.transactions.list"
                            isClearable
                            name="dateTo"
                            selectsEnd
                            showMonthYearDropdown
                            idActivity={`${idForm}.send`}
                            availableAllDayofWeek
                            minDate={moment(values.dateFrom) ?? defaultDateFromList().transactions}
                        />
                    </div>
                    <div className="filters-row">
                        <Field
                            component={TextFieldSearch}
                            idForm="forms.transactions.list"
                            idField="keyWord"
                            key="keyWord"
                            name="keyWord"
                            type="text"
                            handleOnChange={this.handleOnChangeKeyWord}
                        />

                        <Field
                            component={AmountField}
                            idActivity={`${idForm}.send`}
                            hideCurrency
                            idForm="accounts.movements.filters.amount"
                            name="minAmount"
                        />

                        <Field
                            component={AmountField}
                            idActivity={`${idForm}.send`}
                            hideCurrency
                            idForm="accounts.movements.filters.amount"
                            name="maxAmount"
                        />

                        <Button
                            block={isMobile}
                            bsStyle="primary"
                            label="product.filters.filter"
                            loading={fetching}
                            type="submit"
                        />

                        <Button
                            block={isMobile}
                            bsStyle="outline"
                            className="btn-clear-filters"
                            label="product.filters.clearFilters"
                            image="images/ui-icons/ui-clear-filters.svg"
                            loading={fetching}
                            onClick={this.handleClearFilters}
                        />
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filtersSaved: transactionsSelectors.getFilters(state),
    transactionTypes: sessionSelectors.getTransactionTypes(state),
    currentLang: i18nSelectors.getLang(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            dateFrom: props.filtersSaved ? props.filtersSaved.dateFrom : null,
            dateTo: props.filtersSaved ? props.filtersSaved.dateTo : null,
            minAmount: {
                amount: props.filtersSaved ? props.filtersSaved.minAmount : null,
            },
            maxAmount: {
                amount: props.filtersSaved ? props.filtersSaved.maxAmount : null,
            },
            reference: props.filtersSaved && props.filtersSaved.reference ? props.filtersSaved.reference : "",
            state: props.filtersSaved && props.filtersSaved.state ? props.filtersSaved.state : ["Todos"],
            pageNumber: props.filtersSaved ? props.filtersSaved.pageNumber : 1,
            transactionType:
                props.filtersSaved && props.filtersSaved.transactionType ? props.filtersSaved.transactionType : ["all"],

            keyWord: props.filtersSaved?.keyWord || "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get(`forms.transactions.list.dateFrom.range.error`))
                              .required(i18n.get("forms.transactions.list.dateFrom.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("forms.transactions.list.dateFrom.empty.error")),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get(`forms.transactions.list.dateTo.range.error`))
                              .required(i18n.get("forms.transactions.list.dateTo.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("forms.transactions.list.dateTo.empty.error")),
                    minAmount: Yup.object().shape({
                        // eslint-disable-next-line no-nested-ternary
                        amount: Number.isInteger(values.minAmount.amount)
                            ? Number.isInteger(values.maxAmount.amount)
                                ? Yup.number().max(
                                      values.maxAmount.amount,
                                      i18n.get(`forms.transactions.list.minAmount.range.error`),
                                  )
                                : Yup.number()
                            : Yup.string().nullable(),
                    }),
                    maxAmount: Yup.object().shape({
                        // eslint-disable-next-line no-nested-ternary
                        amount: Number.isInteger(values.maxAmount.amount)
                            ? Number.isInteger(values.minAmount.amount)
                                ? Yup.number().min(
                                      values.minAmount.amount,
                                      i18n.get(`forms.transactions.list.maxAmount.range.error`),
                                  )
                                : Yup.number()
                            : Yup.string().nullable(),
                    }),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const {
                dispatch,
                isDesktop,
                onlyPendings,
                resetSelectedFilter,
                handleSetTransactionsSelected,
            } = formikBag.props;
            const newFilters = { ...values, minAmount: values.minAmount.amount, maxAmount: values.maxAmount.amount };
            const isDraft = values.state[0] === "DRAFT";

            if (isDesktop) {
                dispatch(
                    transactionsActions.loadListRequest(
                        newFilters,
                        isDraft ? false : onlyPendings || false,
                        false,
                        false,
                    ),
                );
                if (resetSelectedFilter) {
                    resetSelectedFilter();
                }
            } else {
                dispatch(
                    transactionsActions.loadListRequest(
                        newFilters,
                        isDraft ? false : onlyPendings || false,
                        false,
                        false,
                    ),
                );
                dispatch(filterActions.toggleShowFilters());
            }
            if (handleSetTransactionsSelected) {
                handleSetTransactionsSelected([]);
            }
        },
    }),
)(resizableRoute(HiddenFilters));
