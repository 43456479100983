import React from "react";
import { Field } from "formik";
import { func, shape, bool, string } from "prop-types";
import * as config from "util/config";
import * as i18nUtils from "util/i18n";
import * as stringUtils from "util/string";
import * as FormFieldsComponents from "../../../_components/_fields/Index";
import I18n from "../../../../_components/I18n";
import CustomerEcheqData from "../_components/CustomerEcheqData";

const EmitEcheqCommonFields = ({
    mode,
    authorizedAccountsOptions,
    data,
    ID_FORM,
    searchSuccessAction,
    bankedCustomerInfo,
    handleEmissionType,
    emissionTypeSelected,
    genericProps,
    handleChangeAccount,
    isMobile,
    previewData,
    isPersonalizedTicket,
    hideHR,
    disableComponents,
    otherFieldsSelected,
}) => {
    let echeqs = [];
    if (mode === "preview") {
        echeqs = previewData?.echeqs || data?.echeqs || [];
    } else if (mode === "view") {
        echeqs = data?.echeqs || [];
    }

    const echeq = echeqs?.[0] || {};
    const dataBankedCustomerInfo = data?.bankedCustomerInfo || previewData?.bankedCustomerInfo || null;
    const finalBankedCustomerInfo =
        Object.keys(bankedCustomerInfo).length > 0 ? bankedCustomerInfo : dataBankedCustomerInfo;

    const emissionTypes = config.get("echeqs.emissionTypes.options", "single|multiple").split("|");
    const listEmissionTypes = emissionTypes.map((value) => ({
        id: value,
        label: i18nUtils.get(`forms.echeqs.emitEcheq.emissionType.${value}`),
    }));

    return (
        <>
            <Field
                {...genericProps}
                name="originAccount"
                idField="originAccount"
                key="originAccount"
                data={authorizedAccountsOptions}
                value={data.originAccount || otherFieldsSelected.originAccount || ""}
                handleChange={(e) => handleChangeAccount(e)}
                component={FormFieldsComponents.ProductselectorCustom}
                disabled={disableComponents}
            />

            {!hideHR && (
                <>
                    <hr
                        className={`${mode === "edit" ? "ui-mt-0 ui-mb-4" : ""} ${
                            mode === "preview" ? "ui-mt-7 ui-mb-4" : ""
                        }`}
                    />

                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.beneficiary.label`} componentProps={{ className: "data-label" }} />
                    </div>
                </>
            )}

            {(!finalBankedCustomerInfo || Object.keys(finalBankedCustomerInfo)?.length === 0) &&
            (!echeqs || echeqs?.length === 0) ? (
                <>
                    <CustomerEcheqData
                        idActivity="echeqs.emitEcheq.send"
                        genericProps={genericProps}
                        isMobile={isMobile}
                        searchSuccessAction={searchSuccessAction}
                        data={data}
                        buttonLabel={`${ID_FORM}.document.search`}
                    />
                    <hr className="ui-mt-0 ui-mb-7" />
                </>
            ) : (
                <>
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.documentNumber.label`} componentProps={{ className: "data-label" }} />
                        <span>
                            {`${finalBankedCustomerInfo?.documento_tipo ||
                                echeq?.beneficiario_documento_tipo} ${stringUtils.formatCuit(
                                finalBankedCustomerInfo?.documento !== ""
                                    ? finalBankedCustomerInfo.documento
                                    : echeq?.beneficiario_documento,
                            )}`}
                        </span>
                    </div>
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.beneficiary.label`} componentProps={{ className: "data-label" }} />
                        <span>{finalBankedCustomerInfo?.nombre || echeq?.beneficiario_nombre}</span>
                    </div>
                    {!hideHR && <hr className={`${mode !== "view" ? "ui-mt-7 ui-mb-7" : ""}`} />}
                </>
            )}

            {mode !== "edit" && (emissionTypeSelected[0] === "single" || isPersonalizedTicket) && (
                <>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="echeqId"
                        name="echeqId"
                        idField="echeqId"
                        value={echeqs?.[0]?.cheque_id}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="echeqNumber"
                        name="echeqNumber"
                        idField="echeqNumber"
                        value={echeqs?.[0]?.cheque_numero}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="checkbookNumber"
                        name="checkbookNumber"
                        idField="checkbookNumber"
                        value={echeqs?.[0]?.numero_chequera}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="CMC7"
                        name="CMC7"
                        idField="CMC7"
                        value={echeqs?.[0]?.cmc7}
                    />
                </>
            )}

            <Field
                {...genericProps}
                component={FormFieldsComponents.Selector}
                key="emissionType"
                name="emissionType"
                idField="emissionType"
                renderAs="radio"
                onChange={(e) => handleEmissionType([e])}
                optionList={listEmissionTypes}
                inLineControl={!isMobile}
                value={mode !== "edit" && data?.emissionType ? data.emissionType : emissionTypeSelected}
                disabled={disableComponents}
            />
        </>
    );
};
EmitEcheqCommonFields.propTypes = {
    mode: string.isRequired,
    authorizedAccountsOptions: shape([]).isRequired,
    data: shape({}).isRequired,
    previewData: shape({}).isRequired,
    ID_FORM: string.isRequired,
    searchSuccessAction: func.isRequired,
    bankedCustomerInfo: shape({}).isRequired,
    handleEmissionType: func.isRequired,
    emissionTypeSelected: string.isRequired,
    genericProps: shape({}).isRequired,
    handleChangeAccount: func.isRequired,
    isMobile: bool.isRequired,
    isPersonalizedTicket: bool,
    hideHR: bool,
    disableComponents: bool.isRequired,
    otherFieldsSelected: shape({}).isRequired,
};

EmitEcheqCommonFields.defaultProps = {
    isPersonalizedTicket: false,
    hideHR: false,
};

export default EmitEcheqCommonFields;
