import React, { useState } from "react";

import SimpleModal from "components/SimpleModal/SimpleModal";
import { bool, func, string } from "prop-types";
import { push } from "react-router-redux";

const SignMultipleTransactionsFinishModal = ({ isDisplayed, idTransactionToRedirect, dispatch }) => {
    const [showModal, setShowModal] = useState(isDisplayed);

    const handleCloseModal = () => {
        setShowModal(false);
        window.location.reload();
        // También podemos hacer un dispatch y recargar el listado directamente para no tener que recargar la página completa
    };

    const handleButtonAction = () => {
        setShowModal(false);
        dispatch(push(`/transaction/${idTransactionToRedirect}`));
    };

    return (
        <SimpleModal
            isDisplayed={showModal}
            modalIcon="/images/success.svg"
            modalBigTitle="forms.signMultipleTransaction.confirmationModal.title"
            buttonAction={handleButtonAction}
            closeModal={handleCloseModal}
            buttonActionLabel="forms.signMultipleTransaction.confirmationModal.button.label"
            noCancelButton
        />
    );
};

SignMultipleTransactionsFinishModal.propTypes = {
    dispatch: func.isRequired,
    isDisplayed: bool.isRequired,
    idTransactionToRedirect: string.isRequired,
};

SignMultipleTransactionsFinishModal.defaultProps = {};

export default SignMultipleTransactionsFinishModal;
