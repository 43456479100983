import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import { bool, string, func, shape } from "prop-types";
import Yup from "yup";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as echeqsActions, selectors as echeqsSelectors } from "reducers/echeqs";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { selectors as sessionSelectors } from "reducers/session";

import * as config from "util/config";
import moment from "moment";
import * as i18n from "util/i18n";
import defaultDateFromList from "util/defaultDateFromList";
import * as maskUtils from "util/mask";
import { clearFiltersAndSubmit } from "util/form";

import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Date from "pages/_components/fields/DateField";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";

class ECheqFilters extends Component {
    static propTypes = {
        fetching: bool,
        dateFrom: string,
        dateTo: string,
        filters: shape().isRequired,
        dispatch: func.isRequired,
        isMobile: bool,
        setFieldValue: func.isRequired,
        setFieldError: func.isRequired,
        values: shape({}).isRequired,
        showMoreFilters: bool.isRequired,
        setValues: func.isRequired,
        currentLang: string.isRequired,
        submitForm: func.isRequired,
    };

    static defaultProps = {
        fetching: false,
        dateFrom: null,
        dateTo: null,
        isMobile: false,
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            selectedQuickFilter: null,
        };
    }

    componentDidMount() {
        this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    componentDidUpdate(prevProps) {
        const { echeqType } = this.props.filters;
        const prevEcheqType = prevProps.filters.echeqType;

        if (prevEcheqType !== echeqType) {
            this.handleClearFilters(true);
        }
    }

    getEcheqStatusOptions = () => {
        const {
            filters: { echeqType },
        } = this.props;
        let states = config.get("echeq.status.options")?.split("|");
        if (echeqType === "sent") {
            states = states.filter((state) => state !== "AVAL-PENDIENTE");
        }
        return states.map((state) => ({
            id: state,
            label: i18n.get(`forms.echeqs.list.defaultFilters.status.${state}`),
        }));
    };

    handleClearFilters = (isTabToggle) => {
        const { submitForm, setValues } = this.props;

        clearFiltersAndSubmit(
            () => {
                setValues({
                    cuit: "",
                    echeqNumber: "",
                    status: ["TODOS"],
                    paymentDateFrom: defaultDateFromList().echeqs.paymentDateFrom,
                    paymentDateTo: defaultDateFromList().echeqs.paymentDateTo,
                    echeqId: "",
                    groupId: "",
                    emissionDateFrom: null,
                    emissionDateTo: null,
                    orderDirection: "ascendent",
                });
                this.setState({
                    selectedQuickFilter: null,
                });
            },
            !isTabToggle ? submitForm : () => {},
        );
    };

    handleQuickFilters = (quickFilter) => {
        const {
            dispatch,
            values: { status },
            filters: { echeqType },
            setValues,
        } = this.props;

        const statusString = Array.isArray(status) ? status[0] : status;

        const newFilters = {
            echeqType,
            pageNumber: 1,
            quickFilter,
            orderDirection: "ascendent",
            status: statusString,
        };

        this.setState({
            selectedQuickFilter: quickFilter,
        });

        setValues({
            quickFilter,
            cuit: "",
            echeqNumber: "",
            status: ["TODOS"],
            paymentDateFrom: null,
            paymentDateTo: null,
            echeqId: "",
            groupId: "",
            emissionDateFrom: null,
            emissionDateTo: null,
        });

        dispatch(echeqsActions.loadListRequest(newFilters));
    };

    getQuickFilters = () => {
        const { echeqType } = this.props.filters;
        const { selectedQuickFilter } = this.state;

        if (echeqType) {
            const quickFilters = config.get(`echeq.quickFilters.${echeqType}`)?.split("|");
            const quickFiltersButtons = quickFilters.map((filter) => (
                <Button
                    className={classNames(`btn-chip ${filter}`, {
                        "is-active": selectedQuickFilter === filter,
                    })}
                    bsStyle="outline"
                    key={filter}
                    type="button"
                    label={`forms.echeqs.list.${echeqType}.quickFilter.${filter}`}
                    onClick={() => this.handleQuickFilters(filter)}
                    image={selectedQuickFilter === filter ? "ui-icons/ui-echeq-active-arrow.svg" : ""}
                />
            ));

            return quickFiltersButtons;
        }
        return null;
    };

    onClickSubmit = (setFieldValue) => {
        setFieldValue("quickFilter", null);
        this.setState({
            selectedQuickFilter: null,
        });
    };

    showHideMoreFilters = (e) => {
        const { dispatch } = this.props;
        e.preventDefault();

        dispatch(filterActions.toggleShowFilters());
    };

    renderFiltersBtnActions = () => {
        const { isMobile, fetching, setFieldValue } = this.props;

        return (
            <>
                <Button
                    block={isMobile}
                    bsStyle="primary"
                    label="product.filters.filter"
                    loading={fetching}
                    type="submit"
                    onClick={() => this.onClickSubmit(setFieldValue)}
                />
                <Button
                    block={isMobile}
                    bsStyle="outline"
                    image="images/ui-icons/ui-clear-filters.svg"
                    className="btn-clear-filters"
                    label="product.filters.clearFilters"
                    loading={fetching}
                    onClick={() => this.handleClearFilters(false)}
                />
            </>
        );
    };

    handleChange = (event) => {
        const value = event;
        const filteredValue = value.replace(/\D/g, "");
        const paddedValue = filteredValue.padStart(8, "0");
        const newValue = paddedValue.substring(paddedValue.length - 8);
        const valueWasDeleted = filteredValue === "0000000";
        this.props.setFieldValue("echeqNumber", valueWasDeleted ? "" : newValue);
    };

    render() {
        const { dateTo, dateFrom, isMobile, setFieldValue, setFieldError, showMoreFilters, currentLang } = this.props;
        const defaultFiltersLabel = "echeqs.list.defaultFilters";

        return (
            <div ref={this.myRef} className="filters-container echeq">
                <div className="echeqs-quickFilters ui-mb-7">{this.getQuickFilters()}</div>
                <Form className="form-content">
                    <div
                        className={classNames("filters-row", {
                            "show-more": showMoreFilters,
                        })}>
                        <Field
                            mode="edit"
                            component={FormFieldsComponents.Text}
                            idField="cuit"
                            name="cuit"
                            idActivity={`${defaultFiltersLabel}.send`}
                            idForm={`forms.${defaultFiltersLabel}`}
                            maxLength={11}
                            isRequired
                            validationRegularExpresion="^[0-9\-]*$"
                            lang={currentLang}
                            mask={maskUtils.cuitMask}
                        />
                        <Field
                            mode="edit"
                            component={FormFieldsComponents.Text}
                            name="echeqNumber"
                            idField="echeqNumber"
                            idActivity={`${defaultFiltersLabel}.send`}
                            idForm={`forms.${defaultFiltersLabel}`}
                            maxLength={9}
                            isRequired
                            validationRegularExpresion="^[0-9]*$"
                            lang={currentLang}
                            onChange={this.handleChange}
                        />
                        <Field
                            component={FormFieldsComponents.Selector}
                            optionList={this.getEcheqStatusOptions()}
                            key="status"
                            name="status"
                            idField="status"
                            idForm={`forms.${defaultFiltersLabel}`}
                            renderAs="combo"
                            idActivity={`${defaultFiltersLabel}.send`}
                            mode="edit"
                            isRequired
                            lang={currentLang}
                        />
                        <Field
                            idField="paymentDateFrom"
                            component={Date}
                            endDate={dateTo}
                            hidePlaceholder
                            idForm={`forms.${defaultFiltersLabel}`}
                            isClearable
                            name="paymentDateFrom"
                            selectsStart
                            showMonthYearDropdown
                            startDate={dateFrom}
                            minDate={moment().add(-365, "days")}
                            onChange={(e) => {
                                setFieldValue("paymentDateFrom", e);
                                setFieldError("paymentDateFrom", null);
                            }}
                            availableAllDayofWeek
                        />
                        <Field
                            idField="paymentDateTo"
                            component={Date}
                            hidePlaceholder
                            idForm={`forms.${defaultFiltersLabel}`}
                            isClearable
                            name="paymentDateTo"
                            selectsEnd
                            showMonthYearDropdown
                            startDate={dateFrom}
                            maxDate={defaultDateFromList().echeqs.paymentDateTo}
                            onChange={(e) => {
                                setFieldValue("paymentDateTo", e);
                                setFieldError("paymentDateTo", null);
                            }}
                            availableAllDayofWeek
                        />
                        {!showMoreFilters && this.renderFiltersBtnActions()}
                    </div>
                    {showMoreFilters && (
                        <div
                            className={classNames("filters-row", {
                                "show-more": showMoreFilters,
                            })}>
                            <Field
                                mode="edit"
                                component={FormFieldsComponents.Text}
                                name="echeqId"
                                idField="echeqId"
                                idForm="forms.echeqs.list.filters.more"
                                idActivity="echeqs.list.filters.more.send"
                                maxLength={150}
                                isRequired
                                validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                                lang={currentLang}
                            />
                            <Field
                                mode="edit"
                                component={FormFieldsComponents.Text}
                                name="groupId"
                                idField="groupId"
                                idForm="forms.echeqs.list.filters.more"
                                idActivity="echeqs.list.filters.more.send"
                                maxLength={150}
                                isRequired
                                validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                                lang={currentLang}
                            />
                            <Field
                                idField="emissionDateFrom"
                                component={Date}
                                endDate={dateTo}
                                hidePlaceholder
                                idForm="forms.echeqs.list.filters.more"
                                isClearable
                                name="emissionDateFrom"
                                selectsStart
                                showMonthYearDropdown
                                startDate={dateFrom}
                                minDate={moment().add(-395, "days")}
                                availableAllDayofWeek
                            />
                            <Field
                                idField="emissionDateTo"
                                component={Date}
                                hidePlaceholder
                                idForm="forms.echeqs.list.filters.more"
                                isClearable
                                name="emissionDateTo"
                                selectsEnd
                                showMonthYearDropdown
                                minDate={moment().add(-395, "days")}
                                availableAllDayofWeek
                            />
                            {this.renderFiltersBtnActions()}
                        </div>
                    )}
                </Form>
                <Button
                    onClick={(e) => this.showHideMoreFilters(e)}
                    bsStyle="filter"
                    className={`btn-link ${!isMobile ? "ui-mt-7 ui-mb-0" : "ui-mt-0 ui-mb-8"}`}
                    block={isMobile}
                    image={`images/${!showMoreFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                    label={`forms.echeqs.list.filters.${showMoreFilters ? "seeLessFilters" : "seeMoreFilters"}`}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    currentLang: i18nSelectors.getLang(state),
    filters: echeqsSelectors.getFilters(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    fetching: echeqsSelectors.getFetching(state),
    showMoreFilters: filterSelectors.getShowFilters(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            cuit: props.filters.cuit ? props.filters.cuit : "",
            echeqNumber: props.filters.echeqNumber ? props.filters.echeqNumber : "",
            status: props.filters.status ? [props.filters.status] : ["TODOS"],
            paymentDateFrom: props.filters.paymentDateFrom ? props.filters.paymentDateFrom : null,
            paymentDateTo: props.filters.paymentDateTo ? props.filters.paymentDateTo : null,
            echeqId: props.filters.echeqId ? props.filters.echeqId : "",
            groupId: props.filters.groupId ? props.filters.groupId : "",
            emissionDateFrom: props.filters.emissionDateFrom ? props.filters.emissionDateFrom : null,
            emissionDateTo: props.filters.emissionDateTo ? props.filters.emissionDateTo : null,
            quickFilter: props.filters.quickFilter ? props.filters.quickFilter : null,
            orderDirection: props.filters.orderDirection ? props.filters.orderDirection : "ascendent",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    paymentDateFrom: values.paymentDateTo
                        ? Yup.date()
                              .nullable()
                              .max(
                                  values.paymentDateTo,
                                  i18n.get("forms.echeqs.list.defaultFilters.paymentDateFrom.error"),
                              )
                              .required(i18n.get("forms.echeqs.list.defaultFilters.paymentDateFrom.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("forms.echeqs.list.defaultFilters.paymentDateFrom.empty.error")),
                    paymentDateTo: values.paymentDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(
                                  values.paymentDateFrom,
                                  i18n.get("forms.echeqs.list.defaultFilters.paymentDateTo.error"),
                              )
                              .required(i18n.get("forms.echeqs.list.defaultFilters.paymentDateTo.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("forms.echeqs.list.defaultFilters.paymentDateTo.empty.error")),
                    emissionDateFrom: values.emissionDateTo
                        ? Yup.date()
                              .nullable()
                              .max(
                                  values.emissionDateTo,
                                  i18n.get("forms.echeqs.list.filters.more.emissionDateFrom.error"),
                              )
                        : Yup.date().nullable(),
                    emissionDateTo: values.emissionDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(
                                  values.emissionDateFrom,
                                  i18n.get("forms.echeqs.list.filters.more.emissionDateTo.error"),
                              )
                        : Yup.date().nullable(),
                    cuit: Yup.string()
                        .max(13, i18n.get("forms.echeqs.list.filters.echeqCuit.character.error"))
                        .min(13, i18n.get("forms.echeqs.list.filters.echeqCuit.character.error")),
                    echeqNumber: Yup.string().max(8, i18n.get("forms.echeqs.list.filters.echeqCuit.character.error")),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const { filters, dispatch } = formikBag.props;
            const { echeqType } = filters;
            const { status } = values;
            const statusString = Array.isArray(status) ? status[0] : status;
            const cleanCuit = values.cuit ? values.cuit.replace(/\D/g, "") : "";
            const newFilters = { ...values, echeqType, status: statusString, cuit: cleanCuit };
            dispatch(echeqsActions.loadListRequest(newFilters, formikBag));
        },
    }),
)(ECheqFilters);
