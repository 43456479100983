import React from "react";
import { func, node, bool, string } from "prop-types";

import ChevromRight from "pages/_components/listItem/ChevromRight";
import Image from "pages/_components/Image";

import "./_genericItem.scss";
import classNames from "classnames";

const GenericItem = ({
    onClick,
    children,
    arrow,
    className,
    role,
    options,
    customOption,
    customArrowIcon,
    hoverBehavior,
    selectCheckbox,
}) => (
    <>
        <div
            role={role}
            className={classNames(`generic-item-container ${className}`, {
                "no-hover": !hoverBehavior,
                "with-checkbox": selectCheckbox,
            })}>
            {selectCheckbox && <div className="checkbox-column">{selectCheckbox}</div>}
            <div onClick={onClick}>{children}</div>
            {arrow || options !== null || customOption !== null ? (
                <div className="movement-icon">
                    {options !== null && options}
                    {customOption !== null && (
                        <div className="generic-item--customOption" onClick={onClick}>
                            {" "}
                            {customOption}{" "}
                        </div>
                    )}
                    {arrow && customArrowIcon && (
                        <div className="generic-item--customArrow">
                            <Image src={customArrowIcon} className="svg-icon" alt="" />
                        </div>
                    )}
                    {arrow && !customArrowIcon && <ChevromRight onClick={onClick} />}
                </div>
            ) : (
                <></>
            )}
        </div>
    </>
);

GenericItem.propTypes = {
    onClick: func,
    children: node.isRequired,
    arrow: bool,
    className: string,
    role: string,
    options: node,
    customOption: node,
    hoverBehavior: bool,
    customArrowIcon: string,
    selectCheckbox: node,
};

GenericItem.defaultProps = {
    onClick: null,
    arrow: false,
    className: "",
    role: null,
    options: null,
    customOption: null,
    hoverBehavior: true,
    customArrowIcon: null,
    selectCheckbox: null,
};

export default GenericItem;
