/* eslint-disable */
import React, { Component, Fragment } from "react";
import Select from "react-select";
import classNames from "classnames";
import { arrayOf, bool, object, string, number, func } from "prop-types";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import withFocus from "pages/_components/withFocus";

import * as i18n from "util/i18n";

class DocumentField extends Component {
    static propTypes = {
        clearable: bool,
        countries: arrayOf(string),
        disableDocumentTypeSelect: bool,
        disableSelect: bool,
        documentTypes: arrayOf(object),
        hideDocumentCountryLabel: bool,
        hideDocumentPlaceholder: bool,
        searchable: bool,
        pattern: string,
        maxLength: number,
        onFocus: func,
    };

    static defaultProps = {
        clearable: true,
        disableSelect: false,
        disableDocumentTypeSelect: false,
        hideDocumentCountryLabel: false,
        hideDocumentPlaceholder: false,
        searchable: true,
        countries: ["AR"],
        documentTypes: [],
        defaultCountry: "AR",
        inputRef: React.createRef(),
        pattern: "^[0-9]*$",
        maxLength: 11,
        onFocus: null,
    };

    state = {
        focusSelect: false,
        focusInput: false,
    };

    componentDidUpdate(prevProps) {
        const { form, field, defaultDocumentType, defaultCountry } = this.props;
        if (!prevProps.defaultDocumentType && defaultDocumentType && defaultCountry) {
            form.setFieldValue(field.name, {
                ...field.value,
                type: defaultDocumentType,
                country: defaultCountry,
            });
        }
    }

    handleCountryChange = (selectedOption) => {
        const {
            data: { documentTypes },
            field,
            form,
        } = this.props;

        form.setFieldValue(field.name, {
            ...field.value,
            country: selectedOption ? selectedOption.value : null,
            type: selectedOption
                ? documentTypes.filter((documentType) => documentType.id_country_code === selectedOption.value)[0]
                    .id_document_type
                : null,
        });
    };

    handleDocumentChange = (event) => {
        const { field, form, handleOnChange, maxLength } = this.props;
        if (event.target.validity.valid) {
            if (event.target.value.length <= maxLength) {
                if (handleOnChange) {
                    handleOnChange(event);
                }
            }
            form.setFieldValue(field.name, {
                ...field.value,
                document: event.target.value,
            });
        }
    };

    handleTypeChange = (selectedOption) => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, {
            ...field.value,
            type: selectedOption ? selectedOption.value : null,
        });
    };
    onClickHandle = (event) => {
        const { onClick } = this.props
        if (onClick) {
            onClick(event)
        }
    }

    toggleFocusSelect = (e) => {
        const { onFocus } = this.props;

        if(onFocus) {
            onFocus(e);
        }
        this.setState({
            focusInput : false,
            focusSelect : !this.state.focusSelect,
        })
    }

    toggleFocusInput = (e) => {
        const { onFocus } = this.props;

        if(onFocus) {
            onFocus(e);
        }
        this.setState({
            focusSelect : false,
            focusInput : !this.state.focusInput,
        })
    }

    render() {
        const {
            clearable,
            data: { documentTypes },
            defaultDocumentType,
            disableDocumentTypeSelect,
            field,
            form: { touched, errors },
            hideDocumentPlaceholder,
            idForm,
            inputRef,
            pattern,
            maxLength,
        } = this.props;

        const {focusInput,focusSelect}= this.state;
        const hasError = {
            document:
                touched[field.name] && errors[field.name]
                    ? touched[field.name].document && errors[field.name].document
                    : false,
            documentTypes:
                touched[field.name] && errors[field.name] ? touched[field.name].type && errors[field.name].type : false,
        };

        return (
            <Fragment>
                <div
                    className={classNames("form-group", "form-group--composite", {
                        "has-error": hasError.documentType || hasError.document,
                    })}>
                    <FieldLabel labelKey={`${idForm}.${field.name}.label`} />
                    <div className={classNames("input-group",{
                        "has-focus": focusSelect,
                    })}>
                        <Select
                            placeholder=""
                            noResultsText=""
                            clearable={clearable}
                            className=""
                            disabled={disableDocumentTypeSelect}
                            onChange={this.handleTypeChange}
                            onBlur={()=>this.toggleFocusSelect()}
                            onFocus={(e)=>this.toggleFocusSelect(e)}
                            options={
                                documentTypes
                                    ? documentTypes
                                        .map((docType) => ({
                                            value: docType.id,
                                            label: i18n.get(`documentType.label.${docType.id}`),
                                        }))
                                    : []
                            }
                            searchable={false}
                            value={field.value.type || defaultDocumentType}
                            optionClassName="needsclick"
                        />
                    </div>
                </div>
                <div
                    className={classNames("form-group", "form-group--composite", {
                    "has-error": hasError.document,
                })}>
                    <FieldLabel labelKey={`${idForm}.${field.name}Number.label`} />
                    <div className={classNames("input-group",{
                    "has-focus": focusInput,})}>
                        <input
                            className="form-control"
                            placeholder={hideDocumentPlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                            ref={inputRef}
                            {...field}
                            pattern={pattern}
                            onBlur={(e)=>this.toggleFocusInput(e)}
                            onFocus={this.toggleFocusInput}
                            maxLength={maxLength}
                            onChange={this.handleDocumentChange}
                            value={field.value.document}
                            onClick={this.onClickHandle}
                        />
                    </div>
                    {hasError.documentTypes && <FieldError error={errors[field.name].type} />}
                    {hasError.document && <FieldError error={errors[field.name].document} />}
                </div>
            </Fragment>
        );
    }
}

export default DocumentField;
