import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    isFirstFetching: true,
    fetching: false,
    fetchingExport: false,
    isFetchingDownloadFiles: false,
    nameFileCharged: "",
    idFileCharged: "",
    linesWithErrors: [],
    nameFileError: "",
    fileToSave: "",
    totalLines: "",
    totalAmount: "",
    date: "",
    debitAccount: "",
    isUploading: false,
    files: [],
    pageNumber: 1,
    lastPage: true,
    filters: {
        dateFrom: null,
        dateTo: null,
        fileType: "",
        processingStatus: "",
        signatureStatus: "",
        toggleState: "pending",
    },
    filtersQuery: {
        dateFrom: null,
        dateTo: null,
    },
    resetFiltersQueryOnMount: true,
    filtersErrors: {},
    linesDetail: [],
    nameFileDetail: "",
    headInfo: {},
    comeToList: false,
    goToPreviewForm: false,
    fileType: "",
    typeFileDetail: "",
    answerFiles: [],
    comeProcessed: false,
    availableFilesToUse: [],
    idTransactionTicket: "",
    goToTicket: false,
    fileStatus: "",
    fileBase64: "",
    showDownloadModal: false,
    showDownloadErrorModal: false,
    comeFromProcessed: false,
    idDownloadFileSent: "",
    uploadType: "",
    fileUploadError: null,
    errors: [],
    amountForCap: "",
    fetchingDownload: false,
    vouchersToDownload: [],
};

export const types = {
    DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_REQUEST:
        "paymentToSuppliers/DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_REQUEST",
    DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_FAILURE:
        "paymentToSuppliers/DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_FAILURE",
    DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_SUCCESS:
        "paymentToSuppliers/DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_SUCCESS",
    SAVE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST: "paymentToSuppliers/SAVE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST",
    SAVE_PAYMENT_TO_SUPPLIERS_FILE_BASE: "paymentToSuppliers/SAVE_PAYMENT_TO_SUPPLIERS_FILE_BASE",
    GET_PAYMENT_TO_SUPPLIERS_FILE_BASE: "paymentToSuppliers/GET_PAYMENT_TO_SUPPLIERS_FILE_BASE",
    GET_PAYMENT_TO_SUPPLIERS_PROCCESED_FILE: "paymentToSuppliers/GET_PAYMENT_TO_SUPPLIERS_PROCCESED_FILE",
    SAVE_PAYMENT_TO_SUPPLIERS_FILE_FAILURE: "paymentToSuppliers/SAVE_PAYMENT_TO_SUPPLIERS_FILE_FAILURE",
    SAVE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS: "paymentToSuppliers/SAVE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS",
    DELETE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST: "paymentToSuppliers/DELETE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST",
    DELETE_PAYMENT_TO_SUPPLIERS_FILE_FAILURE: "paymentToSuppliers/DELETE_PAYMENT_TO_SUPPLIERS_FILE_FAILURE",
    DELETE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS: "paymentToSuppliers/DELETE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS",
    PAYMENT_FILES_REQUESTED: "paymentToSuppliers/PAYMENT_FILES_REQUESTED",
    PAYMENT_FILES_RECEIVED: "paymentToSuppliers/PAYMENT_FILES_RECEIVED",
    PAYMENT_FILES_FAILED: "paymentToSuppliers/PAYMENT_FILES_FAILED",
    RESET_DATA: "paymentToSuppliers/RESET_DATA",
    RESET_UPLOADED_FILE_DATA: "paymentToSuppliers/RESET_UPLOADED_FILE_DATA",
    SET_AVAILABLE_FILES_TO_USE: "paymentToSuppliers/SET_AVAILABLE_FILES_TO_USE",
    SET_DATA: "paymentToSuppliers/SET_DATA",
    SET_PAYMENT_FILES_FILTERS: "paymentToSuppliers/SET_PAYMENT_FILES_FILTERS",
    REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_REQUEST: "paymentToSuppliers/REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_REQUEST",
    REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_SUCCESS: "paymentToSuppliers/REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_SUCCESS",
    REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_FAILURE: "paymentToSuppliers/REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_FAILURE",

    REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_REQUEST:
        "paymentToSuppliers/REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_REQUEST",
    REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_SUCCESS:
        "paymentToSuppliers/REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_SUCCESS",

    COME_TO_LIST: "paymentToSuppliers/COME_TO_LIST",
    GO_TO_PREVIEW_FORM: "paymentToSuppliers/GO_TO_PREVIEW_FORM",
    COME_TO_TICKET: "paymentToSuppliers/COME_TO_TICKET",
    GO_TO_TICKET: "paymentToSuppliers/GO_TO_TICKET",

    PAYMENT_TO_SUPPLIERS_ANSWER_FILES_REQUEST: "paymentToSuppliers/PAYMENT_TO_SUPPLIERS_ANSWER_FILES_REQUEST",
    PAYMENT_TO_SUPPLIERS_ANSWER_FILES_SUCCESS: "paymentToSuppliers/PAYMENT_TO_SUPPLIERS_ANSWER_FILES_SUCCESS",
    PAYMENT_TO_SUPPLIERS_ANSWER_FILES_FAILURE: "paymentToSuppliers/PAYMENT_TO_SUPPLIERS_ANSWER_FILES_FAILURE",

    REQUEST_PAYMENT_TO_SUPPLIERS_DOWNLOAD_FILE_FROM_SERVICE:
        "paymentToSuppliers/REQUEST_PAYMENT_TO_SUPPLIERS_DOWNLOAD_FILE_FROM_SERVICE",
    REQUEST_PAYMENT_TO_SUPPLIERS_SEE_DETAIL_FROM_SERVICE:
        "paymentToSuppliers/REQUEST_PAYMENT_TO_SUPPLIERS_SEE_DETAIL_FROM_SERVICE",
    SET_FILE_STATUS: "paymentToSuppliers/SET_FILE_STATUS",
    SET_FETCHING_SUPPLIER: "paymentToSuppliers/SET_FETCHING_SUPPLIER",
    SET_PAGE_NUMBER: "paymentToSuppliers/SET_PAGE_NUMBER",
    SET_DOWNLOAD_MODAL: "paymentToSuppliers/SET_DOWNLOAD_MODAL",
    SET_DOWNLOAD_ERROR_MODAL: "paymentToSuppliers/SET_DOWNLOAD_ERROR_MODAL",
    COME_FROM_PROCESSED: "paymentToSuppliers/COME_FROM_PROCESSED",
    COME_FROM_DETAIL: "paymentToSuppliers/COME_FROM_DETAIL",
    RESET_ANSWER_FILE_DATA: "paymentToSuppliers/RESET_ANSWER_FILE_DATA",
    SET_FILTERS: "paymentToSuppliers/SET_FILTERS",
    SET_FILTERS_QUERY: "paymentToSuppliers/SET_FILTERS_QUERY",
    SET_FILTERS_ERRORS: "paymentToSuppliers/SET_FILTERS_ERRORS",
    RESET_FILTERS: "paymentToSuppliers/RESET_FILTERS",
    RESET_FILTERS_QUERY: "paymentToSuppliers/RESET_FILTERS_QUERY",
    SET_RESET_FILTERS_QUERY: "filters/SET_RESET_FILTERS_QUERY",
    RESET_FILE_UPLOAD_ERRORS: "paymentToSuppliers/RESET_FILE_UPLOAD_ERRORS",
    PAYMENT_TO_SUPPLIERS_ERROR_REQUEST: "paymentToSuppliers/PAYMENT_TO_SUPPLIERS_ERROR_REQUEST",
    PAYMENT_TO_SUPPLIERS_ERROR_SUCCESS: "paymentToSuppliers/PAYMENT_TO_SUPPLIERS_ERROR_SUCCESS",
    PAYMENT_TO_SUPPLIERS_ERROR_FAILURE: "paymentToSuppliers/PAYMENT_TO_SUPPLIERS_ERROR_FAILURE",

    DOWNLOAD_PAYMENT_ERRORS_FILE_REQUEST: "paymentToSuppliers/DOWNLOAD_PAYMENT_ERRORS_FILE_REQUEST",
    DOWNLOAD_PAYMENT_ERRORS_FILE_SUCCESS: "paymentToSuppliers/DOWNLOAD_PAYMENT_ERRORS_FILE_SUCCESS",
    DOWNLOAD_PAYMENT_ERRORS_FILE_FAILURE: "paymentToSuppliers/DOWNLOAD_PAYMENT_ERRORS_FILE_FAILURE",

    DOWNLOAD_VOUCHER_DETAILS_REQUEST: "paymentToSuppliers/DOWNLOAD_VOUCHER_DETAILS_REQUEST",
    DOWNLOAD_VOUCHER_DETAILS_FAILURE: "paymentToSuppliers/DOWNLOAD_VOUCHER_DETAILS_FAILURE",
    DOWNLOAD_VOUCHER_DETAILS_SUCCESS: "paymentToSuppliers/DOWNLOAD_VOUCHER_DETAILS_SUCCESS",

    ADD_DATA_TO_DOWNLOAD_REQUEST: "paymentToSuppliers/ADD_DATA_TO_DOWNLOAD_REQUEST",
    ADD_DATA_TO_DOWNLOAD_SUCCESS: "paymentToSuppliers/ADD_DATA_TO_DOWNLOAD_SUCCESS",

    RESET_VOUCHERS_TO_DOWNLOAD: "paymentToSuppliers/RESET_VOUCHERS_TO_DOWNLOAD",
};

export default createReducer(INITIAL_STATE, {
    [types.DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_REQUEST]: (state) => ({
        ...state,
    }),

    [types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        isUploading: true,
    }),

    [types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_FAILURE]: (state, actions) => ({
        ...state,
        fetching: false,
        linesWithErrors: actions?.linesWithErrors,
        nameFileError: actions?.fileName,
        isUploading: false,
        fileType: actions?.fileType,
        fileUploadError: actions?.validationError,
    }),

    [types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS]: (state, actions) => ({
        ...state,
        fetching: false,
        nameFileCharged: actions.nameFileCharged,
        idFileCharged: actions.idFileCharged,
        fileToSave: actions.fileToSave,
        totalLines: actions.totalLines,
        totalAmount: actions.totalAmount,
        date: actions.date,
        fileType: actions.fileType,
        debitAccount: actions.debitAccount,
        isUploading: false,
        amountForCap: actions.amountForCap,
    }),

    [types.DELETE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        isUploading: false,
    }),

    [types.DELETE_PAYMENT_TO_SUPPLIERS_FILE_FAILURE]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.DELETE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS]: (state) => ({
        ...state,
        nameFileCharged: "",
        idFileCharged: "",
        fileToSave: "",
        totalLines: "",
        totalAmount: {},
        date: "",
        debitAccount: "",
        fetching: false,
    }),
    [types.PAYMENT_FILES_REQUESTED]: (state, actions) => ({
        ...state,
        fetching: true,
        isFirstFetching: actions.firstFetch,
    }),
    [types.PAYMENT_FILES_RECEIVED]: (state, actions) => ({
        ...state,
        fetching: false,
        files: state.pageNumber === 1 ? actions.files : state.files.concat(actions.files),
        pageNumber: actions.nextPage,
        lastPage: actions.lastPage,
        isFirstFetching: false,
    }),
    [types.RESET_DATA]: () => Object.assign(INITIAL_STATE, { filters: { toggleState: "pending" } }),
    [types.RESET_UPLOADED_FILE_DATA]: (state) => ({
        ...state,
        nameFileCharged: INITIAL_STATE.nameFileCharged,
        idFileCharged: INITIAL_STATE.idFileCharged,
        linesWithErrors: INITIAL_STATE.linesWithErrors,
        nameFileError: INITIAL_STATE.nameFileError,
        fileToSave: INITIAL_STATE.fileToSave,
        totalLines: INITIAL_STATE.totalLines,
        totalAmount: INITIAL_STATE.totalAmount,
        date: INITIAL_STATE.date,
        debitAccount: INITIAL_STATE.debitAccount,
        fileType: INITIAL_STATE.fileType,
        goToPreviewForm: INITIAL_STATE.goToPreviewForm,
        goToTicket: INITIAL_STATE.goToTicket,
        idTransactionTicket: INITIAL_STATE.idTransactionTicket,
        uploadType: INITIAL_STATE.uploadType,
        fileBase64: INITIAL_STATE.fileBase64,
        fileStatus: INITIAL_STATE.fileStatus,
        typeFileDetail: INITIAL_STATE.typeFileDetail,
        headInfo: INITIAL_STATE.headInfo,
        nameFileDetail: INITIAL_STATE.nameFileDetail,
        linesDetail: INITIAL_STATE.linesDetail,
        amountForCap: INITIAL_STATE.amountForCap,
    }),
    [types.SET_DATA]: (state, actions) => ({
        ...state,
        ...actions.data,
    }),
    [types.SET_PAYMENT_FILES_FILTERS]: (state, actions) => ({
        ...state,
        filters: actions?.filters,
    }),
    [types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_SUCCESS]: (state, actions) => ({
        ...state,
        linesDetail: actions.linesDetail,
        typeFileDetail: actions.typeFileDetail,
        nameFileDetail: actions.nameFileDetail,
        headInfo: actions.headInfo,
        comeProcessed: actions.comeProcessed,
        linesWithErrors: INITIAL_STATE.linesWithErrors,
        fetching: false,
    }),
    [types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_FAILURE]: (state) => ({
        ...state,
        linesDetail: INITIAL_STATE.linesDetail,
        typeFileDetail: INITIAL_STATE.typeFileDetail,
        nameFileDetail: INITIAL_STATE.nameFileDetail,
        headInfo: INITIAL_STATE.headInfo,
        comeProcessed: INITIAL_STATE.comeProcessed,
        linesWithErrors: INITIAL_STATE.linesWithErrors,
        fetching: false,
    }),
    [types.COME_TO_LIST]: (state, actions) => ({
        ...state,
        comeToList: actions.comeToList,
    }),
    [types.GO_TO_PREVIEW_FORM]: (state, actions) => ({
        ...state,
        goToPreviewForm: actions.goToPreviewForm,
    }),
    [types.COME_TO_TICKET]: (state, actions) => ({
        ...state,
        idTransactionTicket: actions.idTransaction,
    }),
    [types.GO_TO_TICKET]: (state, actions) => ({
        ...state,
        goToTicket: actions.goToTicket,
    }),
    [types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_SUCCESS]: (state, actions) => ({
        ...state,
        linesDetail: actions.linesDetail,
        typeFileDetail: actions.typeFileDetail,
        nameFileDetail: actions.nameFileDetail,
        headInfo: actions.headInfo,
        idTransactionPending: actions.transactionId,
        idDownloadFileSent: actions.idDownloadFileSent,
        linesWithErrors: INITIAL_STATE.linesWithErrors,
        fetching: false,
    }),
    [types.PAYMENT_TO_SUPPLIERS_ANSWER_FILES_REQUEST]: (state, actions) => ({
        ...state,
        fetching: true,
        isFirstFetching: actions.firstFetch,
    }),
    [types.PAYMENT_TO_SUPPLIERS_ANSWER_FILES_SUCCESS]: (state, actions) => ({
        ...state,
        answerFiles: actions.nextPage === 1 ? actions.filesStatus : state.answerFiles.concat(actions.filesStatus),
        lastPage: actions.lastPage,
        pageNumber: actions.nextPage,
        fetching: false,
        isFirstFetching: false,
    }),
    [types.SET_AVAILABLE_FILES_TO_USE]: (state, actions) => ({
        ...state,
        availableFilesToUse: actions.availableFilesToUse,
    }),
    [types.SET_FILE_STATUS]: (state, actions) => ({
        ...state,
        fileStatus: actions.fileStatus,
    }),
    [types.SET_FETCHING_SUPPLIER]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_BASE]: (state, actions) => ({
        ...state,
        fileBase64: actions.base64,
    }),
    [types.SET_PAGE_NUMBER]: (state, actions) => ({
        ...state,
        pageNumber: actions.pageNumber,
    }),
    [types.SET_DOWNLOAD_MODAL]: (state, actions) => ({
        ...state,
        showDownloadModal: actions.value,
        isFetchingDownloadFiles: false,
    }),
    [types.SET_DOWNLOAD_ERROR_MODAL]: (state, actions) => ({
        ...state,
        showDownloadErrorModal: actions.value,
        isFetchingDownloadFiles: false,
    }),
    [types.COME_FROM_PROCESSED]: (state, actions) => ({
        ...state,
        comeFromProcessed: actions.comeFromPorcessed,
    }),
    [types.RESET_ANSWER_FILE_DATA]: (state) => ({
        ...state,
        answerFiles: [],
        lastPage: true,
        pageNumber: 1,
    }),
    [types.SET_FILTERS]: (state, actions) => ({
        ...state,
        filters: actions.filters,
    }),
    [types.SET_FILTERS_QUERY]: (state, actions) => ({
        ...state,
        filtersQuery: actions.filters,
    }),
    [types.SET_FILTERS_ERRORS]: (state, actions) => ({
        ...state,
        filtersErrors: actions.filters,
    }),
    [types.RESET_FILTERS]: (state) => ({
        ...state,
        filters: INITIAL_STATE.filters,
    }),
    [types.RESET_FILTERS_QUERY]: (state) => ({
        ...state,
        filtersQuery: INITIAL_STATE.filtersQuery,
    }),
    [types.SET_RESET_FILTERS_QUERY]: (state, action) => ({
        ...state,
        resetFiltersQueryOnMount: action.reset,
    }),
    [types.RESET_FILE_UPLOAD_ERRORS]: (state) => ({
        ...state,
        fileUploadError: null,
    }),
    [types.PAYMENT_TO_SUPPLIERS_ERROR_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.PAYMENT_TO_SUPPLIERS_ERROR_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        errors: action.errors,
        nameFileError: action.fileName,
    }),
    [types.PAYMENT_TO_SUPPLIERS_ERROR_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.DOWNLOAD_PAYMENT_ERRORS_FILE_REQUEST]: (state) => ({
        ...state,
        fetchingExport: true,
    }),
    [types.DOWNLOAD_PAYMENT_ERRORS_FILE_SUCCESS]: (state) => ({
        ...state,
        fetchingExport: false,
    }),
    [types.DOWNLOAD_PAYMENT_ERRORS_FILE_FAILURE]: (state) => ({
        ...state,
        fetchingExport: false,
    }),
    [types.REQUEST_PAYMENT_TO_SUPPLIERS_DOWNLOAD_FILE_FROM_SERVICE]: (state) => ({
        ...state,
        isFetchingDownloadFiles: true,
    }),
    [types.DOWNLOAD_VOUCHER_DETAILS_REQUEST]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_VOUCHER_DETAILS_FAILURE]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.DOWNLOAD_VOUCHER_DETAILS_SUCCESS]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.ADD_DATA_TO_DOWNLOAD_SUCCESS]: (state, action) => ({
        ...state,
        vouchersToDownload: action.vouchersToDownload,
    }),
    [types.RESET_VOUCHERS_TO_DOWNLOAD]: (state) => ({
        ...state,
        vouchersToDownload: [],
    }),
    [types.GET_PAYMENT_TO_SUPPLIERS_PROCCESED_FILE]: (state) => ({
        ...state,
        isFetchingDownloadFiles: true,
    }),
});

export const actions = {
    downloadPaymentsPerBatchFilesExample: makeActionCreator(
        types.DOWNLOAD_PAYMENT_TO_SUPPLIERS_FILE_EXAMPLE_REQUEST,
        "tipoOfFile",
    ),
    saveFile: makeActionCreator(
        types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST,
        "fileToSave",
        "fileName",
        "fileType",
        "fileDescription",
        "fileIdActivity",
    ),
    saveBase64: makeActionCreator(types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_BASE, "base64"),

    getFileContent: makeActionCreator(
        types.GET_PAYMENT_TO_SUPPLIERS_FILE_BASE,
        "idTransaction",
        "nameFileDetail",
        "format",
        "idDownloadFileSent",
    ),

    getFileContentProcessed: makeActionCreator(
        types.GET_PAYMENT_TO_SUPPLIERS_PROCCESED_FILE,
        "processed",
        "typeFile",
        "longFileName",
        "format",
        "processDate",
    ),

    saveFileSuccess: makeActionCreator(
        types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS,
        "nameFileCharged",
        "idFileCharged",
        "fileToSave",
        "totalLines",
        "totalAmount",
        "date",
        "debitAccount",
        "fileType",
        "amountForCap",
    ),
    saveFileFailure: makeActionCreator(
        types.SAVE_PAYMENT_TO_SUPPLIERS_FILE_FAILURE,
        "linesWithErrors",
        "fileType",
        "fileName",
        "validationError",
    ),
    deleteFile: makeActionCreator(types.DELETE_PAYMENT_TO_SUPPLIERS_FILE_REQUEST, "idFile"),
    deleteFileSuccess: makeActionCreator(types.DELETE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS),
    resetData: makeActionCreator(types.RESET_DATA),
    resetUploadedFileData: makeActionCreator(types.RESET_UPLOADED_FILE_DATA),
    paymentFilesRequested: makeActionCreator(types.PAYMENT_FILES_REQUESTED, "filters", "firstFetch"),
    paymentFilesReceived: makeActionCreator(
        types.PAYMENT_FILES_RECEIVED,
        "files",
        "nextPage",
        "lastPage",
        "firstFetch",
    ),
    paymentFilesFailed: makeActionCreator(types.PAYMENT_FILES_FAILED, "idFile"),
    setData: makeActionCreator(types.SET_DATA, "data"),
    setPaymentFilesFilters: makeActionCreator(types.SET_PAYMENT_FILES_FILTERS, "filters"),
    seeDetail: makeActionCreator(types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_REQUEST, "dataToProcess"),
    seeDetailSuccess: makeActionCreator(
        types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_SUCCESS,
        "linesDetail",
        "typeFileDetail",
        "nameFileDetail",
        "headInfo",
        "comeProcessed",
    ),
    seeDetailFailure: makeActionCreator(types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_FAILURE),
    comeToList: makeActionCreator(types.COME_TO_LIST, "comeToList"),
    goToPreviewForm: makeActionCreator(types.GO_TO_PREVIEW_FORM, "goToPreviewForm"),
    comeToTicket: makeActionCreator(types.COME_TO_TICKET, "idTransaction"),
    goToTicket: makeActionCreator(types.GO_TO_TICKET, "goToTicket"),

    seeDetailById: makeActionCreator(types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_REQUEST, "file"),
    seeDetailByIdSuccess: makeActionCreator(
        types.REQUEST_PAYMENT_TO_SUPPLIERS_DETAIL_BY_ID_SUCCESS,
        "linesDetail",
        "typeFileDetail",
        "nameFileDetail",
        "headInfo",
        "transactionId",
        "idDownloadFileSent",
    ),
    getAnswerFilesRequest: makeActionCreator(types.PAYMENT_TO_SUPPLIERS_ANSWER_FILES_REQUEST, "data", "firstFetch"),
    getAnswerFilesSuccess: makeActionCreator(
        types.PAYMENT_TO_SUPPLIERS_ANSWER_FILES_SUCCESS,
        "filesStatus",
        "lastPage",
        "nextPage",
    ),

    downloadFileFromService: makeActionCreator(
        types.REQUEST_PAYMENT_TO_SUPPLIERS_DOWNLOAD_FILE_FROM_SERVICE,
        "longFileName",
        "action",
        "format",
        "fileType",
    ),
    seeDetailFromService: makeActionCreator(types.REQUEST_PAYMENT_TO_SUPPLIERS_SEE_DETAIL_FROM_SERVICE, "fileData"),
    setAvailableFilesToUse: makeActionCreator(types.SET_AVAILABLE_FILES_TO_USE, "availableFilesToUse"),
    setFileStatus: makeActionCreator(types.SET_FILE_STATUS, "fileStatus"),
    setFetchingSupplier: makeActionCreator(types.SET_FETCHING_SUPPLIER),
    setPageNumber: makeActionCreator(types.SET_PAGE_NUMBER, "pageNumber"),
    setDownloadModal: makeActionCreator(types.SET_DOWNLOAD_MODAL, "value"),
    setDownloadErrorModal: makeActionCreator(types.SET_DOWNLOAD_ERROR_MODAL, "value"),
    setComeFromProcessed: makeActionCreator(types.COME_FROM_PROCESSED, "comeFromPorcessed"),
    setComeFormDetail: makeActionCreator(types.COME_FROM_DETAIL, "comeFromDetail"),
    resetAnswerFilesData: makeActionCreator(types.RESET_ANSWER_FILE_DATA),
    setFilter: makeActionCreator(types.SET_FILTERS, "filters"),
    setFilterQuery: makeActionCreator(types.SET_FILTERS_QUERY, "filters"),
    setFilterErrors: makeActionCreator(types.SET_FILTERS_ERRORS, "filters"),
    resetFilter: makeActionCreator(types.RESET_FILTERS),
    resetFiltersQuery: makeActionCreator(types.RESET_FILTERS_QUERY),
    setResetFiltersQuery: makeActionCreator(types.SET_RESET_FILTERS_QUERY, "reset"),
    resetUploadErrors: makeActionCreator(types.RESET_FILE_UPLOAD_ERRORS),
    getErrorsFailure: makeActionCreator(types.PAYMENT_TO_SUPPLIERS_ERROR_FAILURE),
    getErrorsRequest: makeActionCreator(
        types.PAYMENT_TO_SUPPLIERS_ERROR_REQUEST,
        "internalFileName",
        "internalMemberFileName",
        "longFileName",
    ),
    getErrorsSuccess: makeActionCreator(types.PAYMENT_TO_SUPPLIERS_ERROR_SUCCESS, "fileName", "errors"),
    downloadPaymentErrorsFile: makeActionCreator(
        types.DOWNLOAD_PAYMENT_ERRORS_FILE_REQUEST,
        "fileName",
        "format",
        "errorLines",
    ),
    downloadVoucherDetails: makeActionCreator(
        types.DOWNLOAD_VOUCHER_DETAILS_REQUEST,
        "paymentType",
        "downloadType",
        "headInfo",
        "payments",
    ),
    addVoucherToDownload: makeActionCreator(types.ADD_DATA_TO_DOWNLOAD_REQUEST, "detail", "keyDetailForValidation"),
    setVouchersToDownload: makeActionCreator(types.ADD_DATA_TO_DOWNLOAD_SUCCESS, "vouchersToDownload"),
};

export const selectors = {
    getnameFileCharged: ({ paymentToSuppliers }) => paymentToSuppliers.nameFileCharged,
    getidFileCharged: ({ paymentToSuppliers }) => paymentToSuppliers.idFileCharged,
    getFileToSave: ({ paymentToSuppliers }) => paymentToSuppliers.fileToSave,
    getlinesWithErrors: ({ paymentToSuppliers }) => paymentToSuppliers.linesWithErrors,
    getNameFileError: ({ paymentToSuppliers }) => paymentToSuppliers.nameFileError,
    getTotalLines: ({ paymentToSuppliers }) => paymentToSuppliers.totalLines,
    getTotalAmount: ({ paymentToSuppliers }) => paymentToSuppliers.totalAmount,
    getDate: ({ paymentToSuppliers }) => paymentToSuppliers.date,
    getDebitAccount: ({ paymentToSuppliers }) => paymentToSuppliers.debitAccount,
    isUploading: ({ paymentToSuppliers }) => paymentToSuppliers.isUploading,
    isFetching: ({ paymentToSuppliers }) => paymentToSuppliers.fetching,
    getFiles: ({ paymentToSuppliers }) => paymentToSuppliers.files,
    getPageNumber: ({ paymentToSuppliers }) => paymentToSuppliers.pageNumber,
    isLastPage: ({ paymentToSuppliers }) => paymentToSuppliers.lastPage,
    getPaymentFilesFilters: ({ paymentToSuppliers }) => paymentToSuppliers.filters,
    getFiltersQuery: ({ paymentToSuppliers }) => paymentToSuppliers.filtersQuery,
    getFiltersErrors: ({ paymentToSuppliers }) => paymentToSuppliers.filtersErrors,
    getLinesDetail: ({ paymentToSuppliers }) => paymentToSuppliers.linesDetail,
    getTypeFileDetail: ({ paymentToSuppliers }) => paymentToSuppliers.typeFileDetail,
    getNameFileDetail: ({ paymentToSuppliers }) => paymentToSuppliers.nameFileDetail,
    getHeadInfo: ({ paymentToSuppliers }) => paymentToSuppliers.headInfo,
    getComeToList: ({ paymentToSuppliers }) => paymentToSuppliers.comeToList,
    getGoToPreviewForm: ({ paymentToSuppliers }) => paymentToSuppliers.goToPreviewForm,
    getFileType: ({ paymentToSuppliers }) => paymentToSuppliers.fileType,
    getAnswerFiles: ({ paymentToSuppliers }) => paymentToSuppliers.answerFiles,
    getComeProcessed: ({ paymentToSuppliers }) => paymentToSuppliers.comeProcessed,
    getAvailableFilesToUse: ({ paymentToSuppliers }) => paymentToSuppliers.availableFilesToUse,
    getIdTransactionTicket: ({ paymentToSuppliers }) => paymentToSuppliers.idTransactionTicket,
    getGoToTicket: ({ paymentToSuppliers }) => paymentToSuppliers.goToTicket,
    getFileStatus: ({ paymentToSuppliers }) => paymentToSuppliers.fileStatus,
    getBase64File: ({ paymentToSuppliers }) => paymentToSuppliers.fileBase64,
    getShowDownloadModal: ({ paymentToSuppliers }) => paymentToSuppliers.showDownloadModal,
    getShowDownloadErrorModal: ({ paymentToSuppliers }) => paymentToSuppliers.showDownloadErrorModal,
    getComeFromProcessed: ({ paymentToSuppliers }) => paymentToSuppliers.comeFromProcessed,
    getIdTransactionPending: ({ paymentToSuppliers }) => paymentToSuppliers.idTransactionPending,
    getIdDownloadFileSent: ({ paymentToSuppliers }) => paymentToSuppliers.idDownloadFileSent,
    getComeFormDetail: ({ paymentToSuppliers }) => paymentToSuppliers.comeFormDetail,
    getUploadType: ({ paymentToSuppliers }) => paymentToSuppliers.uploadType,
    getFileUploadError: ({ paymentToSuppliers }) => paymentToSuppliers.fileUploadError,
    getErrors: ({ paymentToSuppliers }) => paymentToSuppliers.errors,
    getAmountForCap: ({ paymentToSuppliers }) => paymentToSuppliers.amountForCap,
    isFetchingExport: ({ paymentToSuppliers }) => paymentToSuppliers.fetchingExport,
    isResetFiltersQuery: ({ paymentToSuppliers }) => paymentToSuppliers.resetFiltersQueryOnMount,
    getFirstFetching: ({ paymentToSuppliers }) => paymentToSuppliers.isFirstFetching,
    getFetchingDownloadFiles: ({ paymentToSuppliers }) => paymentToSuppliers.isFetchingDownloadFiles,
    getVouchersToDownload: ({ paymentToSuppliers }) => paymentToSuppliers.vouchersToDownload,
    getFetchingDownloadFile: ({ paymentToSuppliers }) => paymentToSuppliers.fetchingDownload,
};
