import React, { useState } from "react";
import { any, bool, func, objectOf, string } from "prop-types";
import classNames from "classnames";

import Button from "../Button";
import I18n from "../I18n";

import "./_multipleActionBtn.scss";

const MultipleActionBtn = ({
    btnLabel,
    btnIcon,
    btnAction,
    btnLoading,
    btnBlock,
    textInfo,
    textInfoProps,
    btnDisabled,
    tooltipProps,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="multiple-actions-btn">
            <Button
                label={btnLabel}
                image={btnIcon}
                onClick={() => {
                    if (btnDisabled) {
                        setShowTooltip(true);
                    } else if (btnAction) {
                        btnAction();
                    }
                }}
                onMouseLeave={() => setShowTooltip(false)}
                bsStyle="outline"
                className={classNames({
                    disabled: btnDisabled,
                })}
                loading={btnLoading}
                block={btnBlock}
                {...(btnDisabled && tooltipProps && showTooltip ? tooltipProps : {})}
            />
            <I18n component="small" id={textInfo} {...textInfoProps} />
        </div>
    );
};

MultipleActionBtn.propTypes = {
    btnLabel: string,
    btnIcon: string,
    btnAction: func,
    btnLoading: bool,
    btnDisabled: bool,
    btnBlock: bool,
    textInfo: string,
    textInfoProps: objectOf(any),
    tooltipProps: objectOf(any),
};

MultipleActionBtn.defaultProps = {
    btnLabel: "",
    btnIcon: "images/ui-icons/ui-download.svg",
    btnAction: null,
    btnLoading: false,
    btnDisabled: false,
    btnBlock: false,
    textInfo: "",
    textInfoProps: null,
    tooltipProps: null,
};

export default MultipleActionBtn;
