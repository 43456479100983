import queryString from "query-string";
import { LOCATION_CHANGE, replace, routerActions } from "react-router-redux";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { ACTIVITIES_WITHOUT_PREVIEW_STEP } from "constants.js";
import * as file from "middleware/file";
import * as form from "middleware/form";
import * as userTransactions from "middleware/userTransactions";
import { actions as filterActions } from "reducers/filters";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions as investmentsActions, types as investmentsTypes } from "reducers/investments";
import { actions as massPaymentsActions, selectors as massivePaymentsSelector } from "reducers/massPayments";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { selectors as frequentRecipientsSelectors } from "reducers/frequentRecipients";
import { actions as sessionActions } from "reducers/session";
import { types as templateTypes } from "reducers/template";
import types from "reducers/types/form";
import * as configUtils from "util/config";
import { getDisplay, getMobileOS } from "util/device";
import { adjustIdFieldErrors, credentialsToUnderscoreFormat } from "util/form";
import * as i18n from "util/i18n";
import { types as paymentToSuppliersTypes } from "reducers/paymentToSuppliers";

import transactionLinesTypes from "reducers/form/transactionLinesTypes";
import { downloadPdf, downloadXls } from "util/download";

const sagas = [
    takeLatest(LOCATION_CHANGE, readForm),
    takeLatest(types.PRE_FORM_REQUEST, preForm),
    takeLatest(types.PREVIEW_FORM_REQUEST, previewForm),
    takeLatest(types.SEND_FORM_REQUEST, sendForm),
    takeLatest(LOCATION_CHANGE, readTransaction),
    takeLatest(LOCATION_CHANGE, readTransactionFromBackoffice),
    takeLatest(types.SAVE_DRAFT_REQUEST, saveDraftTransaction),
    takeLatest(types.CANCEL_TRANSACTION_PRE_REQUEST, cancelTransactionPre),
    takeLatest(types.CANCEL_TRANSACTION_REQUEST, cancelTransaction),
    takeLatest(types.MODIFY_TRANSACTION_REQUEST, modifyTransaction),
    takeLatest(types.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    takeLatest(types.SIGN_TRANSACTION_REQUEST, signTransaction),
    takeLatest(types.READ_TRANSACTION_REQUEST, readTransaction),
    takeLatest(types.SEND_FORM_DATA_FAILURE, logout),
    takeLatest(transactionLinesTypes.LIST_TRANSACTION_LINES_REQUEST, listTransactionLinesRequest),
    takeLatest(types.DOWNLOAD_TICKET_REQUEST, downloadTicket),
    takeLatest(types.SHARE_TICKET, shareTicket),
    takeLatest(types.DOWNLOAD_TICKET_CUSTOM_REQUEST, downloadTicketCustom),
    takeLatest(types.CANCEL_REQUEST, cancelRequest),
];

export default sagas;

function* readForm({ payload }) {
    const {
        state = {
            shouldLoadForm: true,
        },
        pathname,
        search,
    } = payload;
    const [, route, idForm] = pathname.split("/");

    if (route === "form" && state.shouldLoadForm) {
        const { query: params } = queryString.parseUrl(search);
        const response = yield call(form.readForm, idForm, params);

        if (response.type === "W") {
            yield put({ type: types.READ_FORM_FAILURE, notification: { type: "error", code: response.data.code } });
        } else {
            const { form: formMetadata, formData } = response.data.data;

            yield put({ type: templateTypes.LOAD_TEMPLATE_LIST, idForm });
            yield put({ type: types.READ_FORM_SUCCESS, idForm, formMetadata, formData });
        }
    }
}

function* preForm({ values }) {
    const { idActivity, requestData } = values;
    const { data } = yield call(form.pre, idActivity, requestData);

    if (data.error) {
        yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
    } else {
        yield put({
            type: types.PRE_FORM_SUCCESS,
            preData: data.data,
        });
    }
}

function* previewForm({ payload }) {
    const { idForm, idActivity, idTransaction, idTransactionStatus, values, formikBag } = payload;
    const index = idActivity.lastIndexOf(".send");
    const previewActivity = `${idActivity.substring(0, index)}.preview`;

    let finalValues = values;

    if (idActivity === "echeqs.emitEcheq.send" && finalValues?.emissionType[0] !== "personalized") {
        const { emissionType, echeqs } = finalValues;
        if (emissionType[0] === "single" && echeqs.length > 0 && echeqs[0].cheque_numero === undefined) {
            finalValues = { ...finalValues, echeqs: [] };
        }
    } else if (idActivity === "pay.transfers.massive.send") {
        const payments = yield select(massivePaymentsSelector.getPayments);
        const transferType = finalValues?.transferType;
        let concept = finalValues?.concept;
        let paymentDetails = null;
        let fileId = null;
        let amount = null;

        if (payments && payments.length > 0) {
            paymentDetails = yield select(massivePaymentsSelector.getPaymentDetails);
            fileId = paymentDetails?.validFileId;

            if (paymentDetails?.total) {
                amount = {
                    quantity: paymentDetails.total,
                    currency: finalValues?.account?.currency,
                };
            }
        }

        if (transferType && transferType.length > 0 && !transferType.includes("3")) {
            concept = null;
        }

        const newFinalValues = { ...finalValues, payments, paymentDetails, fileId, amount, concept };

        finalValues = newFinalValues;
    } else if (idActivity === "request.investment.subscribe.send") {
        const accountShareholder = finalValues?.account?.number;
        const newFinalValues = { ...finalValues, accountShareholder };
        finalValues = newFinalValues;
    }

    const { type, data } = yield call(form.preview, idForm, previewActivity, idTransaction, finalValues);

    const {
        props: { metadata },
    } = formikBag;
    let needsAdditionalSteptoEdit = false;
    if (metadata && metadata.fieldList) {
        needsAdditionalSteptoEdit = metadata.fieldList.filter((i) => i.idField === "overdraft").length > 0;
    }
    const mode = !needsAdditionalSteptoEdit ? "preview" : "edit-step2";
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        if (data.error) {
            yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
            if (data.error && data.error.details) {
                formikBag.setErrors(adjustIdFieldErrors(data.error.details));
            }
            formikBag.setSubmitting(false);
        } else {
            const response = yield call(form.listCredentialsGroups, idForm, idActivity);
            yield put({
                type: types.PREVIEW_FORM_SUCCESS,
                idForm,
                credentialsGroups: response.data.data.groups,
                submitAction: formActions.sendForm,
                submitActionParams: { idForm, idActivity, idTransaction, values },
                previewData: data,
                mode: "preview",
            });
            yield put(formActions.setData(values));
            formikBag.setSubmitting(false);

            yield put({
                type: types.SET_MODE,
                mode,
                prevMode: "edit",
            });
        }
    } else if (type === "W") {
        if (data.data.NO_FIELD) {
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["form"]));
        } else if (data.data.INVALID_ECHEQ) {
            yield put(formActions.disableSubmit());
            yield put(
                notificationActions.showNotificationAction(data.data.INVALID_ECHEQ || "", "error", ["form"], "/echeqs"),
            );
        } else if (idActivity === "frequentDestination.create.send" && idTransactionStatus) {
            const messageResponse = data.data.destinationIdentifierS || data.data.reference;
            if (messageResponse) {
                yield put(notificationActions.showNotification(messageResponse || "", "error", ["form"]));
            }
        } else {
            yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
        }

        if (idActivity === "fixedTermDeposit.create.send") {
            const { capitalToInvest, days, ...rest } = data.data;
            let fieldErrors = rest;
            let errors = {};
            // importante que sea un espacio y no vacio, para que aparezcan los bordes rojos de error sin mostrar texto
            const errorToHighlightField = " ";
            if (capitalToInvest && capitalToInvest !== i18n.get("forms.mariva.allFieldsMandatory")) {
                errors = { capitalToInvest };
                fieldErrors = { capitalToInvest: errorToHighlightField, ...fieldErrors };
            } else {
                fieldErrors = { capitalToInvest, ...fieldErrors };
            }
            if (days && days !== i18n.get("forms.mariva.allFieldsMandatory")) {
                errors = { days, ...errors };
                fieldErrors = { days: errorToHighlightField, ...fieldErrors };
            } else {
                fieldErrors = { days, ...fieldErrors };
            }
            yield put(investmentsActions.simulateDepositFailure(errors));
            formikBag.setErrors(adjustIdFieldErrors(fieldErrors));
        } else {
            yield put(formActions.setPreviewFormErrors(data.data));
            formikBag.setErrors(adjustIdFieldErrors(data.data));
        }

        formikBag.setSubmitting(false);
    } else {
        // TODO a WARNING here must be treated as an ERROR, right?
        const response = yield call(form.listCredentialsGroups, idForm, idActivity);
        let newValues = null;
        if (idActivity === "fixedTermDeposit.create.send") {
            newValues = { ...values, capitalToInvest: { ...values.capitalToInvest, currency: values.currency[0] } };
        }
        if (idActivity === "transfers.thirdParties.send" || idActivity === "transfers.local.send") {
            newValues = { ...values, transfersValidation: data.data.transferDestination };
        }

        if (idActivity === "investments.investerProfileForm.send") {
            newValues = { ...values, profile: data.data?.profile.type };
        }

        if (idActivity === "echeqs.requestMandateForNegotiation.send") {
            newValues = { ...values };
        }

        if (
            idActivity === "pays.supplier.transfer.send" ||
            idActivity === "pays.supplier.withholdings.send" ||
            idActivity === "pays.supplier.check.echeq.send"
        ) {
            yield put({
                type: paymentToSuppliersTypes.SAVE_PAYMENT_TO_SUPPLIERS_FILE_SUCCESS,
                fileType: data.data?.fileType,
                nameFileCharged: data.data?.fileName,
                idFileCharged: data.data?.id,
                fileToSave: data.data?.fileToSave,
                totalLines: data.data?.totalLines,
                totalAmount: data.data?.totalAmount,
                date: data.data?.creationDate,
                debitAccount: data.data?.debitAccount,
                amountForCap: values?.amountForCap,
            });
            newValues = { ...values, ...data?.data };
        }

        if (idActivity === "pay.transfers.massive.send") {
            newValues = finalValues;
        }

        yield put({
            type: types.PREVIEW_FORM_SUCCESS,
            idForm,
            credentialsGroups: response.data.data.groups,
            submitAction: formActions.sendForm,
            submitActionParams: { idForm, idActivity, idTransaction, values: newValues || values },
            previewData: data.data,
            mode: "preview",
        });

        yield put(formActions.setData(values));
        if (formikBag && formikBag.setSubmitting) {
            formikBag.setSubmitting(false);
        }

        yield put({
            type: types.SET_MODE,
            mode,
            prevMode: "edit",
        });
    }
}

function* sendForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, credentials, formikBag } = payload;
    try {
        if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
            const response = yield call(form.send, idForm, idActivity, idTransaction, values, credentials);
            const { data, status } = response;
            if (status === 200 && typeof data === "string") {
                yield put(routerActions.replace(`/transaction/${data}`));
            } else {
                yield put({
                    type: types.SEND_FORM_DATA_FAILURE,
                    code: "",
                });
                if (data && data.error && data.error.message) {
                    if (data.error.details && data.error.details.transactionId) {
                        yield put(routerActions.replace(`/transaction/${data.error.details.transactionId}`));
                    } else {
                        yield put(notificationActions.showNotification(data.error.message, "error", ["form"]));
                    }
                } else {
                    yield put(notificationActions.showNotification(data[Object.keys(data)[0]], "error", ["form"]));
                }
            }
            formikBag.setSubmitting(false);
        } else {
            let formValues = { ...values };
            if (idActivity === "fixedTermDeposit.create.send") {
                const { depositDetail } = yield select(formSelectors.getPreviewData);
                formValues = { ...formValues, depositDetail };
            }

            if (idActivity === "request.investment.subscribe.send") {
                const accountShareholder = formValues?.account?.number;
                formValues = { ...formValues, accountShareholder };
            }

            if (idActivity === "echeqs.emitEcheq.send" && formValues.emissionType[0] === "personalized") {
                const { echeqs, ...rest } = formValues;
                let requiresSignature = null;
                /* eslint-disable no-restricted-syntax */
                for (const echeq of echeqs) {
                    const amount = { currency: echeq.emisor_moneda, quantity: echeq.monto };
                    const idTransactionForEcheq = requiresSignature === null ? idTransaction : null;

                    const newFromValues = {
                        ...rest,
                        echeqs: [echeq],
                        totalAmount: amount,
                        amount,
                        paymentDate: echeq.fecha_pago,
                        echeqNumber: echeq.cheque_numero,
                        CMC7: echeq.cmc7,
                        echeqType: [echeq.cheque_tipo],
                        character: [echeq.cheque_caracter],
                        reference: echeq.referencia,
                    };

                    const response = yield call(
                        form.send,
                        idForm,
                        idActivity,
                        idTransactionForEcheq,
                        newFromValues,
                        credentials,
                    );
                    if (requiresSignature === null) {
                        requiresSignature = response?.data?.code === "COR023I";

                        const successMessage = i18n.get(
                            `forms.echeqs.emitEcheq.personalized.${
                                requiresSignature ? "pendingSignatures" : "transactionsExecuted"
                            }`,
                        );
                        yield put({
                            type: types.SEND_FORM_MULTIPLE_TRANSACTIONS_SUCCESS,
                            transactionsRequireSignature: requiresSignature,
                            message: successMessage || "mensaje",
                        });
                    }
                }
            } else {
                const { data, type } = yield call(
                    form.send,
                    idForm,
                    idActivity,
                    idTransaction,
                    formValues,
                    credentials,
                );
                if (type === "W") {
                    let messageResponse = data.message;
                    const hasIncorrectTerms =
                        Object.prototype.hasOwnProperty.call(data.data, "_termsAndConditionsAcceptance") ||
                        Object.prototype.hasOwnProperty.call(data.data, "_termsAndConditionsSubscriptionAcceptance") ||
                        Object.prototype.hasOwnProperty.call(data.data, "_clausesAndRegulationsAcceptance") ||
                        Object.prototype.hasOwnProperty.call(data.data, "_fundRisksAcceptance") ||
                        Object.prototype.hasOwnProperty.call(data.data, "_termsCondition");

                    if (data.code === "API519W") {
                        yield put(sessionActions.logout());
                        yield put(notificationModalActions.showNotification("returnCode.API519W", "warning"));
                    } else if (data.code === "COR027W") {
                        const errorMessage = { otp: data.message };
                        yield put({
                            type: types.PREVIEW_FORM_ERRORS,
                            errors: adjustIdFieldErrors(errorMessage),
                        });
                        formikBag.setErrors(adjustIdFieldErrors(errorMessage));
                        yield put(notificationActions.showNotification(data.message, "error", ["form"]));
                        yield put({
                            type: types.SEND_FORM_CREDENTIAL_FAILURE,
                            code: data.code,
                        });
                    } else if (hasIncorrectTerms) {
                        formikBag.setErrors(adjustIdFieldErrors(data.data));
                        yield put(
                            notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]),
                        );
                        yield put({
                            type: types.PREVIEW_FORM_ERRORS,
                            errors: adjustIdFieldErrors(data.data),
                        });
                        yield put({
                            type: types.SEND_FORM_DATA_FAILURE_NO_PRE,
                            code: data.code,
                        });
                    } else {
                        if (idActivity === "account.set.cbuAlias" || idActivity === "account.delete.cbuAlias") {
                            yield put({
                                type: types.SEND_FORM_DATA_FAILURE_NO_PRE,
                                code: data.code,
                            });
                            yield put({
                                type: types.PREVIEW_FORM_ERRORS,
                                errors: adjustIdFieldErrors(data.data),
                            });
                        } else if (
                            (idActivity === "request.investment.rescue.send" ||
                                idActivity === "request.investment.subscribe.send") &&
                            data.code === "COR526W"
                        ) {
                            messageResponse = data.data.message;
                            yield put({
                                type: types.SEND_FORM_DATA_FAILURE,
                                code: data.code,
                            });
                            yield put({
                                type: types.GO_BACK_CLEAN_TRANSACTION,
                                code: data.code,
                            });
                        } else if (idActivity === "echeqs.emitEcheq.send") {
                            if (data?.message) {
                                messageResponse = data.message;
                                yield put({
                                    type: types.SEND_FORM_DATA_FAILURE,
                                    code: data.code,
                                });
                                yield put({
                                    type: types.GO_BACK_CLEAN_TRANSACTION,
                                    code: data.code,
                                });
                            } else {
                                yield put(formActions.setErrorMessage(data.data.NO_FIELD));
                                yield put(
                                    routerActions.replace({
                                        pathname: `/transaction/${data.idTransaction}`,
                                        state: { shouldLoadForm: false },
                                    }),
                                );
                            }
                        } else if (idActivity === "fixedTermDeposits.cancelRenewal.send") {
                            messageResponse = data.data.message;
                            yield put({
                                type: types.SEND_FORM_DATA_FAILURE,
                                code: data.code,
                            });
                        } else if (idActivity === "account.qr.enrollment.send") {
                            messageResponse = data.data.termsCondition;
                            yield put({
                                type: types.SEND_FORM_DATA_FAILURE,
                                code: data.code,
                            });
                        } else {
                            yield put({
                                type: types.SEND_FORM_DATA_FAILURE,
                                code: data.code,
                            });
                        }

                        if (data.data.NO_FIELD) {
                            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["form"]));
                        } else {
                            yield put(notificationActions.showNotification(messageResponse, "error", ["form"]));
                        }
                    }

                    formikBag.setSubmitting(false);
                } else if (idActivity === "fixedTermDeposits.cancelRenewal.send") {
                    yield put({
                        type: investmentsTypes.CANCEL_DEPOSIT_RENEWAL_SEND_SUCCESS,
                        idProduct: values.idDeposit,
                    });
                    yield put(routerActions.replace("/fixedTermDeposits"));
                    yield put(
                        notificationActions.showNotification(data.message, "success", [
                            "deposits",
                            "fixedTermDeposit/details",
                        ]),
                    );
                } else if (idActivity === "account.set.cbuAlias" || idActivity === "account.delete.cbuAlias") {
                    let successMessage;
                    if (idActivity === "account.set.cbuAlias") {
                        const setAliasType = values.alias === "" || values.alias === null ? "create" : "modify";
                        successMessage = `accounts.cbu.change.successMessage.${setAliasType}.label`;
                    } else {
                        successMessage = "accounts.cbu.change.successMessage.delete.label";
                    }
                    yield put(routerActions.goBack());
                    yield put(notificationModalActions.showNotification(successMessage, "success"));
                } else if (idActivity === "account.qr.enrollment.send") {
                    const { successEnrollment } = data?.data || {};

                    if (successEnrollment) {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("forms.account.qr.enrollment.success"),
                                "success",
                                ["account/details"],
                            ),
                        );
                    } else {
                        yield put(
                            notificationModalActions.showNotification(
                                "forms.account.qr.download.showqr.requireSignatures",
                            ),
                        );
                    }
                    yield put(routerActions.goBack());
                } else if (idActivity === "request.changePersonalData.send") {
                    const transactionResponse = yield call(userTransactions.readUserTransaction, data.idTransaction);
                    const { userTransactionDetail } = transactionResponse.data.data;
                    yield put({
                        type: types.SEND_FORM_USER_SUCCESS,
                        userTransactionDetail,
                    });

                    if (data.data.email && data.data.mobilePhone) {
                        yield put(sessionActions.updateEmailPhone(data.data.email, data.data.mobilePhone));
                    }

                    formikBag.setSubmitting(false);
                } else if (idActivity === "investments.investerProfileForm.send") {
                    const transactionResponse = yield call(form.readTransaction, data.idTransaction);
                    const { transaction } = transactionResponse.data.data;

                    formikBag.setSubmitting(false);
                    yield put({
                        type: types.SEND_FORM_SUCCESS,
                        transaction,
                    });

                    yield put(formActions.setData(values));

                    yield put({
                        type: types.SET_MODE,
                        mode: "view",
                        prevMode: "edit",
                    });
                    const { environments } = data?.data;

                    if (environments) {
                        yield put(sessionActions.updateEnvironments(environments));
                    }
                } else if (idActivity === "frequentDestination.create.send") {
                    const { urlOrigin } = yield select(frequentRecipientsSelectors.getRecipientFound);
                    const transactionResponse = yield call(form.readTransaction, data.idTransaction);
                    const { transaction } = transactionResponse.data.data;

                    formikBag.setSubmitting(false);
                    yield put({
                        type: types.SEND_FORM_SUCCESS,
                        transaction,
                    });

                    if (urlOrigin && data.code === "COR000I") {
                        yield put(replace(urlOrigin));
                    }
                } else {
                    const transactionResponse = yield call(form.readTransaction, data.idTransaction);
                    const { transaction } = transactionResponse.data.data;

                    formikBag.setSubmitting(false);
                    yield put({
                        type: types.SEND_FORM_SUCCESS,
                        transaction,
                    });

                    if (idActivity === "pay.transfers.massive.send") {
                        yield put(massPaymentsActions.resetData());
                    }
                }
            }
        }
    } catch (error) {
        yield put({
            type: types.SEND_FORM_DATA_FAILURE,
            code: error.code,
        });
        const message = error.data.message || error.message;
        if (message) {
            yield put(notificationActions.showNotification(message || "", "error", ["form"]));
        }
        formikBag.setSubmitting(false);
    }
}

function* readTransaction({ payload }) {
    const [, route, idTransaction] = payload.pathname.split("/");

    if (route === "transaction") {
        const transactionResponse = yield call(form.readTransaction, idTransaction);

        if (transactionResponse.type === "W") {
            yield put({
                type: types.READ_TRANSACTION_FAILURE,
                notification: { type: "error", code: transactionResponse.data.code },
            });
        } else {
            const { idForm, formVersion } = transactionResponse.data.data.transaction;

            let formResponse = {};

            if (idForm !== null) {
                formResponse = yield call(form.readForm, idForm, {
                    idTransactionToRead: idTransaction,
                    formVersion,
                });

                if (formResponse.type === "W") {
                    yield put({
                        type: types.READ_FORM_FAILURE,
                        notification: { type: "error", code: formResponse.data.code },
                    });
                    return;
                }
            }

            const { children, parent } = transactionResponse.data.data;
            let { transaction } = transactionResponse.data.data;
            transaction = { ...transaction, dispatcher: transactionResponse.data.data.dispatcher };

            yield put({
                type: types.READ_TRANSACTION_SUCCESS,
                idForm: transaction.idForm,
                transaction,
                childrenTransactions: children,
                parentTransaction: parent,
                formMetadata: formResponse?.data?.data?.form || { fieldList: [], formNameMap: [] },
            });
        }
    } else if (route === "userTransaction") {
        const transactionResponse = yield call(userTransactions.readUserTransaction, idTransaction);
        const { userTransactionDetail } = transactionResponse.data.data;

        yield put({
            type: types.READ_USER_TRANSACTION_SUCCESS,
            idForm: userTransactionDetail.idActivity,
            userTransactionDetail,
        });
    }
}

function* readTransactionFromBackoffice({ payload }) {
    if (payload.pathname === "/forms/backoffice/ticket") {
        const { query } = queryString.parseUrl(payload.search);
        const exchangeToken = query._exchangeToken;
        const { data } = yield call(form.readTransactionFromBackoffice, exchangeToken);
        const { UserTransaction } = data.data;
        if (UserTransaction) {
            yield put({
                type: types.READ_USER_TRANSACTION_FROM_BACKOFFICE_SUCCESS,
                idForm: UserTransaction.idActivity,
                data: UserTransaction,
                userTransactionDetail: UserTransaction,
            });
        } else {
            yield put({
                type: types.READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS,
                idForm: data.data.transaction.idForm,
                data: data.data.transactionData,
                formMetadata: data.data.form,
                transaction: data.data.transaction,
            });
        }
    }
}

function* saveDraftTransaction({ payload }) {
    const { idForm, data, idActivityDraft, idTransaction } = payload;
    const response = yield call(form.saveDraft, idForm, data, idActivityDraft, idTransaction);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("forms.saveDraft.errorMessage"), "error", ["form"]));
        yield put({ type: types.SAVE_DRAFT_FAILURE });
    } else {
        const confirmationMessage = i18n.get("forms.saveDraft.confirmationMessage");
        yield put({ type: types.SAVE_DRAFT_SUCCESS, idForm, data: response.data.data });
        yield put(filterActions.setReloadData(true));
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["accounts", "desktop"]));
        yield put(routerActions.push("/desktop"));
    }
}

function* cancelTransactionPre({ payload }) {
    const { idActivity, idForm } = payload;
    const { type, data } = yield call(form.listCredentialsGroups, idForm, idActivity);

    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.pre.error"), "error", ["form"]),
        );
        yield put({
            type: types.CANCEL_TRANSACTION_PRE_ERROR,
        });
    } else {
        const { groups } = data.data;

        yield put({
            type: types.CANCEL_TRANSACTION_PRE_SUCCESS,
            credentialsGroups: groups,
        });
    }
}

function isUserTransaction(location, transactions) {
    let result = false;
    transactions.forEach((uT) => {
        if (location.includes(uT)) {
            result = true;
        }
    });
    return result;
}

function* cancelTransaction({ payload }) {
    const { credentials, formikBag } = payload;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    // Es necesario agregar todos los paths de transacciones de usuarios
    const userTransactionsData = ["requestChangePersonalData"];

    const { idTransaction, idForm } = formikBag.props;

    const response = yield call(
        form.cancelTransaction,
        idTransaction,
        {
            ...credentialsWithUnderscore,
        },
        idForm,
    );
    const { data, message } = response.data;

    if (response.type === "W") {
        data.otp = message;
        formikBag.setErrors(data);

        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.errorMessage"), "error", ["form"]),
        );
    } else {
        yield put({
            type: types.CANCEL_TRANSACTION_SUCCESS,
        });

        yield put(filterActions.setReloadData(true));

        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.confirmationMessage"), "success", [
                "form",
                "desktop",
            ]),
        );

        const location = isUserTransaction(window.location.pathname, userTransactionsData)
            ? `/userTransaction/${idTransaction}`
            : `/transaction/${idTransaction}`;

        yield put(
            formActions.readTransaction(
                window.location.pathname.includes("/transaction") ||
                    window.location.pathname.includes("/userTransaction")
                    ? window.location
                    : { pathname: location },
            ),
        );
    }

    formikBag.setSubmitting(false);
}

function* cancelRequest({ idTransaction, credentials, cancelReason }) {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const response = yield call(
        form.cancelRequest,
        idTransaction,
        {
            ...credentialsWithUnderscore,
        },
        cancelReason,
    );
    const { data, message } = response.data;

    if (response.type === "W") {
        data.otp = message;

        yield put({
            type: types.CANCEL_REQUEST_FAILURE,
        });
        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.errorMessage"), "error", ["form"]),
        );
    } else {
        yield put({
            type: types.CANCEL_REQUEST_SUCCESS,
        });

        yield put(filterActions.setReloadData(true));

        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.confirmationMessage"), "success", [
                "form",
                "desktop",
            ]),
        );

        const location = `/transaction/${idTransaction}`;

        yield put(
            formActions.readTransaction(
                window.location.pathname.includes("/transaction") ? window.location : { pathname: location },
            ),
        );
    }
}

function* downloadTicket({ idTicket, format, idForm }) {
    const { type, data } = yield call(form.downloadTicket, idTicket, format, idForm);
    if (type === "W") {
        yield put({ type: types.DOWNLOAD_TICKET_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_TICKET_SUCCESS });
    }
}

function* shareTicket({ idTicket, format, idForm }) {
    const { type, data } = yield call(form.downloadTicket, idTicket, format, idForm);
    const fileName = "Transaction Ticket";
    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        let contentData = `data:text/pdf;base64,${data.data.content}`;
        if (getMobileOS(getDisplay()) === "iOS") {
            contentData = `data:text/pdf:${fileName}'.pdf;base64,${data.data.content}`;
        }
        const options = {
            message: fileName,
            subject: fileName,
            files: [contentData],
        };
        window.plugins.socialsharing.shareWithOptions(options, null, null);
    }
}

function* modifyTransaction({ idTransaction }) {
    const response = yield call(form.moveToDraftTransaction, idTransaction);
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.modifyTransaction.errorMessage"), "error", ["form"]),
        );
        yield put({ type: types.CANCEL_TRANSACTION_FAILURE });
    } else {
        yield put(filterActions.setReloadData(true));
        yield put(formActions.readTransaction({ pathname: `/transaction/${idTransaction}` }));
        yield put(filterActions.setReloadData(true));
    }
}

function* signTransactionPreview({ payload }) {
    const { idForm, idActivity, idTransaction, ticketData } = payload;
    const credentialsResponse = yield call(form.listCredentialsGroups, idForm, idActivity);
    const objectData = {
        type: types.SIGN_TRANSACTION_PREVIEW_SUCCESS,
        idForm,
        credentialsGroups: credentialsResponse.data.data.groups,
        submitAction: formActions.signTransaction,
        submitActionParams: { idForm, idActivity, idTransaction },
        ticketData,
        mode: "preview",
    };

    if (ACTIVITIES_WITHOUT_PREVIEW_STEP.indexOf(idActivity) === -1) {
        const { type, data } = yield call(
            form.signPreview,
            idForm,
            idActivity.replace(".send", ".preview"),
            idTransaction,
        );

        if (type === "W") {
            // TODO, que se hace?

            if (idActivity === "request.investment.subscribe.send" && data.code === "COR020W") {
                yield put({
                    type: types.GO_BACK_CLEAN_TRANSACTION,
                    code: data.code,
                });
                yield put(routerActions.replace(`/transaction/${data?.idTransaction}`));

                const message = data.data.message || data.message;
                if (data.data.NO_FIELD) {
                    yield put(
                        notificationActions.showNotification(data.data.NO_FIELD, "error", ["transaction/details"]),
                    );
                } else if (message) {
                    yield put(notificationActions.showNotification(message || "", "error", ["form"]));
                }
            } else {
                yield put({
                    type: types.GO_BACK_CLEAN_TRANSACTION,
                    code: data.code,
                });
                yield put(routerActions.replace(`/transaction/${data?.idTransaction}`));

                if (idActivity === "frequentDestination.create.send") {
                    const messageResponse = data.data.destinationIdentifierS || data.data.reference;
                    if (messageResponse) {
                        yield put(
                            notificationActions.showNotification(messageResponse || "", "error", [
                                "transaction/details",
                            ]),
                        );
                    }
                } else {
                    const message = data.data.message || data.message;
                    if (data.data.NO_FIELD) {
                        yield put(
                            notificationActions.showNotification(data.data.NO_FIELD, "error", ["transaction/details"]),
                        );
                    } else if (message) {
                        yield put(
                            notificationActions.showNotification(message || "", "error", ["transaction/details"]),
                        );
                    }
                }
            }
        } else if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
            yield put({ ...objectData, previewData: data });
        } else {
            yield put({ ...objectData, previewData: data.data });
        }
    } else {
        yield put(objectData);
    }
}

function* signTransaction({ payload }) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = payload;
    try {
        const { data, type } = yield call(form.sign, idForm, idActivity, idTransaction, credentials);
        if (type === "W") {
            const hasIncorrectCredentials = Object.keys(credentials).some((key) => data.data[key]);

            if (hasIncorrectCredentials) {
                formikBag.setErrors(adjustIdFieldErrors(data.data));
                yield put({
                    type: types.SEND_FORM_CREDENTIAL_FAILURE,
                    code: data.code,
                });
            } else {
                yield put({
                    type: types.SEND_FORM_DATA_FAILURE,
                    code: data.code,
                });
                if (idActivity === "echeqs.emitEcheq.send") {
                    const message = data?.data?.NO_FIELD || data?.NO_FIELD || data?.data?.message || data.message;

                    yield put({
                        type: types.GO_BACK_CLEAN_TRANSACTION,
                        code: data.code,
                    });
                    yield put(routerActions.replace(`/transaction/${data?.idTransaction}`));
                    yield put(notificationActions.showNotification(message, "error", ["transaction/details"]));
                } else {
                    const message = data.data.message || data.message;
                    if (data.data.NO_FIELD) {
                        yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["form"]));
                    } else if (message) {
                        yield put(notificationActions.showNotification(message || "", "error", ["form"]));
                    }
                }
            }

            formikBag.setSubmitting(false);
        } else {
            const transactionResponse = yield call(form.readTransaction, idTransaction);
            const { transaction } = transactionResponse.data.data;
            yield put({
                type: types.SEND_FORM_SUCCESS,
                transaction,
                idTransaction,
            });
            formikBag.setSubmitting(false);
        }
        yield put(filterActions.setReloadData(true));
    } catch (error) {
        yield put({
            type: types.SEND_FORM_DATA_FAILURE,
            code: error.code,
        });
        const message = error.data.message || error.message;
        if (message) {
            yield put(notificationActions.showNotification(message || "", "error", ["form"]));
        }
        formikBag.setSubmitting(false);
    }
}

function* listTransactionLinesRequest({ payload }) {
    const response = yield call(file.listTransactionLines, payload);
    if (response.type === "W") {
        yield put(transactionLinesActions.listTransactionLinesFailure());
    } else {
        yield put(transactionLinesActions.listTransactionLinesSuccess(response.data.data));
    }
}

function* logout({ code }) {
    if (code === "API010W") {
        yield put(sessionActions.logout());
        yield put(notificationActions.showNotification(i18n.get("returnCode.API010W"), "error", ["login"]));
    }
}

function* downloadTicketCustom({ idTransaction, idActivity }) {
    try {
        const idActivityTicket = idActivity.replace(".send", ".download.ticket");
        const { type, data } = yield call(form.downLoadTicketCustom, idActivityTicket, idTransaction);
        if (type === "W") {
            yield put({ type: types.DOWNLOAD_TICKET_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "transaction/details",
                ]),
            );
        } else {
            const { content, fileName } = data.data;
            yield call(downloadPdf, fileName, content);
            yield put({ type: types.DOWNLOAD_TICKET_SUCCESS });
            yield put(
                notificationActions.showNotification(i18n.get("notification.download.successful"), "success", [
                    "transaction/details",
                ]),
            );
        }
    } catch (error) {
        yield put({ type: types.DOWNLOAD_TICKET_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    }
}
